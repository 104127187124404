import { FC, useState } from 'react';

import classNames from 'classnames';
import arrowLeftBlack from '../../assets/images/arrow-left-black.svg';
import blueClose from '../../assets/images/close-blue.svg';
import close from '../../assets/images/close.svg';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { CoverInfoV3 } from '../../content/coverTermsTypes';
import useCoverInformation from '../../hooks/useCoverInformation';
import useCoverTerms from '../../hooks/useCoverTerms';
import common from '../../strings/common';
import CoverCode from '../../utils/constants/CoverCode';
import parseString from '../../utils/parseString';
import Button from '../button/Button';
import CoverTerms from '../cover-terms/CoverTerms';
import IconButton from '../icon-button/IconButton';
import Modal from '../modal/Modal';

import './SlideInCoverTerms.scss';

interface SlideInCoverTermsProps {
    coverCode: CoverCode;
    pdsVersion: string;
    isOpen: boolean;
    showGroupPrice?: boolean;
    onClose: () => void;
    onOpen: () => void;
    hidePriceChip?: boolean;
    canCancel?: boolean;
    initiateCancellation?: () => void;
}

const SlideInCoverTerms: FC<SlideInCoverTermsProps> = ({
    coverCode,
    pdsVersion,
    isOpen,
    showGroupPrice = false,
    onOpen,
    onClose,
    hidePriceChip = false,
    canCancel,
    initiateCancellation,
}) => {
    const [multiContentTarget, setMultiContentTarget] = useState<keyof CoverInfoV3>('mainMenu');
    const { coverCategories } = useProduct();
    const coverInformation = useCoverInformation(coverCode, pdsVersion);
    const selectedCoverCategory = coverCategories.find((x) => x.coverCodes.includes(coverCode));
    const newCoverTerms = useCoverTerms(coverCode, pdsVersion)?.coverTermsSections;
    const mainMenuLinks = newCoverTerms?.map((item) => {
        return { target: item.id, label: item.label };
    });
    const closeModal = () => {
        setTimeout(() => {
            onClose();
            setMultiContentTarget('mainMenu'); // reset
        }, 0);
    };

    const switchSubMenu = (id: string) => {
        onClose();
        setTimeout(() => {
            setMultiContentTarget(id as keyof CoverInfoV3);
            onOpen();
        }, 600);
    };

    return (
        <Modal isOpen={isOpen} parentClass="#root" onClose={closeModal} variant="slide-in" className="no-scroll">
            <div className="slide-in-coverterms__modal-close-button-wrapper">
                <IconButton onClick={closeModal} icon={close} hoverIcon={blueClose} actionLabel="Close" />
                {multiContentTarget !== 'mainMenu' && (
                    <Button
                        type="button"
                        variant="link"
                        width="standard"
                        className="slide-in-coverterms__modal__menu-button"
                        label={`${selectedCoverCategory?.name} Menu`}
                        onClick={() => setMultiContentTarget('mainMenu')}
                        suffixIcon={arrowLeftBlack}
                    />
                )}
            </div>
            <div
                className={classNames(
                    'slide-in-coverterms',
                    !!multiContentTarget && multiContentTarget !== 'mainMenu' && 'active',
                )}
            >
                <div className="slide-in-coverterms__menu">
                    {!hidePriceChip && (
                        <div className="slide-in-coverterms__main__pricing">
                            <span>
                                {parseString(
                                    (showGroupPrice
                                        ? coverInformation?.groupPrice
                                        : `${coverInformation?.price} per ${coverInformation?.priceTimePeriod}`) || '',
                                )}
                            </span>
                        </div>
                    )}
                    <h1 className="slide-in-coverterms__menu__heading">{selectedCoverCategory?.name}</h1>
                    <p>{parseString(selectedCoverCategory?.description ?? '')}</p>
                    {mainMenuLinks?.map((link: { target: string; label: string }) => (
                        <div key={link.target}>
                            <Button
                                key={link.target}
                                type="button"
                                variant="drawer-link"
                                width="full"
                                label={link.label}
                                className="slide-in-coverterms__main__button"
                                onClick={() => setMultiContentTarget(link.target as keyof CoverInfoV3)}
                            />
                            <div className="slide-in-coverterms__main__button__divider" />
                        </div>
                    ))}
                    {canCancel && (
                        <Button
                            type="button"
                            width="full"
                            variant="secondary"
                            className="slide-in-coverterms__cancel"
                            label="Cancel Cover"
                            onClick={() => {
                                closeModal();
                                setTimeout(() => {}, 400); // wait until benefit drawer closes
                                initiateCancellation!();
                            }}
                        />
                    )}
                </div>
                <div className="slide-in-coverterms__step">
                    <CoverTerms
                        currentMenuId={multiContentTarget}
                        pdsVersion={pdsVersion}
                        coverCode={coverCode}
                        switchSubMenu={switchSubMenu}
                    />
                    <span className="slide-in-coverterms__pds">
                        {coverInformation?.name} {common.formatString(common.pdsVersion, { pdsVersion })}
                    </span>
                </div>
            </div>
        </Modal>
    );
};

export default SlideInCoverTerms;
