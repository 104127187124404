import { useMachine } from '@xstate/react';
import classNames from 'classnames';
import { FC, FormEvent, useCallback, useMemo } from 'react';
import closeBlue from '../../../../assets/images/close-blue.svg';
import { usePersons } from '../../../../business-logic/context-provider/persons-provider/PersonsContext';
import { useUser } from '../../../../business-logic/context-provider/user-context';
import { ProductOption } from '../../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import AdultDetailsForm from '../../../../components/adult-details-form/AdultDetailsForm';
import Alert, { AlertTypes } from '../../../../components/alert/Alert';
import Button from '../../../../components/button/Button';
import CheckboxList from '../../../../components/checkbox-list/CheckboxList';
import Fieldset from '../../../../components/form/fieldset/Fieldset';
import { RadioCheckboxProps } from '../../../../components/form/RadioCheckboxProps';
import KidDetailsForm from '../../../../components/kid-details-form/KidDetailsForm';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import Sticky from '../../../../components/sticky/Sticky';
import personsInsuredContent from '../../../../content/ui/screens/persons-insured/personsInsured';
import withContent from '../../../../hoc/with-content/withContent';
import useProductSpec from '../../../../hooks/useProductSpec';
import isAgeWithinRange from '../../../../utils/isAgeWithinRange';
import { InsuredPerson, InsuredPersonType } from '../../cart-machine/context/cartMachineContext';
import personsInsuredMachine from './persons-insured-machine/personsInsuredMachine';
import toAccountHolderCheckboxProps from './utils/toAccountHolderCheckboxProps';
import toAdultCheckboxProps from './utils/toAdultCheckboxProps';
import toDependantCheckboxProps from './utils/toDependantCheckboxProps';

import './PersonsInsured.scss';

const contentMap = {
    heading: 'ui.heading',
    myselfLabel: 'ui.myselfLabel',
    kidsLabel: 'ui.kidsLabel',
    kidsInfo: 'ui.kidsInfo',
    addAKidCta: 'ui.addAKidCta',
    addAnAdultCta: 'ui.addAnAdultCta',
    kidDetailsFormHeading: 'ui.kidDetailsFormHeading',
    kidDetailsFormCta: 'ui.kidDetailsFormCta',
    kidDetailsFormCancel: 'ui.kidDetailsFormCancel',
    adultDetailsFormHeading: 'ui.adultDetailsFormHeading',
    adultDetailsFormCta: 'ui.adultDetailsFormCta',
    adultDetailsFormCancel: 'ui.adultDetailsFormCancel',
    kidOver18: 'ui.kidOver18',
    cta: 'ui.cta',
    ageEligibilityTitle: 'ui.ageEligibilityTitle',
};

const personsInsuredFormId = 'persons-insured-form';
const addAKidFormId = 'add-a-kid-form';
const addAnAdultFormId = 'add-an-adult-form';

interface PersonsInsuredProps {
    selectedPersons: InsuredPerson[];
    coverStartDates: string[];
    content: Record<keyof typeof contentMap, string>;
    availableInsuredPersonsTypes: InsuredPersonType[];
    products: ProductResponse[];
    onPersonsUpdateComplete: (insuredPersons: InsuredPerson[]) => void;
    selectedProductOption: ProductOption | null;
}

const PersonsInsured: FC<PersonsInsuredProps> = ({
    selectedPersons,
    coverStartDates,
    content,
    availableInsuredPersonsTypes,
    onPersonsUpdateComplete,
    selectedProductOption,
}) => {
    const { accessToken, userDetails } = useUser();
    const { adults, dependants, addDependants, refreshAdultsInBackground } = usePersons();

    const allPersons = [
        {
            firstName: userDetails.firstName,
            email: userDetails.email,
            personId: userDetails.id,
            type: InsuredPersonType.ACCOUNTHOLDER,
        } as InsuredPerson,
        ...adults.map((x) => {
            return {
                firstName: x.firstName,
                email: x.email,
                personId: x.personId,
                type: InsuredPersonType.ADULT,
            } as InsuredPerson;
        }),
        ...dependants.map((x) => {
            return {
                firstName: x.firstName,
                personId: x.personId,
                type: InsuredPersonType.DEPENDANT,
            } as InsuredPerson;
        }),
    ];

    const [state, send] = useMachine(personsInsuredMachine, {
        context: {
            accessToken,
            selectedPersons,
            allPersons,
        },
        actions: {
            notifyDependantsCreationComplete: (ctx) => onPersonsUpdateComplete(ctx.selectedPersons),
            updateUserContext: (ctx, event) => {
                addDependants(event.data);
            },
            refreshUserContextAdults: refreshAdultsInBackground,
        },
    });

    const accountHolderAvailableToBeInsured = availableInsuredPersonsTypes.includes(InsuredPersonType.ACCOUNTHOLDER);
    const dependantsAvailableToBeInsured = availableInsuredPersonsTypes.includes(InsuredPersonType.DEPENDANT);
    const adultsAvailableToBeInsured = availableInsuredPersonsTypes.includes(InsuredPersonType.ADULT);

    const showAddAKidCta = state.can('ADD_A_KID') && dependantsAvailableToBeInsured;
    const showAddAnAdultCta = state.can('ADD_AN_ADULT') && adultsAvailableToBeInsured;
    const showKidDetailsForm = state.matches('addAKid') && !state.matches('addAKid.spawnKidDetailsFormMachine');
    const showAdultDetailsForm =
        state.matches('addAnAdult') && !state.matches('addAnAdult.spawnAdultDetailsFormMachine');
    const isCreatingDependants = state.matches('addAKid.createDependant');
    const showCreateDependantsError = state.matches('addAKid.displayCreateDependantError');
    const isCreatingAdult = state.matches('addAnAdult.createAdult');
    const showCreateAdultError = state.matches('addAnAdult.displayCreateAdultError');
    const isPersonsInsuredFormInvalid = !state.can('CONTINUE');
    const isAddAKidFormInvalid = state.context.kidDetailsFormMachineRef
        ? state.context.kidDetailsFormMachineRef.getSnapshot()?.hasTag('INVALID')
        : true;
    const isAddAnAdultFormInvalid = state.context.adultDetailsFormMachineRef
        ? state.context.adultDetailsFormMachineRef.getSnapshot()?.hasTag('INVALID')
        : true;
    const isAddingNewInsuredPerson = state.matches('addAKid') || state.matches('addAnAdult');

    const handlePersonsInsuredFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send('CONTINUE');
    };

    const handleAddAKidFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send('SAVE_KID');
    };

    const handleAddAnAdultFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send('SAVE_ADULT');
    };

    const handleInsuredPersonsChange = useCallback(
        (insuredPerson: InsuredPerson) => {
            send({ type: 'TOGGLE_INSURED_PERSON_SELECTION', data: insuredPerson });
        },
        [send],
    );

    const availableInsuredAccountHolder = useMemo<Omit<RadioCheckboxProps, 'checked'>[]>(() => {
        const list: Omit<RadioCheckboxProps, 'checked'>[] = [];
        const ageEligibilityErrorMessage = content.ageEligibilityTitle;

        if (accountHolderAvailableToBeInsured) {
            list.push({
                ...toAccountHolderCheckboxProps(
                    userDetails,
                    coverStartDates,
                    ageEligibilityErrorMessage,
                    content.myselfLabel,
                ),
                onChange: () =>
                    handleInsuredPersonsChange({
                        personId: userDetails.personId!,
                        type: InsuredPersonType.ACCOUNTHOLDER,
                    }),
            });
        }

        return list;
    }, [
        accountHolderAvailableToBeInsured,
        content.ageEligibilityTitle,
        content.myselfLabel,
        coverStartDates,
        handleInsuredPersonsChange,
        userDetails,
    ]);

    const availableInsuredAdults = useMemo<Omit<RadioCheckboxProps, 'checked'>[]>(() => {
        const list: Omit<RadioCheckboxProps, 'checked'>[] = [];

        const adultsList: Omit<RadioCheckboxProps, 'checked'>[] = [];

        if (adultsAvailableToBeInsured) {
            adultsList.push(
                ...adults.map((i) => ({
                    ...toAdultCheckboxProps(i),
                    onChange: () =>
                        handleInsuredPersonsChange({ personId: i.personId!, type: InsuredPersonType.ADULT }),
                })),
            );
        }

        // Sort non account holders by label
        adultsList.sort((a, b) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            if (nameA < nameB) return -1;
            return nameA > nameB ? 1 : 0;
        });

        // Push to list
        list.push(...adultsList);

        return list;
    }, [adults, adultsAvailableToBeInsured, content.ageEligibilityTitle, handleInsuredPersonsChange]);
    const allProductConfig = selectedProductOption?.coverCodes.map((coverCode) => useProductSpec(coverCode));
    const dependantProductConfig = allProductConfig?.find((x) => x?.requiredInsuredPersonRelationship === 'Dependant');

    // TODO use adultProductConfig for add adult form
    const adultProductConfig = allProductConfig?.find((x) => !x?.requiredInsuredPersonRelationship);

    const availableInsuredDependants = useMemo<Omit<RadioCheckboxProps, 'checked'>[]>(() => {
        const list: Omit<RadioCheckboxProps, 'checked'>[] = [];

        const dependantsList: Omit<RadioCheckboxProps, 'checked'>[] = [];

        if (dependantsAvailableToBeInsured) {
            dependantsList.push(
                ...dependants
                    .filter((dependant) =>
                        isAgeWithinRange({ dateOfBirth: dependant.dob ?? '', dates: coverStartDates, isAdult: false }),
                    )
                    .map((i) => ({
                        ...toDependantCheckboxProps(i),
                        onChange: () =>
                            handleInsuredPersonsChange({ personId: i.personId!, type: InsuredPersonType.DEPENDANT }),
                    })),
            );
        }

        // Sort non account holders by label
        dependantsList.sort((a, b) => {
            const nameA = a.label.toUpperCase();
            const nameB = b.label.toUpperCase();
            if (nameA < nameB) return -1;
            return nameA > nameB ? 1 : 0;
        });

        // Push to list
        list.push(...dependantsList);

        return list;
    }, [coverStartDates, dependants, dependantsAvailableToBeInsured, handleInsuredPersonsChange]);

    return (
        <>
            <form id={personsInsuredFormId} onSubmit={handlePersonsInsuredFormSubmit} />
            <form id={addAKidFormId} onSubmit={handleAddAKidFormSubmit} />
            <form id={addAnAdultFormId} onSubmit={handleAddAnAdultFormSubmit} />
            <div className="persons-insured__form-content">
                <Fieldset
                    className="persons-insured__fieldset"
                    legend="Select who is covered"
                    visuallyHideLegend
                    form={personsInsuredFormId}
                >
                    <CheckboxList
                        className="persons-insured__checkbox-list"
                        list={availableInsuredAccountHolder.map(
                            (i): RadioCheckboxProps => ({
                                ...i,
                                disabled: i.disabled || isAddingNewInsuredPerson,
                                checked: !!state.context.selectedPersons.find((p) => p.personId === i.id),
                            }),
                        )}
                    />
                    {availableInsuredAdults.length > 0 && (
                        <h4
                            className={classNames('persons-insured__subheading', {
                                disabled: showKidDetailsForm || showAdultDetailsForm,
                            })}
                        >
                            Adults
                        </h4>
                    )}
                    <CheckboxList
                        className="persons-insured__checkbox-list"
                        list={availableInsuredAdults.map(
                            (i): RadioCheckboxProps => ({
                                ...i,
                                disabled: i.disabled || isAddingNewInsuredPerson,
                                checked: !!state.context.selectedPersons.find((p) => p.personId === i.id),
                            }),
                        )}
                    />
                    {availableInsuredDependants.length > 0 && (
                        <h4
                            className={classNames('persons-insured__subheading', {
                                disabled: showKidDetailsForm || showAdultDetailsForm,
                            })}
                        >
                            Kids
                        </h4>
                    )}
                    <CheckboxList
                        className="persons-insured__checkbox-list"
                        list={availableInsuredDependants.map(
                            (i): RadioCheckboxProps => ({
                                ...i,
                                disabled: i.disabled || isAddingNewInsuredPerson,
                                checked: !!state.context.selectedPersons.find((p) => p.personId === i.id),
                            }),
                        )}
                    />
                </Fieldset>
                {showKidDetailsForm && (
                    <>
                        <div className="persons-insured__insured-person-details-form-heading-section">
                            <p className="persons-insured__insured-person-details-form-heading">
                                {content.kidDetailsFormHeading}
                            </p>
                            <Button
                                type="button"
                                variant="link"
                                label={content.kidDetailsFormCancel}
                                className="persons-insured__insured-person-details-form-cancel-button"
                                onClick={() => send('CANCEL_ADD_A_KID')}
                                prefixIcon={closeBlue}
                            />
                        </div>
                        <Alert
                            type={AlertTypes.INFO}
                            hideIcon
                            message={content.kidsInfo}
                            className="persons-insured__kids-info"
                        />
                        <KidDetailsForm
                            kidDetailsFormMachineRef={state.context.kidDetailsFormMachineRef!}
                            form={addAKidFormId}
                            coverStartDates={coverStartDates}
                            minAge={dependantProductConfig?.eligibility.minAge}
                            maxAge={dependantProductConfig?.eligibility.maxAge}
                        />

                        {showCreateDependantsError && (
                            <Alert className="persons-insured__insured-person-details-form-error" />
                        )}

                        <LoadingButton
                            status={isCreatingDependants ? 'loading' : 'idle'}
                            label={content.kidDetailsFormCta}
                            width="full"
                            variant="secondary"
                            type="submit"
                            form={addAKidFormId}
                            disabled={isAddAKidFormInvalid}
                        />
                    </>
                )}
                {showAdultDetailsForm && (
                    <>
                        <div className="persons-insured__insured-person-details-form-heading-section">
                            <p className="persons-insured__insured-person-details-form-heading">
                                {content.adultDetailsFormHeading}
                            </p>
                            <Button
                                type="button"
                                variant="link"
                                label={content.adultDetailsFormCancel}
                                className="persons-insured__insured-person-details-form-cancel-button"
                                onClick={() => send('CANCEL_ADD_AN_ADULT')}
                                prefixIcon={closeBlue}
                            />
                        </div>
                        <AdultDetailsForm
                            adultDetailsFormMachineRef={state.context.adultDetailsFormMachineRef!}
                            form={addAnAdultFormId}
                            createAdultError={state.context.createAdultError}
                            resetCreateAdultError={() => {
                                send('RESET_ADD_ADULT_ERROR');
                            }}
                            coverStartDates={coverStartDates}
                            minAge={adultProductConfig?.eligibility.minAge}
                            maxAge={adultProductConfig?.eligibility.maxAge}
                        />

                        {showCreateAdultError && (
                            <Alert
                                className="persons-insured__insured-person-details-form-error"
                                message={state.context.createAdultError}
                            />
                        )}

                        <LoadingButton
                            status={isCreatingAdult ? 'loading' : 'idle'}
                            label={content.adultDetailsFormCta}
                            width="full"
                            variant="secondary"
                            type="submit"
                            form={addAnAdultFormId}
                            disabled={isAddAnAdultFormInvalid}
                        />
                    </>
                )}
                {showAddAKidCta && (
                    <Button
                        label={content.addAKidCta}
                        width="full"
                        variant="secondary"
                        onClick={() => send('ADD_A_KID')}
                        className="person-insured__add-insured-person-button"
                    />
                )}
                {showAddAnAdultCta && (
                    <Button
                        label={content.addAnAdultCta}
                        width="full"
                        variant="secondary"
                        onClick={() => send('ADD_AN_ADULT')}
                        className="person-insured__add-insured-person-button"
                    />
                )}
            </div>
            <Sticky>
                <Button
                    label={content.cta}
                    width="full"
                    form={personsInsuredFormId}
                    type="submit"
                    disabled={isPersonsInsuredFormInvalid}
                />
            </Sticky>
        </>
    );
};

export default withContent(PersonsInsured, contentMap, personsInsuredContent);
