/// <reference types="@types/segment-analytics" />

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
    }
}

const WRITE_KEY = import.meta.env.VITE_SEGMENT_WRITE_KEY || '';

const channel = {
    channel: 'Web App',
};

export const page = (category: string, name: string, properties?: Record<string, any>): void => {
    window.analytics.page(category, name, { ...properties, ...channel });
};

export const track = (name: string, properties?: Record<string, any>): void => {
    window.analytics.track(name, { ...properties, ...channel });
};

export const trackV2 = (name: string, properties?: Record<string, any>): void => {
    window.analytics.track(name, { ...properties, version: '2', ...channel });
};

export const identify = (traits?: Record<string, any>, userId?: string): void => {
    if (userId) {
        window.analytics.identify(userId, traits);
    } else {
        window.analytics.identify(traits);
    }
};

export const reset = (): void => {
    window.analytics.reset();
};
