import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import Alert, { AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import usePathParams from '../../hooks/usePathParams';
import Routes from '../../utils/Routes';
import CoverCode from '../../utils/constants/CoverCode';
import getPdsVersion from '../../utils/getPdsVersion';
import parseString from '../../utils/parseString';
import useCoverTerms from '../../hooks/useCoverTerms';
import useCoverInformation from '../../hooks/useCoverInformation';
import { CoverInfoV3 } from '../../content/coverTermsTypes';
import CoverInformationDrawer from '../../components/cover-information-drawer/CoverInformationDrawer';
import Sticky from '../../components/sticky/Sticky';
import common from '../../strings/common';
import Analytics from '../../analytics/Analytics';
import coverInformationContent from '../../content/ui/screens/cover-information/coverInformation';
import withContent from '../../hoc/with-content/withContent';

import './CoverInformation.scss';

const contentMap = {
    backCta: 'ui.backCta',
};

interface CoverInformationProps {
    content: Record<keyof typeof contentMap, string>;
}

const CoverInformation: FC<CoverInformationProps> = ({ content }) => {
    const history = useHistory();
    const {
        productGroups,
        products,
        initialised: productInitialised,
        loading: productLoading,
        coverCategories,
    } = useProduct();
    const { setSelectedProductGroupParam, selectedProductGroup } = usePathParams();
    const coverCode = productGroups.find((x) => x.id === selectedProductGroup)?.representedByCoverCode ?? '';
    const [multiContentTarget, setMultiContentTarget] = useState<keyof CoverInfoV3>('mainMenu');
    const pdsVersion = getPdsVersion({ products, coverCode }) ?? '';
    const newCoverTerms = useCoverTerms(coverCode, pdsVersion)?.coverTermsSections;
    const mainMenuLinks = newCoverTerms?.map((item) => {
        return { target: item.id, label: item.label };
    });
    const coverInformation = useCoverInformation(coverCode, pdsVersion);
    const selectedCoverCategory = coverCategories.find((x) => x.coverCodes.includes(coverCode as CoverCode));

    const redirectToProductGroupPurchase = useCallback(
        (productGroup: string): void => {
            history.push({
                pathname: setSelectedProductGroupParam(Routes.PURCHASE_ENTRY, productGroup),
            });
        },
        [history],
    );

    return (
        <Layout showBackButton showLoading={!productInitialised || productLoading} className="cover-information">
            {(!productInitialised || !newCoverTerms || !mainMenuLinks) && (
                <Alert type={AlertTypes.ERROR} message={ErrorMessages.refreshOrContactUs} />
            )}
            <div className="cover-information__content">
                <div className="slide-in-coverterms__main__pricing">
                    <span>{parseString(coverInformation?.groupPrice ?? '')}</span>
                </div>
                <h1>{selectedCoverCategory?.name}</h1>
                <p>{parseString(selectedCoverCategory?.description ?? '')}</p>
                {mainMenuLinks?.map((link: { target: string; label: string }) => (
                    <div key={link.target}>
                        <Button
                            key={link.target}
                            type="button"
                            variant="drawer-link"
                            width="full"
                            label={link.label}
                            className="slide-in-coverterms__main__button"
                            onClick={() => setMultiContentTarget(link.target as keyof CoverInfoV3)}
                        />
                        <div className="slide-in-coverterms__main__button__divider" />
                    </div>
                ))}
            </div>
            <CoverInformationDrawer
                multiContentTarget={multiContentTarget}
                setMultiContentTarget={setMultiContentTarget}
                coverCode={coverCode as CoverCode}
                pdsVersion={pdsVersion}
                isOpen={multiContentTarget !== 'mainMenu'}
                onClose={() => setMultiContentTarget('mainMenu')}
            />
            <Sticky>
                <Button
                    label={common.buyNow}
                    className="cover-information__cta"
                    onClick={() => {
                        if (selectedProductGroup) {
                            Analytics.trackProductInterestConfirmed();
                            redirectToProductGroupPurchase(selectedProductGroup);
                        }
                    }}
                />
                <Button
                    label={content.backCta}
                    variant="secondary"
                    className="cover-information__cta"
                    onClick={() => {
                        history.goBack();
                    }}
                />
            </Sticky>
        </Layout>
    );
};

export default withContent(CoverInformation, contentMap, coverInformationContent);
