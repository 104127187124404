import classNames from 'classnames';
import { FC } from 'react';
import { ProductOption } from '../../../../business-logic/models/ProductGroupsResponse';
import useCoverInformation from '../../../../hooks/useCoverInformation';
import useProductSpec from '../../../../hooks/useProductSpec';
import RadioButton from '../../../../components/form/radio-button/RadioButton';

import './PurchaseOptionsRadioButton.scss';

interface PurchaseOptionsRadioButtonProps {
    productOption: ProductOption;
    checked?: boolean;
    onChange: (productOptionId: string) => void;
}

const PurchaseOptionsRadioButton: FC<PurchaseOptionsRadioButtonProps> = ({ productOption, checked, onChange }) => {
    const { id, representedByCoverCode } = productOption;
    const coverInformation = useCoverInformation(representedByCoverCode);
    const productSpec = useProductSpec(representedByCoverCode);

    if (coverInformation === null || coverInformation.purchaseOptionsContent === null || productSpec === null) {
        return null;
    }

    const { optionLabel, optionDescription, priceHighlight, optionUnit } = coverInformation.purchaseOptionsContent!;

    const handleChange = () => {
        onChange(id);
    };

    return (
        <RadioButton
            id={id}
            name={optionLabel}
            checked={checked}
            onChange={handleChange}
            className={classNames('purchase-options-radio-button')}
            label={
                <div className="purchase-options-radio-button__wrapper">
                    <div className="purchase-options-radio-button__content-area">
                        <div className="purchase-options-radio-button__text-content">
                            <span className="purchase-options-radio-button__label">{optionLabel}</span>
                            <span className="purchase-options-radio-button__description">{optionDescription}</span>
                        </div>
                    </div>
                    <div className="purchase-options-radio-button__price-content">
                        {priceHighlight !== undefined ? (
                            <span
                                className={classNames(
                                    'purchase-options-radio-button__price-highlight',
                                    `purchase-options-radio-button__price-highlight--${priceHighlight.variant}`,
                                )}
                            >
                                {priceHighlight.text}
                            </span>
                        ) : null}
                        <div className="purchase-options-radio-button__price__wrapper">
                            <span className="purchase-options-radio-button__currency">$</span>
                            <span className="purchase-options-radio-button__price">
                                {productSpec.billing.premium / 100}
                            </span>
                        </div>
                        {optionUnit !== undefined ? (
                            <span className="purchase-options-radio-button__price-unit">{optionUnit}</span>
                        ) : null}
                    </div>
                </div>
            }
        />
    );
};

export default PurchaseOptionsRadioButton;
