import { CreateAliasRequest } from '../../models/CreateAlias';
import { Alias } from '../../models/Alias';
import UserDetails from '../../models/UserDetails';

// Interfaces
export interface PersonsState {
    loading: boolean;
    initialised: boolean;
    dependants: UserDetails[];
    adults: Alias[];
    insurablePersons: (Alias | UserDetails)[];
}

export interface PersonsActions {
    setDependants: (dependants: UserDetails[]) => void;
    setAdults: (adults: Alias[]) => void;
    addDependants: (dependant: UserDetails) => void;
    refreshAdultsInBackground: () => Promise<void>;
}

export const initialState: PersonsState = {
    loading: false,
    initialised: false,
    dependants: [],
    adults: [],
    insurablePersons: [],
};

export type IPersonsContext = PersonsState & PersonsActions;

export type PersonsDispatchAction =
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_INITIALISED'; payload: boolean }
    | { type: 'SET_DEPENDANTS'; payload: UserDetails[] }
    | { type: 'SET_ADULTS'; payload: Alias[] }
    | { type: 'ADD_DEPENDANT'; payload: UserDetails }
    | { type: 'ADD_ADULT'; payload: CreateAliasRequest };
