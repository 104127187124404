import { assign, spawn } from 'xstate';
import { Alias, AliasError } from '../../../../../business-logic/models/Alias';
import UserDetails from '../../../../../business-logic/models/UserDetails';
import adultDetailsFormMachine from '../../../../../components/adult-details-form/adult-details-form-machine/adultDetailsFormMachine';
import kidDetailsFormMachine from '../../../../../components/kid-details-form/kid-details-form-machine/kidDetailsFormMachine';
import { InsuredPerson, InsuredPersonType } from '../../../cart-machine/context/cartMachineContext';
import { PersonsInsuredMachineContextTypes } from '../context/personsInsuredMachineContext';
import { Typegen0 } from '../persons-insured-machine/personsInsuredMachine.typegen';
import Analytics from '../../../../../analytics/Analytics';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const spawnKidDetailsFormMachine = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['spawnKidDetailsFormMachine'] }
>({
    kidDetailsFormMachineRef: () => spawn(kidDetailsFormMachine, { sync: true }),
}) as any;

export const spawnAdultDetailsFormMachine = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['spawnAdultDetailsFormMachine'] }
>({
    adultDetailsFormMachineRef: () => spawn(adultDetailsFormMachine, { sync: true }),
}) as any;

export const toggleInsuredPersonSelection = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['toggleInsuredPersonSelection']; data: InsuredPerson }
>({
    selectedPersons: (ctx, event) => {
        const isPersonSelected = ctx.selectedPersons.find((p) => p.personId === event.data.personId);
        if (isPersonSelected) {
            return ctx.selectedPersons.filter((p) => p.personId !== event.data.personId);
        }
        return [...ctx.selectedPersons, event.data];
    },
}) as any;

export const setRecentlyAddedKid = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['setRecentlyAddedKid']; data: UserDetails }
>({
    selectedPersons: (ctx, event) => {
        return [...ctx.selectedPersons, { personId: event.data.personId!, type: InsuredPersonType.DEPENDANT }];
    },
}) as any;

export const setRecentlyAddedAdult = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['setRecentlyAddedAdult']; data: Alias }
>({
    selectedPersons: (ctx, event) => {
        return [...ctx.selectedPersons, { personId: event.data.personId!, type: InsuredPersonType.ADULT }];
    },
    allPersons: (ctx, event) => [...ctx.allPersons, { type: InsuredPersonType.ADULT, ...event.data }],
}) as any;

export const setCreateAdultError = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['setCreateAdultError']; data: Error }
>({
    createAdultError: (_, event) => {
        return (event.data.cause as AliasError).errorMessage;
    },
}) as any;

export const clearCreateAdultError = assign<
    PersonsInsuredMachineContextTypes,
    { type: EventsCausingActions['clearCreateAdultError'] }
>({
    createAdultError: () => {
        return null;
    },
}) as any;

export const trackAdultAdded = () => {
    Analytics.trackAddedAdult();
};

export const trackDependantAdded = () => {
    Analytics.trackAddedDependant();
};
