import axios from 'axios';
import asService from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';
import { AllFlipContent } from '../../business-logic/models/cdn-content/FlipContent';

const baseApiPath = import.meta.env.VITE_BASE_CDN_PATH;

const getAllContent = async (): Promise<AllFlipContent> => {
    try {
        const { data } = await axios.get<AllFlipContent>(`${baseApiPath}/json/cdn.json`);
        return data;
    } catch (error) {
        if (isAxios404Error(error)) {
            throw new Error(`Unable to retrieve all cdn content from Flip content delivery network.`);
        }
        throw new Error(`Error retrieving all cdn content from Flip content delivery network`, {
            cause: error as Error,
        });
    }
};

export default asService(getAllContent, false);
