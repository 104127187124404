import classNames from 'classnames';

import { RadioCheckboxProps } from '../RadioCheckboxProps';
import RADIO_CHECKBOX_SIZE from '../RadioCheckboxSize';

import './RadioButton.scss';

const RadioButton: React.FC<
    RadioCheckboxProps & {
        icon?: string;
    }
> = ({
    id,
    name,
    label,
    className,
    checked,
    form,
    onChange,
    onBlur,
    onFocus,
    disabled,
    isError,
    size = RADIO_CHECKBOX_SIZE.LARGE,
    icon,
}) => {
    return (
        <label
            htmlFor={id}
            className={classNames(
                'radio-button',
                { checked, disabled, isError, 'radio-button--icon': !!icon },
                className,
            )}
        >
            <input
                type="radio"
                id={id}
                name={name}
                checked={checked}
                className="radio-button__input"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                form={form}
                disabled={disabled}
            />
            {!!icon ? (
                <img src={icon} alt="" className="radio-button__icon" />
            ) : (
                <div
                    className={classNames(
                        'radio-button__decorated-radio',
                        {
                            checked,
                            disabled,
                            isError,
                        },
                        size === RADIO_CHECKBOX_SIZE.LARGE ? 'radio-button--large' : 'radio-button--small',
                    )}
                />
            )}
            {label}
        </label>
    );
};

export default RadioButton;
