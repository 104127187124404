import { useMachine } from '@xstate/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import stripeLogo from '../../../../assets/images/stripe-logo.svg';
import { useUser } from '../../../../business-logic/context-provider/user-context';
import { CartCheckout, CartErrorDisplay, CartInstanceResponse } from '../../../../business-logic/models/Cart';
import { AllCoverInformation } from '../../../../business-logic/models/cdn-content/CoverInformation';
import ProductGroupsResponse from '../../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import { ReviewCoverSelection } from '../../../../business-logic/models/ReviewCoverSelection';
import Alert, { AlertTypes } from '../../../../components/alert/Alert';
import CartError from '../../../../components/cart-error/CartError';
import CoverSummaryCard from '../../../../components/cover-summary-card/CoverSummaryCard';
import ExternalLink from '../../../../components/external-link/ExternalLink';
import { LoadingButtonProps } from '../../../../components/loading-button/LoadingButton';
import LoadingSpinner from '../../../../components/loading-spinner/LoadingSpinner';
import PaymentMethods from '../../../../components/payment-methods/PaymentMethods';
import ReviewCoverFinePrint from '../../../../components/review-cover-fine-print/ReviewCoverFinePrint';
import TextFieldWithButton from '../../../../components/text-field-with-button/TextFieldWithButton';
import guestCartContent from '../../../../content/ui/screens/guest-cart/guestCart';
import withContent from '../../../../hoc/with-content/withContent';
import useLazyDependency from '../../../../hooks/lazy-dependency/useLazyDependency';
import useCart from '../../../../hooks/use-cart/useCart';
import { default as commonStrings } from '../../../../strings/common';
import { default as paymentsStrings } from '../../../../strings/payments';
import { PurchaseState } from '../../../../types/PurchaseState';
import ExternalLinks from '../../../../utils/constants/ExternalLinks';
import StorageKeys from '../../../../utils/constants/StorageKeys';
import formatPrice from '../../../../utils/formatPrice';
import getCartRequestItems from '../../../../utils/getCartRequestItems';
import Routes from '../../../../utils/Routes';
import checkoutMachine from '../../../checkout/checkout-machine/checkoutMachine';
import createAccountCreditListItem from '../../../checkout/checkout-summary/list-item-creators/createAccountCreditListItem';
import createDiscountCodeListItem from '../../../checkout/checkout-summary/list-item-creators/createDiscountCodeListItem';
import { InsuredPerson } from '../../cart-machine/context/cartMachineContext';

import './PaymentStep.scss';

const contentMap = {
    title: 'ui.paymentStep.title',
    ctaLabel: 'ui.paymentStep.ctaLabel',
    total: 'ui.paymentStep.total',
};

interface PaymentStepProps {
    content: Record<keyof typeof contentMap, string>;
    purchaseState: PurchaseState;
    products: ProductResponse[];
    productGroups: ProductGroupsResponse;
    userTimeZone: string;
    coverInformation: AllCoverInformation | null;
    insuredPersons: InsuredPerson[];
    activities: string[];
    onInvalidCoverSelection: () => void;
    onPaymentComplete: () => void;
    onGoToPrevStep: () => void;
}

const PaymentStep: FC<PaymentStepProps> = ({
    content,
    purchaseState,
    products,
    productGroups,
    userTimeZone,
    coverInformation,
    insuredPersons,
    onInvalidCoverSelection,
    activities,
    onPaymentComplete,
}) => {
    const { createCart, closeCart } = useCart();
    const history = useHistory();
    const ldClient = useLDClient();
    const { creditBalance: cbLazyDepObject, accessToken } = useUser();
    const creditBalance = useLazyDependency(cbLazyDepObject);

    const [state, send] = useMachine(checkoutMachine, {
        context: {
            products,
            productGroups,
            coverInformation,
            accessToken,
            purchaseState,
            fetchCreditBalance: creditBalance.fetch,
            selectedPaymentMethod: 'card',
            activities,
        },
        actions: {
            openCart: () => {
                createCart.mutate(
                    getCartRequestItems(purchaseState, products, productGroups, userTimeZone, insuredPersons),
                    {
                        onError: (err) => {
                            send({
                                type: 'CART_CREATE_ERROR',
                                data: (err as Error).cause as CartErrorDisplay,
                            });
                        },
                        onSuccess: (data) => {
                            send({ type: 'CART_CREATED', data: data as CartInstanceResponse });
                        },
                    },
                );
            },
            ldTrackCheckoutCompleted: () => {
                ldClient?.track('Order Completed');
                ldClient?.flush();
            },
            closeCart: () => {
                closeCart.mutate(undefined, {
                    onError: (err) => {
                        send({
                            type: 'CART_CLOSE_ERROR',
                            data: (err as Error).cause as CartErrorDisplay,
                        });
                    },
                    onSuccess: (data) => {
                        send({ type: 'CART_CLOSE', data: data as CartCheckout });
                    },
                });
            },
            goBack: () => {
                history.goBack();
            },
            redirectToSuccessPage: () => {
                onPaymentComplete();

                sessionStorage.removeItem(StorageKeys.CART_COVER_SELECTION);
                history.push({
                    pathname: Routes.CART_SUCCESS,
                    state: {
                        selectedProductGrouping: null,
                        selectedProductOption: null,
                        coverStartDates: [],
                        destination: null,
                    },
                });
            },
        },
    });

    const applyDiscountCodeStatus: LoadingButtonProps['status'] = useMemo(() => {
        if (state.matches('ready.discountCode.displayDiscountSuccess')) {
            return 'success';
        }
        if (state.matches('ready.discountCode.applyDiscount')) {
            return 'loading';
        }
        return 'idle';
    }, [state]);

    const renderDiscountCodeSection = () => (
        <div className="payment-step__discount-code-disclosure-wrapper">
            <form className="payment-step__discount-code-form">
                <div className="payment-step__discount-code">
                    <TextFieldWithButton
                        textFieldProps={{
                            placeholder: paymentsStrings.discountCode,
                            id: 'discountCode',
                            name: 'discountCode',
                            value: state.context.discountCode,
                            onChange: (e) => send('ENTER_DISCOUNT_CODE', { data: e.target.value }),
                            isError: state.matches('ready.discountCode.displayDiscountError'),
                            disabled:
                                state.matches('ready.discountCode.applyDiscount') ||
                                state.matches('ready.discountCode.displayDiscountSuccess'),
                        }}
                        buttonProps={{
                            status: applyDiscountCodeStatus,
                            label: commonStrings.apply,
                            onClick: (e) => {
                                e.preventDefault();
                                send('APPLY_DISCOUNT_CODE');
                            },
                            disabled:
                                !state.can('APPLY_DISCOUNT_CODE') ||
                                state.matches('ready.discountCode.displayDiscountError'),
                            type: 'submit',
                            width: 'full',
                        }}
                    />
                </div>
                {state.matches('ready.discountCode.displayDiscountError') && (
                    <Alert
                        type={AlertTypes.ERROR}
                        message={
                            <span className="payment-step__discount-code-error-message">
                                {state.context.discountErrorInfo}
                            </span>
                        }
                        className="payment-step__discount-code-error"
                    />
                )}
            </form>
        </div>
    );

    useEffect(() => {
        if (state.matches('redirectToDashboard')) {
            onInvalidCoverSelection();
        }
    }, [onInvalidCoverSelection, state]);

    if (state.hasTag('initialising')) {
        return <LoadingSpinner />;
    }

    return (
        <div className="payment-step">
            {state.matches('ready') && (
                <div className="payment-step__summary">
                    {renderDiscountCodeSection()}

                    {state.context.cartItemsByCoverCode.map((coverCodeGroup, index) => (
                        <div key={coverCodeGroup.selectedCoverCode}>
                            <CoverSummaryCard
                                className={index > 0 ? 'cover-summary-card--multiple' : undefined}
                                subtotal={coverCodeGroup.subTotalByCoverCode}
                                checkoutChipText={coverCodeGroup.checkoutChipText}
                                coverName={coverCodeGroup.name}
                                unitLabel={coverCodeGroup.unitLabel!}
                                hideUnitCount={coverCodeGroup.hideUnitCount}
                                coverType={coverCodeGroup.coverType}
                                paymentModelDescription={coverCodeGroup.paymentModelDescription!}
                                coverSelections={coverCodeGroup.coverItems as ReviewCoverSelection[]}
                                unitPrice={coverCodeGroup.unitPrice}
                            />
                        </div>
                    ))}
                    {state.context.checkoutDetails.coupon && (
                        <div className="payment-step__discount-line-item">
                            {createDiscountCodeListItem(state.context.checkoutDetails)}
                        </div>
                    )}
                    {!!creditBalance.value && createAccountCreditListItem(state.context.checkoutDetails)}
                    <p className="payment-step__total">
                        {content.total}
                        <span className="payment-step__total-amount">
                            {formatPrice(state.context.checkoutDetails.invoice.amountDue)}
                        </span>
                    </p>
                    <ReviewCoverFinePrint ctaLabel={content.ctaLabel} />
                    <>
                        <PaymentMethods state={state} send={send} />
                        <p className="payment-step-disclaimer">
                            {paymentsStrings.formatString(paymentsStrings.disclaimer, {
                                stripeTerms: (
                                    <ExternalLink
                                        to={ExternalLinks.stripeTerms}
                                        label={paymentsStrings.stripeTermsLabel}
                                    />
                                ),
                            })}
                        </p>
                        <div className="payment-step-powered-by">
                            <p className="payment-step-powered-by__text">{paymentsStrings.poweredBy}</p>
                            <img src={stripeLogo} alt="Stripe" />
                        </div>
                    </>
                </div>
            )}
            {state.hasTag('error') && !!state.context.cartError && (
                <>
                    <CartError
                        cartError={state.context.cartError}
                        prevStepEvent={function (): void {
                            history.push(Routes.HOME);
                        }}
                        tryAgainEvent={function (): void {
                            send({ type: 'CART_TRY_AGAIN' });
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default withContent(PaymentStep, contentMap, guestCartContent);
