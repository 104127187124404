import { AxiosError, isAxiosError } from 'axios';
import { Alias } from '../../../../../business-logic/models/Alias';
import UserDetails from '../../../../../business-logic/models/UserDetails';
import adultDetailsFormContent from '../../../../../content/ui/components/adult-details-form/adultDetailsForm';
import PersonService from '../../../../../services/person-service/PersonService';
import isAxios409Error from '../../../../../services/utils/isAxios409Error';
import common from '../../../../../strings/common';
import formatDateToString from '../../../../../utils/formatDateToString';
import { PersonsInsuredMachineContextTypes } from '../context/personsInsuredMachineContext';

export type PersonsInsuredMachineServices = {
    createDependant: {
        data: UserDetails;
    };
    createAdult: {
        data: Alias | Error;
    };
};

export const createDependant = async (ctx: PersonsInsuredMachineContextTypes): Promise<UserDetails> => {
    const child = ctx.kidDetailsFormMachineRef?.getSnapshot()?.context;
    const { firstName, lastName, dob, gender } = child!;
    const { dependants } = await PersonService.createDependants({
        accessToken: ctx.accessToken,
        dependants: [
            {
                firstName,
                lastName,
                dateOfBirth: formatDateToString(new Date(`${dob.year}/${dob.month}/${dob.day}`)),
                gender: gender!,
            },
        ],
    });
    return dependants[0];
};

export const createAdult = async (ctx: PersonsInsuredMachineContextTypes): Promise<Alias | Error> => {
    const adult = ctx.adultDetailsFormMachineRef?.getSnapshot()?.context;
    const { firstName, lastName, dob, email } = adult!;
    try {
        const response = await PersonService.createAlias({
            accessToken: ctx.accessToken,
            alias: {
                firstName,
                lastName,
                dateOfBirth: formatDateToString(new Date(`${dob.year}/${dob.month}/${dob.day}`)),
                email: email.toLowerCase(),
            },
        });
        return response;
    } catch (err) {
        if (isAxiosError(err)) {
            const error = err as AxiosError;
            if (error.status == 422) {
                const errorMessage = adultDetailsFormContent.formatString(
                    adultDetailsFormContent.conflictAccountHolderEmail.errorMessage,
                    {
                        firstName,
                    },
                );
                throw new Error('Throwing Create Alias Conflict Error', {
                    cause: {
                        errorCode: error.status,
                        errorMessage,
                    },
                });
            }

            if (isAxios409Error(err)) {
                const aliasInConflict = ctx.allPersons.find((x) => x.email?.toLowerCase() === email.toLowerCase());
                const errorMessage = aliasInConflict
                    ? adultDetailsFormContent.formatString(
                          adultDetailsFormContent.conflictExistingAliasEmail.errorMessage,
                          {
                              firstName: aliasInConflict?.firstName ?? '',
                          },
                      )
                    : adultDetailsFormContent.conflictExistingEmail.errorMessage;
                throw new Error('Throwing Create Alias Conflict Error', {
                    cause: {
                        errorCode: error.status,
                        errorMessage,
                    },
                });
            }
        }

        throw new Error('Throwing Create Alias Generic Error', {
            cause: {
                errorMessage: common.errorSomethingWentWrongTryAgain,
            },
        });
    }
};
