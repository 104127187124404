import { ActorRefFrom, assign, createMachine, send, spawn } from 'xstate';
import Analytics from '../../../analytics/Analytics';
import { CartErrorDisplay, CartInstanceResponse, CartResponseItem } from '../../../business-logic/models/Cart';
import { AllCoverInformation } from '../../../business-logic/models/cdn-content/CoverInformation';
import {
    CheckoutCoverResponse,
    CheckoutDetailsResponse,
    CoverResponse,
} from '../../../business-logic/models/CheckoutDetails';
import ProductGroupsResponse from '../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../business-logic/models/ProductResponse';
import { ReviewCoverCartItem, ReviewCoverSelection } from '../../../business-logic/models/ReviewCoverSelection';
import { UserPaymentMethod } from '../../../business-logic/models/UserPaymentMethod';
import paymentElementMachine from '../../../components/payment-methods/payment-element/payment-element-machine/paymentElementMachine';
import AnalyticsMetadataService from '../../../services/analytics-metadata-service/AnalyticsMetadataService';
import PaymentService from '../../../services/payment-service/PaymentService';
import common from '../../../strings/common';
import payments from '../../../strings/payments';
import { PurchaseState } from '../../../types/PurchaseState';
import CoverTypeId from '../../../utils/constants/CoverTypeId';
import DateFormat from '../../../utils/constants/DateFormat';
import formatDateToString from '../../../utils/formatDateToString';

const checkoutMachine = createMachine(
    {
        id: 'CheckoutMachine',
        initial: 'createCart',
        tsTypes: {} as import('./checkoutMachine.typegen').Typegen0,
        schema: {
            context: {} as {
                accessToken: string;
                products: ProductResponse[];
                coverInformation: AllCoverInformation | null;
                productGroups: ProductGroupsResponse | null;
                discountCode: string;
                purchaseState: PurchaseState;
                discountErrorInfo: string;
                checkoutErrorInfo: string;
                checkoutDetails: CheckoutDetailsResponse;
                incomingCheckoutDetails: CheckoutDetailsResponse;
                covers: CheckoutCoverResponse[];
                coverSelections: ReviewCoverSelection[];
                paymentMethodMachine: ActorRefFrom<typeof paymentElementMachine>;
                paymentMethods: UserPaymentMethod[];
                fetchCreditBalance: () => void;
                selectedPaymentMethod: string;
                activities: string[];
                cartError: CartErrorDisplay | null;
                cartItemsByCoverCode: ReviewCoverCartItem[];
            },
            events: {} as
                | { type: 'ENTER_DISCOUNT_CODE'; data: string }
                | { type: 'APPLY_DISCOUNT_CODE' }
                | { type: 'SETTING_UP_PAYMENT_METHOD' }
                | { type: 'CART_CREATED'; data: CartInstanceResponse }
                | {
                      type: 'CART_CLOSE';
                      data: {
                          checkoutDetails: {
                              checkoutDetails: CartInstanceResponse;
                              covers: CartResponseItem[];
                          };
                      };
                  }
                | {
                      type: 'CART_CLOSE_ERROR';
                      data: CartErrorDisplay;
                  }
                | {
                      type: 'CART_CREATE_ERROR';
                      data: CartErrorDisplay;
                  }
                | { type: 'GO_BACK' }
                | { type: 'CART_TRY_AGAIN' }
                | {
                      type: 'INITIATE_CHECKOUT';
                      data: { paymentMethodId?: string; setAsDefault?: boolean; selectedPaymentMethod?: string };
                  },
        },
        states: {
            createCart: {
                entry: ['openCart'],
                tags: ['initialising'],
                on: {
                    CART_CREATE_ERROR: {
                        actions: ['setCartError'],
                        target: 'displayCartError',
                    },
                    CART_CREATED: {
                        actions: ['setCoversToDisplay'],
                        target: 'getCheckoutDetailsFromClosedCart',
                    },
                },
            },
            closeCart: {
                entry: 'closeCart',
                tags: ['initialising'],
                on: {
                    CART_CLOSE: {
                        target: 'updateMetadata',
                        actions: ['setCheckoutDetails'],
                    },
                    CART_CLOSE_ERROR: {
                        target: 'displayCartError',
                    },
                },
            },
            getPaymentMethods: {
                tags: ['initialising'],
                invoke: {
                    src: 'getPaymentMethods',
                    onDone: {
                        target: 'ready',
                        actions: ['setPaymentMethods', 'spawnPaymentMethodMachine'],
                    },
                    onError: {
                        target: 'displayErrorPage',
                    },
                },
            },
            updateMetadata: {
                tags: ['initialising'],
                invoke: {
                    src: 'setMetadata',
                    onDone: [
                        {
                            target: 'getPaymentMethods',
                        },
                    ],
                    onError: [
                        {
                            target: 'getPaymentMethods',
                        },
                    ],
                },
            },
            displayCartError: {
                tags: ['error'],
                on: {
                    CART_TRY_AGAIN: {
                        actions: ['resetCartError'],
                        target: 'createCart',
                    },
                    GO_BACK: {
                        actions: 'goBack',
                    },
                },
            },
            getCheckoutDetailsFromClosedCart: {
                always: [
                    {
                        cond: 'isValidSelectedCover',
                        target: 'closeCart',
                    },
                    {
                        target: 'redirectToDashboard',
                    },
                ],
            },
            redirectToDashboard: {
                type: 'final',
                description: 'This is probably a case of somebody going directly to this URL',
            },
            ready: {
                type: 'parallel',
                states: {
                    discountCode: {
                        initial: 'idle',
                        states: {
                            idle: {
                                on: {
                                    ENTER_DISCOUNT_CODE: [
                                        {
                                            cond: 'isDiscountCodeValid',
                                            actions: 'setDiscountCode',
                                            target: 'readyToApplyDiscountCode',
                                        },
                                        {
                                            actions: 'setDiscountCode',
                                            target: 'idle',
                                        },
                                    ],
                                },
                            },
                            readyToApplyDiscountCode: {
                                on: {
                                    ENTER_DISCOUNT_CODE: [
                                        {
                                            cond: 'isDiscountCodeValid',
                                            actions: 'setDiscountCode',
                                            target: 'readyToApplyDiscountCode',
                                        },
                                        {
                                            actions: 'setDiscountCode',
                                            target: 'idle',
                                        },
                                    ],
                                    APPLY_DISCOUNT_CODE: {
                                        target: 'applyDiscount',
                                    },
                                },
                            },
                            applyDiscount: {
                                entry: 'trackCouponEntered',
                                invoke: {
                                    src: 'applyPromoCode',
                                    onDone: {
                                        actions: ['setIncomingCheckoutDetails', 'trackCouponApplied'],
                                        target: 'displayDiscountSuccess',
                                    },
                                    onError: {
                                        actions: ['setDiscountErrorInfo', 'trackCouponDenied'],
                                        target: 'displayDiscountError',
                                    },
                                },
                            },
                            displayDiscountSuccess: {
                                after: {
                                    2000: {
                                        actions: ['updateCheckoutDetails', 'clearDiscountCode'],
                                        target: 'idle',
                                    },
                                },
                            },
                            displayDiscountError: {
                                on: {
                                    ENTER_DISCOUNT_CODE: [
                                        {
                                            cond: 'isDiscountCodeValid',
                                            actions: ['setDiscountCode', 'clearDiscountErrorInfo'],
                                            target: 'readyToApplyDiscountCode',
                                        },
                                        {
                                            actions: ['setDiscountCode', 'clearDiscountErrorInfo'],

                                            target: 'idle',
                                        },
                                    ],
                                    APPLY_DISCOUNT_CODE: {
                                        target: 'applyDiscount',
                                    },
                                },
                            },
                        },
                    },
                    checkout: {
                        initial: 'idle',
                        states: {
                            idle: {
                                on: {
                                    INITIATE_CHECKOUT: {
                                        actions: 'setSelectedPaymentMethod',
                                        target: 'initiateCheckout',
                                    },
                                },
                            },
                            initiateCheckout: {
                                invoke: {
                                    src: 'checkout',
                                    onDone: {
                                        target: 'showCheckoutSuccess',
                                    },
                                    onError: {
                                        actions: 'setCheckoutErrorInfo',
                                        target: 'displayCheckoutError',
                                    },
                                },
                            },
                            displayCheckoutError: {
                                entry: 'sendCheckoutErrorEvent',
                                on: {
                                    INITIATE_CHECKOUT: {
                                        actions: 'setSelectedPaymentMethod',
                                        target: 'initiateCheckout',
                                    },
                                    SETTING_UP_PAYMENT_METHOD: {
                                        target: 'idle',
                                    },
                                },
                            },
                            showCheckoutSuccess: {
                                entry: [
                                    'sendCheckoutSuccessEvent',
                                    'fetchNewCreditBalance',
                                    'trackCheckoutCompleted',
                                    'ldTrackCheckoutCompleted',
                                ],
                                after: {
                                    2000: {
                                        target: 'successRedirect',
                                    },
                                },
                            },
                            successRedirect: {
                                type: 'final',
                                entry: 'redirectToSuccessPage',
                            },
                        },
                    },
                },
            },
            displayErrorPage: {
                type: 'final',
            },
        },
    },
    {
        guards: {
            isValidSelectedCover: (ctx) => {
                return !!ctx.purchaseState.selectedProductOption;
            },
            isDiscountCodeValid: (ctx, event) => {
                // Following existing Stripe behaviour
                return event.data.length > 3;
            },
        },
        services: {
            getPaymentMethods: async (ctx) => {
                const paymentMethods = await PaymentService.getPaymentMethods({ accessToken: ctx.accessToken });

                return {
                    paymentMethods,
                };
            },
            applyPromoCode: (ctx) => {
                const applyPromoCodeBody = {
                    accessToken: ctx.accessToken,
                    paymentId: ctx.checkoutDetails.paymentId,
                    promoCode: ctx.discountCode,
                };

                return PaymentService.applyPromoCode(applyPromoCodeBody);
            },
            checkout: (ctx, event) => {
                const checkoutBody = {
                    accessToken: ctx.accessToken,
                    paymentId: ctx.checkoutDetails.paymentId,
                    paymentMethodId: event.data.paymentMethodId,
                    setAsDefault: event.data.setAsDefault,
                };

                return PaymentService.payCheckout(checkoutBody);
            },
            setMetadata: async (ctx) => {
                await AnalyticsMetadataService.updateActivitiesMetadata({
                    accessToken: ctx.accessToken,
                    activityMetadata: {
                        paymentId: ctx.checkoutDetails.paymentId,
                        activities: ctx.activities,
                    },
                });

                if (ctx.purchaseState?.destination) {
                    await AnalyticsMetadataService.updateDestinationsMetadata({
                        accessToken: ctx.accessToken,
                        destinationMetadata: ctx.cartItemsByCoverCode
                            .map((x) => x.coverItems)
                            .flat()
                            .map((y) => {
                                return {
                                    insuranceCoverId: y.insuranceCoverId ?? '',
                                    destination: {
                                        destinations: ctx.purchaseState?.destination?.destinations ?? [],
                                        startingRegion: {
                                            region: ctx.purchaseState?.destination?.startingRegion ?? '',
                                            timeZone: ctx.purchaseState?.destination?.timezone ?? '',
                                        },
                                    },
                                };
                            }),
                    });
                }
            },
        },
        actions: {
            setCartError: assign({
                cartError: (_, event: any) => event.data,
            }),
            resetCartError: assign({
                cartError: (_, event: any) => null,
            }),
            setCoversToDisplay: assign({
                cartItemsByCoverCode: (ctx, event) => {
                    const cartResponse = event.data;
                    const allProducts = ctx.products.map((x) => {
                        const pdsVersion = `${x?.pdsVersionMajor}.${x?.pdsVersionMinor}`;
                        const selectedCoverInformation =
                            ctx.coverInformation![x.productSpec.mainCoverType.coverCode][pdsVersion];
                        return {
                            coverCode: x.productSpec.mainCoverType.coverCode,
                            price: x.productSpec.mainCoverType.billing.premium,
                            coverType: x.productSpec.mainCoverTypeId,
                            selectedCoverInformation,
                        };
                    });

                    const baseObject = allProducts.map((x) => {
                        return { [x.coverCode]: [] };
                    });

                    const parseCoversFromCart =
                        cartResponse?.coverItems.reduce<Record<string, ReviewCoverSelection[]>>(
                            (acc, cur) => {
                                const productConfig = allProducts.find((x) => x.coverCode === cur.coverCode);
                                const productOptionConfig = ctx.productGroups?.find((y) =>
                                    y.options.find((x) => x.coverCodes.includes(cur.coverCode)),
                                );

                                if (!productConfig || !productOptionConfig) return acc;

                                acc[cur.coverCode].push({
                                    selectedCover: cur.coverCode,
                                    coverStartDate: cur.startTime,
                                    personId: cur.insuredPersonId,
                                    timezone: ctx.purchaseState?.destination?.timezone ?? null,
                                    insurancePolicyId: cur.insurancePolicyId,
                                    insuranceCoverId: cur.insuranceCoverId,
                                    insuranceProductId: cur.insuranceProductId,
                                    unitPrice: productConfig.price,
                                    ...(ctx.purchaseState?.destination && {
                                        destination: {
                                            destinations: ctx.purchaseState.destination.destinations,
                                            startingRegion: {
                                                region: ctx.purchaseState.destination.startingRegion,
                                                timeZone: ctx.purchaseState.destination.timezone,
                                            },
                                        },
                                    }),
                                });
                                return acc;
                            },
                            Object.assign({}, ...baseObject),
                        ) ?? [];

                    return Object.entries(parseCoversFromCart)
                        .filter(([, covers]) => !!covers.length)
                        .map(([coverCode, covers]) => {
                            const productConfig = allProducts.find((x) => x.coverCode === coverCode);
                            const productOptionConfig = ctx.productGroups
                                ?.find((y) => y.options.find((x) => x.coverCodes.includes(coverCode)))
                                ?.options.find((y) => y.coverCodes.includes(coverCode));
                            const subTotalByCoverCode = covers.reduce((acc, cur) => acc + cur.unitPrice!, 0);
                            return {
                                selectedCoverCode: coverCode,
                                name: productConfig?.selectedCoverInformation.name ?? '',
                                checkoutChipText: productConfig?.selectedCoverInformation?.checkoutChipText ?? '',
                                subTotalByCoverCode,
                                unitPrice: productConfig?.price ?? 0,
                                group: productOptionConfig?.id ?? '',
                                coverItems: covers,
                                unitLabel: productConfig?.selectedCoverInformation?.datePickerContent.unitLabel ?? '',
                                hideUnitCount: productConfig?.selectedCoverInformation?.datePickerContent.hideUnitLabel,
                                coverType: productConfig?.coverType ?? '',
                                paymentModelDescription: productOptionConfig?.paymentModelDescription ?? '',
                            };
                        });
                },
            }),
            setCheckoutDetails: assign({
                checkoutDetails: (ctx, event: any) => {
                    return event.data.checkoutDetails.checkoutDetails as CheckoutDetailsResponse;
                },
                covers: (ctx, event: any) => {
                    const newCovers = (event.data.checkoutDetails.covers as CoverResponse[]).map((cover) => {
                        const correspondingProductOnSale = ctx.products.find(
                            (p) => p.productSpec.mainCoverType.coverCode === cover.coverCode,
                        );
                        const pdsVersion = `${correspondingProductOnSale?.pdsVersionMajor}.${correspondingProductOnSale?.pdsVersionMinor}`;
                        const selectedCoverInformation = ctx.coverInformation![cover.coverCode!][pdsVersion];
                        return {
                            ...cover,
                            coverName: selectedCoverInformation.name || cover.coverCode,
                        };
                    });
                    return newCovers as CheckoutCoverResponse[];
                },
            }),
            setPaymentMethods: assign({
                paymentMethods: (ctx, event: any) => {
                    return event.data.paymentMethods as UserPaymentMethod[];
                },
            }),
            setIncomingCheckoutDetails: assign({
                incomingCheckoutDetails: (ctx, event) => {
                    return event.data as CheckoutDetailsResponse;
                },
            }),
            updateCheckoutDetails: assign({
                checkoutDetails: (ctx) => {
                    return ctx.incomingCheckoutDetails;
                },
            }),
            setDiscountCode: assign({
                discountCode: (ctx, event) => {
                    return event.data.toUpperCase();
                },
            }),
            setSelectedPaymentMethod: assign({
                selectedPaymentMethod: (ctx, event) => event.data.selectedPaymentMethod || 'card',
            }),
            // below eslint-disable due to this documented bug https://xstate.js.org/docs/guides/typescript.html#troubleshooting
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            clearDiscountCode: assign({ discountCode: (ctx) => '' }),
            setDiscountErrorInfo: assign({
                discountErrorInfo: (ctx, event: any) => {
                    const errorInfo = event.data.response.data.message as string;
                    return errorInfo.charAt(0).toUpperCase() + errorInfo.slice(1);
                },
            }),
            // below eslint-disable due to this documented bug https://xstate.js.org/docs/guides/typescript.html#troubleshooting
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            clearDiscountErrorInfo: assign({ discountErrorInfo: (ctx) => '' }),
            setCheckoutErrorInfo: assign({
                checkoutErrorInfo: (ctx, event: any) => {
                    const failedReason: string = event?.data?.response?.data?.message;
                    if (failedReason) {
                        const parsedError = failedReason.match(/(.*?): (.*)/);
                        if (parsedError) {
                            // Is stripe error
                            const [, stripeErrorCode, stripeErrorMessage] = parsedError;
                            if (stripeErrorCode === 'invoice_payment_intent_requires_action') {
                                return payments.errorNotSupportedCard;
                            }
                            return stripeErrorMessage;
                        }
                    }
                    return common.errorSomethingWentWrongTryAgain;
                },
            }),
            spawnPaymentMethodMachine: assign({
                paymentMethodMachine: (ctx) => {
                    const selectedProductGroup = ctx.productGroups?.find(
                        (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
                    );
                    const coverCodes = selectedProductGroup?.options.find((x) =>
                        x.coverCodes.includes(ctx.purchaseState.selectedProductOption?.representedByCoverCode ?? ''),
                    )?.coverCodes;

                    return spawn(
                        paymentElementMachine.withContext({
                            ...paymentElementMachine.context,
                            defaultCard: ctx.paymentMethods.find((pm) => pm.isDefault && pm.type === 'card'),
                            accessToken: ctx.accessToken,
                            hasSubscriptionInCheckout:
                                coverCodes?.some(
                                    (c) =>
                                        ctx.products.find((x) => x.productSpec.mainCoverType.coverCode === c)
                                            ?.productSpec.mainCoverTypeId === CoverTypeId.SUBSCRIPTION_V1,
                                ) ?? false,
                            paymentId: ctx.checkoutDetails.paymentId,
                        }),
                    );
                },
            }),
            sendCheckoutSuccessEvent: send('CHECKOUT_SUCCESS', { to: (ctx) => ctx.paymentMethodMachine }),
            sendCheckoutErrorEvent: send('CHECKOUT_ERROR', { to: (ctx) => ctx.paymentMethodMachine }),
            fetchNewCreditBalance: (ctx) => ctx.fetchCreditBalance(),

            // *******************
            // Analytics
            // *******************
            trackCouponEntered: (ctx) => Analytics.trackCouponEntered(ctx.checkoutDetails.paymentId, ctx.discountCode),
            trackCouponApplied: (ctx, event) =>
                Analytics.trackCouponApplied(
                    ctx.checkoutDetails.paymentId,
                    (event.data as CheckoutDetailsResponse).invoice.couponName,
                    (event.data as CheckoutDetailsResponse).invoice.couponAmountOff,
                ),
            trackCouponDenied: (ctx, event: any) =>
                Analytics.trackCouponDenied(
                    ctx.checkoutDetails.paymentId,
                    ctx.discountCode,
                    event.data.response.data.message as string,
                ),
            trackCheckoutCompleted: (ctx) => {
                const selectedProductGroup = ctx.productGroups?.find(
                    (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
                );
                const selectedCoverCodes = selectedProductGroup?.options.find((x) =>
                    x.coverCodes.includes(ctx.purchaseState.selectedProductOption?.representedByCoverCode ?? ''),
                )?.coverCodes;
                Analytics.trackOrderCompleted({
                    order_id: ctx.checkoutDetails.paymentId,
                    total: ctx.checkoutDetails.invoice.amountDue,
                    discount:
                        ctx.checkoutDetails.invoice.couponAmountOff > 0
                            ? ctx.checkoutDetails.invoice.couponAmountOff
                            : undefined,
                    coupon: ctx.checkoutDetails.coupon?.promoCode,
                    products:
                        selectedCoverCodes?.map((c) => {
                            const correspondingProductOnSale = ctx.products.find(
                                (x) => x.productSpec.mainCoverType.coverCode === c,
                            );
                            const requiredInsuredPersonRelationship =
                                correspondingProductOnSale?.productSpec.mainCoverType.requiredInsuredPersonRelationship;
                            const pdsVersion = `${correspondingProductOnSale?.pdsVersionMajor}.${correspondingProductOnSale?.pdsVersionMinor}`;
                            const selectedCoverInformation = ctx.coverInformation![c][pdsVersion];
                            return {
                                coverCode: c,
                                purchaseFor: requiredInsuredPersonRelationship ?? 'myself',
                                quantity: selectedCoverCodes.filter((x) => x === c).length,
                                variant: ctx.purchaseState.coverStartDates.map((startDate) =>
                                    formatDateToString(startDate, DateFormat.ANALYTICS),
                                ),
                                consumerFacingProductName: selectedCoverInformation.analytics.consumerFacingProductName,
                                duration: selectedCoverInformation.analytics.duration,
                            };
                        }) ?? [],
                });
            },
        },
    },
);

export default checkoutMachine;
