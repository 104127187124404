import LocalizedStrings from 'react-localization';

const payments = new LocalizedStrings({
    en: {
        title: 'Purchase now',
        amountPerWeek: '{amount} per week',
        cardDetails: 'Card details',
        subtitleCover: 'Your cover',
        subtitlePayment: 'Payment information',
        subscriptionBtn: 'Subscribe ',
        payButtonLabel: 'Pay {totalDue}',
        discountCode: 'Discount code',
        discountCodeInvalid: 'This code is invalid',
        discountCodeAdd: 'Add',
        discountCodeAdded: '<strong>Discount:</strong> {code}',
        accountCredit: 'Account credit',
        creditApplied: 'Credit applied',
        creditBalance: 'Credit balance',
        subscription: 'Subscription',
        boost: 'Boost',
        total: 'Total:',
        cardName: 'Name on Card',
        cardNumber: 'Card number',
        expiryDate: 'Expiry Date',
        cvv: 'CVV',
        payWith: 'Pay with',
        cardChange: 'Change',
        cardChangeCancel: 'Cancel',
        disclaimer:
            'Flip uses Stripe as its payment provider. By entering your payment details you authorise and consent to Stripe collecting and using this information on behalf of Flip in accordance with {stripeTerms}',
        stripeTermsLabel: "Stripe's Terms and Privacy Policy",
        poweredBy: 'Powered by',
        terms: 'Terms',
        privacy: 'Privacy',
        saveThisCard: 'Save this card for future payments',
        errorNotSupportedCard:
            "Sorry we don't support your cards verification requirements at this time. Please try another card.",
        noChargeAlert:
            "<strong>Don't worry you won't be charged for this transaction.</strong> However, we need to capture your credit card details for payments, once your credit is exhausted.",
    },
});

export default payments;
