import { assign } from 'xstate';
import { Destination } from '../../../../business-logic/models/CoverConfig';
import { SelectStartingDestinationMachineContextTypes } from '../context/selectStartingDestinationMachineContext';
import Analytics from '../../../../analytics/Analytics';
import { Typegen0 } from '../selectStartingDestinationMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setStartingDestination = assign<
    SelectStartingDestinationMachineContextTypes,
    { type: EventsCausingActions['setStartingDestination']; data: string | null }
>({
    startDestination: (ctx, event) => {
        return event.data;
    },
}) as any;

export const setAllDestinations = assign<
    SelectStartingDestinationMachineContextTypes,
    {
        type: EventsCausingActions['setAllDestinations'];
        data: {
            availableDestinations: Destination[];
        };
    }
>({
    availableDestinations: (ctx, event) => event.data.availableDestinations,
}) as any;
