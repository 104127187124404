import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';
import AlertLargeIcon from '../../assets/images/alert-alert-large.svg';
import AlertIcon from '../../assets/images/alert-alert.svg';
import AlertErrorLargeIcon from '../../assets/images/alert-error-large.svg';
import AlertErrorIcon from '../../assets/images/alert-error.svg';
import AlertInfoLargeIcon from '../../assets/images/alert-info-large.svg';
import AlertInfoIcon from '../../assets/images/alert-info.svg';
import AlertSuccessLargeIcon from '../../assets/images/alert-success-large.svg';
import AlertSuccessIcon from '../../assets/images/alert-success.svg';
import AlertSystemLargeIcon from '../../assets/images/alert-system-large.svg';
import AlertSystemIcon from '../../assets/images/alert-system.svg';
import Button, { ButtonProps } from '../button/Button';
import Card from '../card/Card';
import ErrorMessages from './error-messages/ErrorMessages';

import './Alert.scss';

export enum AlertTypes {
    ALERT = 'alert',
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success',
    SYSTEM = 'system',
}

export enum AlertSizes {
    SMALL = 'small',
    LARGE = 'large',
}
export interface AlertProps {
    size?: AlertSizes;
    type?: AlertTypes;
    message?: string | ReactNode;
    className?: string;
    // CTA only applies to large Alerts
    cta?: ButtonProps;
    hideIcon?: boolean;
}

const Alert: React.FC<AlertProps> = ({
    size = AlertSizes.SMALL,
    type = AlertTypes.ALERT,
    message,
    className,
    cta,
    hideIcon = false,
}) => {
    const cardIcon = useMemo(() => {
        if (size === AlertSizes.LARGE) {
            switch (type) {
                case AlertTypes.INFO:
                    return AlertInfoLargeIcon;
                case AlertTypes.ERROR:
                    return AlertErrorLargeIcon;
                case AlertTypes.SUCCESS:
                    return AlertSuccessLargeIcon;
                case AlertTypes.SYSTEM:
                    return AlertSystemLargeIcon;
                case AlertTypes.ALERT:
                default:
                    return AlertLargeIcon;
            }
        }
        // Small size icons
        switch (type) {
            case AlertTypes.INFO:
                return AlertInfoIcon;
            case AlertTypes.ERROR:
                return AlertErrorIcon;
            case AlertTypes.SUCCESS:
                return AlertSuccessIcon;
            case AlertTypes.SYSTEM:
                return AlertSystemIcon;
            case AlertTypes.ALERT:
            default:
                return AlertIcon;
        }
    }, [type, size]);

    const content = useMemo(() => message || ErrorMessages.default, [message]);

    const renderSmallAlert = () => (
        <div className={classNames('alert', `alert--${type}`, className)}>
            {!hideIcon && <img src={cardIcon} alt={`icon-${type}`} role="presentation" className="alert__icon" />}
            <span className="alert__content--small">{content}</span>
        </div>
    );

    const renderLargeAlert = () => (
        <Card
            className={classNames('alert--large', `alert--${type}`, className)}
            icon={!hideIcon ? cardIcon : undefined}
        >
            <div className={classNames('alert--large__content', { hideIcon })}>{content}</div>
            {cta && (
                <Button
                    width="full"
                    variant="primary"
                    {...cta}
                    className={classNames('alert--large__cta', cta.className)}
                />
            )}
        </Card>
    );

    return size === AlertSizes.LARGE ? renderLargeAlert() : renderSmallAlert();
};

export default Alert;
