import { Alias } from '../../../../../business-logic/models/Alias';
import { RadioCheckboxProps } from '../../../../../components/form/RadioCheckboxProps';

const toAdultCheckboxProps = (adult: Alias): Omit<RadioCheckboxProps, 'checked' | 'onChange'> => {
    return {
        id: adult.personId,
        name: `${adult.firstName} ${adult.lastName}`,
        label: `${adult.firstName} ${adult.lastName}`,
        // We don't check other adults eligibility since we do not know their age.
        // Change this here if we get this back from the alias service.
    };
};

export default toAdultCheckboxProps;
