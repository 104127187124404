import LocalizedStrings from 'react-localization';
import ExternalLinks from '../../../../utils/constants/ExternalLinks';
import CartStep from '../../../../screens/cart/constants/CartStep';

const cartContent = new LocalizedStrings({
    en: {
        header: 'Checkout',
        steps: {
            [CartStep.PROFILE]: 'Your details',
            [CartStep.ACTIVITIES]: 'Activities',
            [CartStep.WHOS_COVERED]: 'Who’s covered',
            [CartStep.PAYMENT]: 'Payment',
        },
        referral: {
            referralVerificationSuccess: 'Complete sign up to get 3 weeks free',
            referralVerificationError: 'Sorry your referral link is invalid',
        },
        profileStep: {
            title: 'Your details',
            description: 'to manage your insurance later',
            kidsInfo:
                'You will be the account holder on behalf of the Kids’ policies. You must be over 18 and a parent or legal guardian of the children you’re buying cover for.',
            alreadyHaveAccount: 'Already have an account? {signInHere}',
            signInHere: 'Sign in here',
            email: {
                label: 'Email Address',
            },
            firstName: {
                label: 'First name',
            },
            lastName: {
                label: 'Last name',
            },
            gender: {
                label: 'Select your gender',
            },
            state: {
                label: 'State you live in',
            },
            residencyStatus: {
                label: 'Residency status',
            },
            dropdownPlaceholder: 'Please select',
            dobError: {
                errorInvalidDate: 'Sorry, the date doesn’t look right',
                errorMustBeOver18: 'Sorry, you need to be 18 or over to purchase Flip insurance',
                errorMustBeUnder65: 'Sorry, you need to be 64 or under to purchase Flip insurance',
            },
            emailError: "Sorry, that email doesn't look right",
            agreeTerms: `By continuing you agree to the <a href="${ExternalLinks.termsAndConditions}" target="_blank">Flip Terms of Use</a> and <a href="${ExternalLinks.privacyPolicy}" target="_blank">Privacy Policy</a>.`,
        },
        activitiesStep: {
            title: 'Activities',
            description: 'What activities are you planning to do',
        },
        whosCoveredStep: {
            title: 'Who is covered?',
            description: 'Select who this cover is for',
            disclosureSummary: 'Kid {index}',
        },
        paymentStep: {
            title: 'Payment details',
            subtotal: 'Subtotal',
            total: 'Total: ',
        },
    },
});

export default cartContent;
