import classnames from 'classnames';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BandaidIcon from '../../../assets/images/icon-bandaid.svg';
import ShieldIcon from '../../../assets/images/icon-shield.svg';
import homeIcon from '../../../assets/images/nav/home.svg';
import ProfileIcon from '../../../assets/images/nav/profile.svg';
import BrandPurchaseButton from '../../../components/brand-purchase-button/BrandPurchaseButton';
import DashboardButton, { DashboardButtonStyle } from '../../../components/dashboard-button/DashboardButton';
import MobileAppDownloadModal from '../../../components/mobile-download-modal/MobileAppDownloadModal';
import claimsDeprecationContent from '../../../content/ui/components/claims-deprecation-modal/claimsDeprecationModal';
import HomeContent from '../../../content/ui/screens/home/home';
import Routes from '../../../utils/Routes';

import './SideNavigation.scss';

const SideNavigation = ({ className }: { className: string }) => {
    const history = useHistory();
    const location = useLocation();
    const isManageCover = location.pathname === Routes.MANAGE_COVER;
    const isDashboard = location.pathname === Routes.HOME;
    const [openClaimsDeprecation, setOpenClaimsDeprecation] = useState(false);
    return (
        <div className={classnames('side-navigation', className)}>
            <h4 className="side-navigation__heading">{HomeContent.purchaseHeading}</h4>
            <BrandPurchaseButton />
            <h4 className="side-navigation__heading">{HomeContent.linksHeading}</h4>
            {isDashboard && (
                <DashboardButton
                    label={HomeContent.coverSubLinkTitle}
                    style={DashboardButtonStyle.LITE}
                    icon={ShieldIcon}
                    onClick={() => {
                        history.push(Routes.MANAGE_COVER);
                    }}
                />
            )}
            {isManageCover && (
                <DashboardButton
                    label={HomeContent.dashboardSubLinkTitle}
                    style={DashboardButtonStyle.LITE}
                    icon={homeIcon}
                    onClick={() => {
                        history.push(Routes.HOME);
                    }}
                />
            )}
            <DashboardButton
                label={HomeContent.claimsSubLinkTitle}
                style={DashboardButtonStyle.LITE}
                icon={BandaidIcon}
                onClick={() => {
                    setOpenClaimsDeprecation(true);
                }}
            />
            <DashboardButton
                label={HomeContent.accountSubLinkTitle}
                style={DashboardButtonStyle.LITE}
                icon={ProfileIcon}
                onClick={() => {
                    history.push(Routes.ACCOUNT);
                }}
            />
            <MobileAppDownloadModal
                isOpen={openClaimsDeprecation}
                onClose={() => setOpenClaimsDeprecation(false)}
                header={claimsDeprecationContent.header}
                description={claimsDeprecationContent.description}
            />
        </div>
    );
};

export default SideNavigation;
