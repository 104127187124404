import { CheckoutDetailsResponse } from '../../../../business-logic/models/CheckoutDetails';
import paymentStrings from '../../../../strings/payments';
import formatPrice from '../../../../utils/formatPrice';
import parseString from '../../../../utils/parseString';
import PriceLineItem from '../price-line-item/PriceLineItem';

export default (checkoutDetails: CheckoutDetailsResponse) => {
    return (
        <PriceLineItem
            description={parseString(
                paymentStrings
                    .formatString(paymentStrings.discountCodeAdded, {
                        code: checkoutDetails.coupon?.promoCode ?? '',
                    })
                    .toString(),
            )}
            // description={checkoutDetails.coupon?.promoCode}
            amount={`-${formatPrice(checkoutDetails.invoice.couponAmountOff)}`}
            withTopSpacing
        />
    );
};
