import classNames from 'classnames';
import { FC, FormEvent, useState } from 'react';
import blueClose from '../../assets/images/close-blue.svg';
import close from '../../assets/images/close.svg';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { PolicyItem } from '../../business-logic/models/PoliciesResponse';
import cancelCoverDrawer from '../../content/ui/components/cancel-cover-drawer/cancelCoverDrawer';
import withContent from '../../hoc/with-content/withContent';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import IconButton from '../icon-button/IconButton';
import Modal from '../modal/Modal';
import CancelCoverFeedbackForm from './CancelCoverFeedbackForm';
import CancelCoverList from './CancelCoverList';

import './CancelCoverDrawer.scss';

const contentMap = {
    cancelYourCover: 'ui.cancelYourCover',
    starting: 'ui.starting',
    destination: 'ui.destination',
    renews: 'ui.renews',
    cancelSubscriptionInfo: 'ui.cancelSubscriptionInfo',
    cancelInfo: 'ui.cancelInfo',
    cancelCoverCTA: 'ui.cancelCoverCTA',
};

interface CancelCoverDrawerProps {
    content: Record<keyof typeof contentMap, string>;
    policy: PolicyItem | null;
    isOpen: boolean;
    onClose: () => void;
    refreshCovers?: () => void;
}

export const CANCELLATION_STEP = {
    COVER_SELECTION: 1,
    FEEDBACK: 2,
} as const;

const CancelCoverDrawer: FC<CancelCoverDrawerProps> = ({ content, policy, isOpen, onClose, refreshCovers }) => {
    const { cancelSingleOpenCover, cancelSubscriptionOpenCover } = useOpenCovers();
    const [cancelReasons, setCancelReasons] = useState<string[]>([]);
    const [freeText, setFreeText] = useState<string>('');
    const [cancellationError, setCancellationError] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [slideIndex, setSlideIndex] = useState<(typeof CANCELLATION_STEP)[keyof typeof CANCELLATION_STEP]>(
        CANCELLATION_STEP.COVER_SELECTION,
    );

    // Confirm to proceed to cancellation feedback (Slide 1 to Slide 2)
    const onSelectCoverToCancel = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSlideIndex(CANCELLATION_STEP.FEEDBACK);
    };

    // Commit cancelling with insuranceCoverId and any feedback (Slide 2 to close modal)
    const commitCancel = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsCancelling(true);
        setCancellationError(false);
        const allReasons = [...cancelReasons, freeText].filter((item) => !!item);
        try {
            if (!!policy && policy.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1) {
                await cancelSubscriptionOpenCover(policy.mainCover.insuranceCoverId, allReasons);
            }

            if (!!policy && policy.mainCover.coverTypeId === CoverTypeId.SINGLE_V1) {
                await cancelSingleOpenCover(policy.mainCover.insuranceCoverId, allReasons);
            }
            setIsCancelling(false);
            if (refreshCovers) {
                refreshCovers();
            }
            onClose();
        } catch (error) {
            setCancellationError(true);
        } finally {
            setCancelReasons([]); // reset
            setFreeText('');
            setIsCancelling(false);
        }
    };

    const handleCloseModal = () => {
        setCancelReasons([]);
        setSlideIndex(CANCELLATION_STEP.COVER_SELECTION);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            parentClass="#root"
            onClose={handleCloseModal}
            variant="slide-in"
            className="cancel-cover-drawer"
        >
            <div className="cancel-cover-drawer__modal-close-button-wrapper">
                <IconButton onClick={handleCloseModal} icon={close} hoverIcon={blueClose} actionLabel="Close" />
            </div>
            <div
                className={classNames('cancel-cover-drawer__wrapper', {
                    'cancel-cover-drawer__wrapper--active': slideIndex === CANCELLATION_STEP.FEEDBACK,
                })}
            >
                <div className="cancel-cover-drawer__slide cancel-cover-drawer__cover-selection">
                    <CancelCoverList
                        onSelectCoverToCancel={onSelectCoverToCancel}
                        policy={policy}
                        handleCloseModal={handleCloseModal}
                    />
                </div>
                <div className="cancel-cover-drawer__slide cancel-cover-drawer__feedback">
                    <CancelCoverFeedbackForm
                        commitCancel={commitCancel}
                        cancelReasons={cancelReasons}
                        policy={policy}
                        isCancelling={isCancelling}
                        setFreeText={setFreeText}
                        updateCancelReasons={(checked: boolean, reason: string) => {
                            if (checked) {
                                setCancelReasons((prev) => [...prev, reason]);
                            } else {
                                setCancelReasons((prev: string[]) => prev.filter((i) => i !== reason));
                            }
                        }}
                        cancellationError={cancellationError}
                        freeText={freeText}
                        resetCancelReasons={() => {
                            setCancelReasons([]);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default withContent(CancelCoverDrawer, contentMap, cancelCoverDrawer);
