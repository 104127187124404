import { CartMachineContextTypes, InsuredPersonType } from '../context/cartMachineContext';

export const isPurchaseStatePresent = (ctx: CartMachineContextTypes): boolean => {
    return !!ctx.purchaseState?.selectedProductGrouping;
};

export const isKidsDetailsRequired = (ctx: CartMachineContextTypes): boolean => {
    // Get cover codes in selected product option
    const selectedProductGroup = ctx.productGroups?.find(
        (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
    );
    const coverCodes = selectedProductGroup?.options.find(
        (option) => option.id === ctx.purchaseState?.selectedProductOption?.id,
    )?.coverCodes;
    // For each cover code in selected product option
    // Check if any of the cover code is a dependant cover
    return (
        coverCodes?.find((coverCode) => {
            // Get product for specified cover code
            const coverCodeProduct = ctx.products.find(
                (product) => product.productSpec.mainCoverType.coverCode === coverCode,
            );
            // Check if requiredInsuredPersonRelationship is 'Dependant'
            return (
                coverCodeProduct?.productSpec.mainCoverType.requiredInsuredPersonRelationship?.toLowerCase() ===
                InsuredPersonType.DEPENDANT
            );
        }) !== undefined
    );
};

const getSelectedCoverCodesInProductOption = (ctx: CartMachineContextTypes): string[] | undefined => {
    // Get cover codes in selected product option
    const selectedProductGroup = ctx.productGroups?.find(
        (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
    );
    const selectedCoverCodes = selectedProductGroup?.options.find(
        (option) => option.id === ctx.purchaseState?.selectedProductOption?.id,
    )?.coverCodes;

    return selectedCoverCodes;
};

const doesSelectedProductOptionHaveCoverCodesForAdults = (ctx: CartMachineContextTypes): boolean => {
    const selectedCoverCodes = getSelectedCoverCodesInProductOption(ctx);
    // This check is for account owner and also other adults
    // No distinct differentiation is being made for products that is
    // only for the account owner vs for the account owner and other adults
    // at this point in time
    return (
        selectedCoverCodes?.some(
            (coverCode) =>
                ctx.products
                    .find((x) => x.productSpec.mainCoverType.coverCode === coverCode)
                    ?.productSpec.mainCoverType.requiredInsuredPersonRelationship?.toLowerCase() !==
                InsuredPersonType.DEPENDANT,
        ) ?? false
    );
};

const doesSelectedProductOptionHaveCoverCodesForDependents = (ctx: CartMachineContextTypes): boolean => {
    const selectedCoverCodes = getSelectedCoverCodesInProductOption(ctx);
    return (
        selectedCoverCodes?.some(
            (coverCode) =>
                ctx.products
                    .find((x) => x.productSpec.mainCoverType.coverCode === coverCode)
                    ?.productSpec.mainCoverType.requiredInsuredPersonRelationship?.toLowerCase() ===
                InsuredPersonType.DEPENDANT,
        ) ?? false
    );
};

export const canAccountHolderBeInsured = (ctx: CartMachineContextTypes): boolean => {
    return doesSelectedProductOptionHaveCoverCodesForAdults(ctx);
};

export const canAdultsBeInsured = (ctx: CartMachineContextTypes): boolean => {
    return doesSelectedProductOptionHaveCoverCodesForAdults(ctx) && ctx.withMultiAdultPurchase;
};

export const canDependantsBeInsured = (ctx: CartMachineContextTypes): boolean => {
    return doesSelectedProductOptionHaveCoverCodesForDependents(ctx);
};

export const isSelectPersonsInsuredRequired = (ctx: CartMachineContextTypes): boolean => {
    return (
        ctx.availableInsuredPersonsTypes.length > 1 ||
        ctx.availableInsuredPersonsTypes.includes(InsuredPersonType.DEPENDANT)
    );
};

export const isExistingUser = (ctx: CartMachineContextTypes): boolean => {
    return ctx.isExistingUser;
};
