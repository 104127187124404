import add from 'date-fns/add';
import parse from 'date-fns/parse';
import { FC, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import lightningIcon from '../../assets/images/icon-lightning-yellow.svg';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import NewDatePicker from '../../components/new-date-picker/NewDatePicker';
import { NewDatePickerModes } from '../../components/new-date-picker/types';
import Sticky from '../../components/sticky/Sticky';
import selectDatesContent from '../../content/ui/screens/select-dates/selectDates';
import withContent from '../../hoc/with-content/withContent';
import useCoverInformation from '../../hooks/useCoverInformation';
import useProductSpec from '../../hooks/useProductSpec';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import DateFormat from '../../utils/constants/DateFormat';
import Limits from '../../utils/constants/Limits';
import formatDateToString from '../../utils/formatDateToString';
import getLastDayToSchedule from '../../utils/getLastDayToSchedule';
import usePathParams from '../../hooks/usePathParams';
import { PRODUCT_SELECTION_EVENT } from '../../analytics/AnalyticsConstants';

import './SelectDates.scss';

const contentMap = {
    heading: 'ui.heading',
    cta: 'ui.cta',
};

interface SelectDatesProps {
    content: Record<keyof typeof contentMap, string>;
}
const SelectDatesWithPurchaseOptionValidated: FC<SelectDatesProps> = ({ content }) => {
    const location = useLocation<PurchaseState>();
    const history = useHistory();
    const { selectedProductGroup, setSelectedProductGroupParam } = usePathParams();
    const { selectedProductOption, coverStartDates } = location.state;
    const { representedByCoverCode } = selectedProductOption!;

    const coverInformation = useCoverInformation(representedByCoverCode);
    const productSpec = useProductSpec(representedByCoverCode);
    const [rangeToSchedule, setSelectedRangeToSchedule] = useState<Date[]>(
        coverStartDates.map((date) => parse(date, 'dd-MM-yyyy', new Date())),
    );
    const { products } = useProduct();
    const coverType = products.find(
        (x) => x.productSpec.mainCoverType.coverCode === location.state.selectedProductOption?.representedByCoverCode,
    )?.productSpec.mainCoverTypeId;

    const datePickerMode = (): NewDatePickerModes => {
        if (coverType === CoverTypeId.SUBSCRIPTION_V1) {
            return 'single';
        }
        return 'range';
    };

    const onValueChange = (date: Date[]) => {
        setSelectedRangeToSchedule(date);
    };

    const handleContinue = () => {
        Analytics.trackProductSelectionStepCompleted(PRODUCT_SELECTION_EVENT.SELECT_DATES);
        history.push({
            pathname: setSelectedProductGroupParam(Routes.COVER_DETAILS, selectedProductGroup!),
            state: {
                selectedProductGrouping: location.state?.selectedProductGrouping,
                selectedProductOption: location.state?.selectedProductOption,
                coverStartDates: rangeToSchedule.map((date) => formatDateToString(date, DateFormat.DEFAULT)),
                destination: {
                    destinations: location.state.destination?.destinations,
                    startingDestination: location.state.destination?.startingDestination,
                    startingRegion: location.state?.destination?.startingRegion,
                    timezone: location.state?.destination?.timezone,
                },
            },
        });
    };

    // date picker constraints (can be from up to 12 months from current date)
    const todayDate = new Date();
    const aYearFromToday = add(todayDate, {
        hours: productSpec?.schedule?.scheduleLimitInHours ?? Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS,
    });

    useEffect(() => {
        Analytics.trackProductSelectionStepViewed(PRODUCT_SELECTION_EVENT.SELECT_DATES);
    }, []);

    return (
        <Layout title={content.heading} showBackButton className="select-dates__layout">
            {coverInformation?.datePickerContent.datePickerInstructions.length && (
                <ul className="select-dates-instructions">
                    {coverInformation?.datePickerContent.datePickerInstructions.map((item) => (
                        <li key={item} className="select-dates-instructions__item">
                            <img src={lightningIcon} alt="" className="select-dates-instructions__item__icon" />
                            <span>{item}</span>
                        </li>
                    ))}
                </ul>
            )}
            {(productSpec?.activePeriodInHours != null || datePickerMode()) && (
                <NewDatePicker
                    mode={datePickerMode()}
                    value={rangeToSchedule}
                    onChange={onValueChange}
                    fromMonth={todayDate}
                    toMonth={aYearFromToday}
                    rangeInDays={!productSpec?.activePeriodInHours ? undefined : productSpec?.activePeriodInHours / 24}
                    unitLabel={coverInformation?.datePickerContent.unitLabel}
                    hideUnitLabel={coverInformation?.datePickerContent.hideUnitLabel}
                    hideButton
                    disabledDays={[
                        {
                            before: new Date(),
                            after: getLastDayToSchedule(
                                new Date(),
                                productSpec?.schedule?.scheduleLimitInHours ?? Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS,
                            ),
                        },
                    ]}
                />
            )}
            <Sticky>
                <Button
                    label={content.cta}
                    onClick={handleContinue}
                    width="full"
                    disabled={rangeToSchedule.length === 0}
                />
            </Sticky>
        </Layout>
    );
};

const SelectDates: FC<SelectDatesProps> = (props) => {
    const location = useLocation<PurchaseState>();

    if (location.state === undefined) {
        return <Redirect to={Routes.SELECT_COVER} />;
    }

    const { selectedProductOption, selectedProductGrouping } = location.state;

    if (selectedProductOption === null || selectedProductGrouping === null) {
        return <Redirect to={Routes.SELECT_COVER} />;
    }

    return <SelectDatesWithPurchaseOptionValidated {...props} />;
};

export default withContent(SelectDates, contentMap, selectDatesContent);
