import cancelCoverFeedbackDrawer from '../../content/ui/components/cancel-cover-feedback-drawer/cancelCoverFeedbackDrawer';
import Button from '../button/Button';
import Fieldset from '../form/fieldset/Fieldset';
import LoadingButton from '../loading-button/LoadingButton';
import {
    CancelSingleCoverFeedbackOptions,
    CancelSubscriptionCoverFeedbackOptions,
} from '../../utils/constants/CancelCoverFeedbackOptions';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import { ChangeEvent, ChangeEventHandler, FormEvent } from 'react';
import Checkbox from '../form/checkbox/Checkbox';
import parseString from '../../utils/parseString';
import TextField from '../text-field/TextField';
import Alert, { AlertTypes } from '../alert/Alert';
import common from '../../strings/common';
import { PolicyItem } from '../../business-logic/models/PoliciesResponse';

interface CancelCoverFeedbackFormProps {
    commitCancel: (e: FormEvent<HTMLFormElement>) => void;
    cancelReasons: string[];
    policy: PolicyItem | null;
    isCancelling: boolean;
    setFreeText: (textValue: string) => void;
    updateCancelReasons: (checked: boolean, reason: string) => void;
    cancellationError: boolean;
    freeText: string;
    resetCancelReasons: () => void;
}
const CancelCoverFeedbackForm = ({
    commitCancel,
    cancelReasons,
    policy,
    isCancelling,
    setFreeText,
    updateCancelReasons,
    cancellationError,
    freeText,
    resetCancelReasons,
}: CancelCoverFeedbackFormProps) => {
    const options = () => {
        const allReasons = [];
        if (!!policy && policy.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1) {
            allReasons.push(CancelSubscriptionCoverFeedbackOptions);
        }
        if (!!policy && policy.mainCover.coverTypeId === CoverTypeId.SINGLE_V1) {
            allReasons.push(CancelSingleCoverFeedbackOptions);
        }
        // Amalgates all possible reasons for cancellation,
        // remove duplication and sort by display order
        return allReasons
            .flat()
            .filter((v, i, a) => a.findIndex((v2) => v2.key === v.key) === i)
            .sort((a, b) => a.index - b.index);
    };

    const renderCheckbox = (label: string, onChange: ChangeEventHandler<HTMLInputElement>, checked: boolean) => {
        return (
            <div key={label} className="cancel-cover-feedback-drawer__checkbox">
                <Checkbox
                    id={label}
                    name="cancellationReason"
                    label={
                        <div className="cancel-cover-feedback-drawer__checkbox-label">
                            <span>{parseString(label)}</span>
                        </div>
                    }
                    onChange={onChange}
                    checked={checked}
                    disabled={isCancelling}
                />
                {label === 'Other' && checked && (
                    <TextField
                        name={label}
                        label={cancelCoverFeedbackDrawer.feedbackFreeTextLabel}
                        onChange={(e) => setFreeText(e.target.value)}
                        minLength={1}
                    />
                )}
            </div>
        );
    };

    const renderFeedbackCheckbox = (key: string, label: string) => {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            updateCancelReasons(e.target.checked, key);
        };

        const checked = cancelReasons.some((i) => i === key);
        return renderCheckbox(label, onChange, checked);
    };

    return (
        <div>
            <form onSubmit={commitCancel} className="cancel-cover-feedback-drawer__form">
                <h2 className="cancel-cover-drawer__heading">{cancelCoverFeedbackDrawer.feedbackHeading}</h2>
                <p className="cancel-cover-feedback-drawer__description">
                    {cancelCoverFeedbackDrawer.feedbackDescription}
                </p>
                <Fieldset
                    legend={cancelCoverFeedbackDrawer.feedbackSubheading}
                    className="cancel-cover-feedback-drawer__fieldset"
                >
                    {options().map((option) => renderFeedbackCheckbox(option.key, option.label))}
                </Fieldset>

                <LoadingButton
                    status={isCancelling ? 'loading' : 'idle'}
                    label={cancelCoverFeedbackDrawer.feedbackCTA}
                    width="full"
                    type="submit"
                    disabled={isCancelling || !cancelReasons.length || (cancelReasons.includes('OTHER') && !freeText)}
                    className="cancel-cover-drawer__cta"
                />
                <Button
                    variant="link"
                    label={cancelCoverFeedbackDrawer.noFeedbackCTA}
                    width="full"
                    type="submit"
                    disabled={isCancelling}
                    onClick={() => {
                        resetCancelReasons();
                    }}
                    className="cancel-cover-feedback-drawer__cta--feedback"
                />
            </form>
            {cancellationError && (
                <Alert
                    className="cancel-cover-feedback-drawer__error"
                    type={AlertTypes.ERROR}
                    message={common.errorSomethingWentWrongTryAgain}
                />
            )}
        </div>
    );
};

export default CancelCoverFeedbackForm;
