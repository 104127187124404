import classNames from 'classnames';
import React, { ChangeEvent, FocusEvent, KeyboardEvent, forwardRef, useState } from 'react';
import questionIcon from '../../assets/images/icon-question.svg';
import common from '../../strings/common';
import Regex from '../../utils/RegexCollection';
import InputType from '../../utils/constants/InputType';
import ModalWithCTA from '../modal-with-cta/ModalWithCTA';

import './TextField.scss';

export interface TextFieldProps {
    id?: string;
    name: string;
    type?: InputType;
    label?: string | React.ReactNode;
    isError?: boolean;
    error?: string;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    value?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    minLength?: number;
    maxLength?: number;
    inputMode?: 'text' | 'numeric';
    form?: string;
    prefixIcon?: string;
    suffixIcon?: string;
    required?: boolean; // displays red asterix next to label
    showOptional?: boolean; // displays (optional) text next to label
    toggleModalContent?: { title: string; textContent?: string }; // displays "?" icon that toggles a modal
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            id,
            name,
            label,
            placeholder,
            type,
            value,
            isError,
            error,
            disabled,
            onChange,
            onFocus,
            onBlur,
            onKeyDown,
            className,
            minLength,
            maxLength,
            inputMode,
            form,
            prefixIcon,
            suffixIcon,
            required,
            showOptional,
            toggleModalContent,
        },
        ref,
    ) => {
        const [showModal, setShowModal] = useState<boolean>(false);
        const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
            const input = e.target.value;

            if (type !== InputType.NUMBER) {
                onChange(e);
                return;
            }

            const allowedText = Regex.numbers;
            if (input === '' || allowedText.test(input)) {
                onChange(e);
            }
        };
        return (
            <div className={classNames('text-field', className)} id={!!toggleModalContent ? 'root' : undefined}>
                <ModalWithCTA
                    isOpen={showModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                    variant="pop-up"
                    title={toggleModalContent?.title}
                    textContent={toggleModalContent?.textContent}
                    className="text-field__modal"
                    primaryCTA={{
                        label: common.ok,
                        onClick: () => {
                            setShowModal(false);
                        },
                    }}
                />

                {label && (
                    <label className={classNames('text-field__label', disabled && 'disabled')} htmlFor={id}>
                        {!!required && <sup className="text-field__label__required">*</sup>}
                        {label}
                        {!!toggleModalContent && (
                            <button
                                type="button"
                                className="button text-field__label__toggle"
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                <img src={questionIcon} />
                            </button>
                        )}
                        {!required && !!showOptional && <span className="text-field__label__optional">(optional)</span>}
                    </label>
                )}
                <div
                    className={classNames(
                        'text-field__input',
                        disabled && 'text-field__input--disabled',
                        isError && 'text-field__input--error',
                        'text-field__input__wrapper',
                    )}
                >
                    {prefixIcon && <img src={prefixIcon} alt="" className="text-field__icon--prefix" />}
                    <input
                        id={id}
                        ref={ref}
                        name={name}
                        // dont use number type, we'll handle not
                        // allowing non-number on chnage instead
                        // to avoid the default number input actions
                        type={type === InputType.NUMBER ? InputType.TEXT : type}
                        inputMode={inputMode}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled}
                        onChange={handleOnChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        minLength={minLength}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        form={form}
                    />
                    {suffixIcon && <img src={suffixIcon} alt="" className="text-field__icon--suffix" />}
                </div>
                {isError && error && <span className="text-field__error">{error}</span>}
            </div>
        );
    },
);

export default TextField;
