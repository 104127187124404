import React from 'react';
import { Layout } from '../../components/layout/Layout';
import { List } from '../../components/list/List';
import { useUser } from '../../business-logic/context-provider/user-context';
import profile from '../../strings/profileFlow';
import common from '../../strings/common';

import './ProfileAccount.scss';

const ProfileAccount: React.FC = () => {
    const { userDetails, loading: isAppLoading } = useUser();

    const accountMenu = [
        {
            id: 'customer-email',
            header: common.email,
            // custom element to avoid any formatting to the email
            customDataElement: <p className="profile-account__list-item-data">{userDetails.email}</p>,
        },
        {
            id: 'customer-password',
            header: common.password,
            data: '•••••••••',
        },
        {
            id: 'customer-number',
            header: 'Customer Number',
            data: <p className="profile-account__list-item-data">{userDetails.customerNumber?.toString()}</p>,
        },
    ];

    return (
        <Layout className="account" title={profile.yourAccount} bgPattern={false} showBackButton>
            {!isAppLoading && <List items={accountMenu} />}
        </Layout>
    );
};

export default ProfileAccount;
