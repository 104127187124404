import { FC } from 'react';

import classNames from 'classnames';
import blueClose from '../../assets/images/close-blue.svg';
import close from '../../assets/images/close.svg';
import { CoverInfoV3 } from '../../content/coverTermsTypes';
import useCoverInformation from '../../hooks/useCoverInformation';
import useCoverTerms from '../../hooks/useCoverTerms';
import common from '../../strings/common';
import CoverCode from '../../utils/constants/CoverCode';
import CoverTerms from '../cover-terms/CoverTerms';
import IconButton from '../icon-button/IconButton';
import Modal from '../modal/Modal';

interface CoverInformationDrawerProps {
    multiContentTarget: keyof CoverInfoV3;
    setMultiContentTarget: (value: keyof CoverInfoV3) => void;
    coverCode: CoverCode;
    pdsVersion: string;
    isOpen: boolean;
    onClose: () => void;
}

/*
    This cover information drawer component is identical to 
    SlideInCoverTerms with the exception that it does not include
    the drawer main menu. 
    The toggling of this drawer is intended to be controlled 
    at the parent level.
*/
const CoverInformationDrawer: FC<CoverInformationDrawerProps> = ({
    multiContentTarget,
    setMultiContentTarget,
    coverCode,
    pdsVersion,
    isOpen,
    onClose,
}) => {
    const coverInformation = useCoverInformation(coverCode, pdsVersion);
    const newCoverTerms = useCoverTerms(coverCode, pdsVersion)?.coverTermsSections;

    const closeModal = () => {
        onClose();
        setMultiContentTarget('mainMenu'); // reset
    };

    const switchSubMenu = (id: string) => {
        onClose();
        setTimeout(() => {
            setMultiContentTarget(id as keyof CoverInfoV3);
        }, 600);
    };

    return (
        <Modal isOpen={isOpen} parentClass="#root" onClose={onClose} variant="slide-in" className="no-scroll">
            <div className="slide-in-coverterms__modal-close-button-wrapper">
                <IconButton onClick={closeModal} icon={close} hoverIcon={blueClose} actionLabel="Close" />
            </div>
            <div
                className={classNames(
                    'slide-in-coverterms',
                    !!multiContentTarget && multiContentTarget !== 'mainMenu' && 'active',
                )}
            >
                <div className="slide-in-coverterms__step">
                    <CoverTerms
                        currentMenuId={multiContentTarget}
                        pdsVersion={pdsVersion}
                        coverCode={coverCode}
                        switchSubMenu={switchSubMenu}
                    />
                    <span className="slide-in-coverterms__pds">
                        {coverInformation?.name} {common.formatString(common.pdsVersion, { pdsVersion })}
                    </span>
                </div>
            </div>
        </Modal>
    );
};

export default CoverInformationDrawer;
