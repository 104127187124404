import { Appearance, loadStripe } from '@stripe/stripe-js';

const STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY || '';
const stripePromise = loadStripe(STRIPE_KEY);

const elementsOptions = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Mulish',
        },
    ],
};

const paymentElementAppearance: Appearance = {
    theme: 'flat',
    variables: {
        colorBackground: '#ffffff',
        fontFamily: 'Mulish, sans-serif',
        fontSizeBase: '16px',
        fontLineHeight: '24px',
        colorText: '#070a3d',
        colorDanger: '#be2301',
        colorDangerText: '#be2301',
        borderRadius: '4px',
    },
    rules: {
        '.Input': {
            border: '1.5px solid #bab4ab',
            padding: '15.5px 16px',
        },
        '.Input:focus': {
            border: '1.5px solid #070a3d',
            boxShadow: 'none',
        },
        '.Label': {
            fontSize: 'var(--fontSize3Xs)',
            fontWeight: 'var(--fontWeightBold)',
            lineHeight: '20px',
            textTransform: 'uppercase',
        },
        '.Tab--selected': {
            backgroundColor: '#070a3d',
        },
    },
};

export default { stripePromise, elementsOptions, paymentElementAppearance };
