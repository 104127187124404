import { FC } from 'react';
import { useUser } from '../../../../business-logic/context-provider/user-context/UserContext';
import ActivitiesSelection from '../../../../components/activities-selection/ActivitiesSelection';

interface ActivitiesStepsProps {
    onActivitiesComplete: (activities: string[]) => void;
}

const ActivitiesStep: FC<ActivitiesStepsProps> = ({ onActivitiesComplete }) => {
    const { userDetails } = useUser();
    return (
        <ActivitiesSelection
            updateProfile={!!userDetails.activities && userDetails.activities.length === 0}
            onActivitiesComplete={(activitiesToSubmit: string[]) => onActivitiesComplete(activitiesToSubmit)}
        />
    );
};

export default ActivitiesStep;
