import personsInsuredContent from '../../../../../content/ui/screens/persons-insured/personsInsured';
import isAgeWithinRange, {
    ACTIVE_MAX_AGE,
    ACTIVE_MIN_AGE,
    KIDS_MAX_AGE,
    KIDS_MIN_AGE,
} from '../../../../../utils/isAgeWithinRange';

const ageCheck = ({
    isAdult = false,
    dob,
    coverStartDates,
    ageEligibilityErrorMessage,
}: {
    isAdult: boolean;
    dob: string;
    coverStartDates: string[];
    ageEligibilityErrorMessage: string;
}): { eligible: boolean; errorMessage: string } => {
    const isPersonAgeWithinRange = isAgeWithinRange({
        dateOfBirth: dob,
        dates: coverStartDates,
        isAdult,
    });
    return {
        eligible: isPersonAgeWithinRange,
        errorMessage: personsInsuredContent
            .formatString(ageEligibilityErrorMessage, {
                minAge: (isAdult ? ACTIVE_MIN_AGE : KIDS_MIN_AGE).toString(),
                maxAge: (isAdult ? ACTIVE_MAX_AGE : KIDS_MAX_AGE).toString(),
            })
            .toString(),
    };
};

export default ageCheck;
