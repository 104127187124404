import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import Analytics from '../../analytics/Analytics';
import { useUser } from '../../business-logic/context-provider/user-context';
import { Button } from '../../components/button/Button';
import DateOfBirthInput from '../../components/date-of-birth-input/DateOfBirthInput';
import Dropdown from '../../components/dropdown/Dropdown';
import { Layout } from '../../components/layout/Layout';
import PartnerEventBanner from '../../components/layout/banners/partner-event-banner/PartnerEventBanner';
import { TextField } from '../../components/text-field/TextField';
import yourDetails from '../../content/ui/screens/your-details/yourDetails';
import requireFlags from '../../hoc/require-flags/requireFlags';
import withContent from '../../hoc/with-content/withContent';
import commonStrings from '../../strings/common';
import Routes from '../../utils/Routes';
import Limits from '../../utils/constants/Limits';
import { SignUpStep, SignUpStepName } from '../../utils/constants/SignUpStep';
import formatDateToString from '../../utils/formatDateToString';
import parseString from '../../utils/parseString';
import useYourDetailsForm from './utils/useYourDetails';

import './YourDetails.scss';

const contentMap = {
    heading: 'ui.heading',
    agreeTerms: 'ui.agreeTerms',
    email: 'ui.email',
    cta: 'ui.cta',
    firstName: 'ui.name.firstName',
    firstNamePlaceholder: 'ui.name.firstNamePlaceholder',
    lastNamePlaceholder: 'ui.name.lastNamePlaceholder',
    lastName: 'ui.name.lastName',
    day: 'ui.dateOfBirth.day',
    month: 'ui.dateOfBirth.month',
    year: 'ui.dateOfBirth.year',
    gender: 'ui.gender.title',
    genderPlaceHolder: 'ui.gender.placeholder',
    errorInvalidDate: 'ui.dateOfBirth.errorInvalidDate',
    errorMustBeOver18: 'ui.dateOfBirth.errorMustBeOver18',
};

interface YourDetailsProps {
    content: Record<keyof typeof contentMap, string>;
}

const YourDetails: React.FC<YourDetailsProps> = ({ content }) => {
    const { userDetails, setUserDetailsByAttr, initialiseNewUser } = useUser();
    const {
        gender: { options },
    } = yourDetails;

    const {
        state,
        handleYearChange,
        handleMonthChange,
        handleDayChange,
        handleDateOnBlur,
        handleGenderDropDown,
        handleLastNameChange,
        handleFirstNameChange,
    } = useYourDetailsForm();

    const ldClient = useLDClient();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;

    const [isLoading, setIsLoading] = useState(false);

    const [signUpError, setSignUpError] = useState('');
    const { dobDay, dobMonth, dobYear, firstName, lastName, gender, dobError } = state;

    const handleContinueClick = async () => {
        setSignUpError('');
        const dob = new Date(`${dobYear}/${dobMonth}/${dobDay}`);
        const formattedDob = formatDateToString(dob);
        // Update global state
        setUserDetailsByAttr('firstName', firstName);
        setUserDetailsByAttr('lastName', lastName);
        setUserDetailsByAttr('gender', gender?.value);
        setUserDetailsByAttr('dob', formattedDob);

        setIsLoading(true);

        try {
            await initialiseNewUser({ firstName, lastName, dateOfBirth: formattedDob, gender: gender?.value });

            history.push({
                pathname: Routes.ACTIVITIES,
                state: { isOnboarding: isOnboardingFlow },
            });
        } catch (err) {
            console.log(err);
            setSignUpError(commonStrings.errorSomethingWentWrong);
        } finally {
            Analytics.trackSignUpStepCompleted(SignUpStep.YOUR_DETAILS + 1, SignUpStepName[SignUpStep.YOUR_DETAILS]);
            setIsLoading(false);
        }
    };

    const hasError = signUpError !== '' || dobError !== '';

    useEffect(() => {
        Analytics.trackSignUpStepViewed(SignUpStep.YOUR_DETAILS + 1, SignUpStepName[SignUpStep.YOUR_DETAILS]);
        ldClient?.identify({ kind: 'user', key: userDetails.id, viewedYourDetailsScreenWeb: true });
    }, []);

    return (
        <Layout
            title={content.heading}
            showBackButton={!isOnboardingFlow}
            showLoading={isLoading}
            banner={<PartnerEventBanner />}
        >
            <form>
                <TextField
                    id="email"
                    name="email"
                    label={content.email}
                    value={userDetails.email}
                    disabled={!!userDetails.email}
                    onChange={() => {}}
                    className="your-details__text"
                />
                <TextField
                    id="firstName"
                    name="firstName"
                    label={content.firstName}
                    value={firstName}
                    placeholder={content.firstNamePlaceholder}
                    minLength={Limits.NAME_MIN_LENGTH}
                    maxLength={Limits.NAME_MAX_LENGTH}
                    onChange={handleFirstNameChange}
                    className="your-details__text"
                />
                <TextField
                    id="lastName"
                    name="lastName"
                    label={content.lastName}
                    value={lastName}
                    placeholder={content.lastNamePlaceholder}
                    minLength={Limits.NAME_MIN_LENGTH}
                    maxLength={Limits.NAME_MAX_LENGTH}
                    onChange={handleLastNameChange}
                    className="your-details__text"
                />
                <DateOfBirthInput
                    day={dobDay}
                    month={dobMonth}
                    year={dobYear}
                    hasError={hasError}
                    onDayChange={(e) => {
                        setSignUpError('');
                        handleDayChange(e);
                    }}
                    onMonthChange={(e) => {
                        setSignUpError('');
                        handleMonthChange(e);
                    }}
                    onYearChange={(e) => {
                        setSignUpError('');
                        handleYearChange(e);
                    }}
                    onBlur={handleDateOnBlur}
                />
                {dobError && <p className="error date-of-birth__error ">{dobError}</p>}
                <Dropdown
                    options={[...options]}
                    label={content.gender}
                    placeholder={content.genderPlaceHolder}
                    value={gender}
                    onChange={handleGenderDropDown}
                    searchable={false}
                    className="your-details__dropdown"
                />
                <p className="your-details__terms">{parseString(content.agreeTerms)}</p>
                <Button
                    label={commonStrings.continue}
                    disabled={!(firstName && lastName) || hasError || !gender}
                    width="full"
                    className="your-details__btn-continue"
                    onClick={handleContinueClick}
                />
                {signUpError && <p className="error your-details__error">{commonStrings.errorSomethingWentWrong}</p>}
            </form>
        </Layout>
    );
};

export default requireFlags(withContent(YourDetails, contentMap, yourDetails));
