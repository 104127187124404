/**
 * Custom error exception that does not warrant reporting on observability platform
 * as they are not platform exceptions but user errors handled by external services.
 * These error scenarios do not provide relevant uptime information regarding the
 * health of Flip application and platform uptime or customer behaviours
 */

export const NON_REPORTABLE_ERROR_TYPE = 'not-reportable';

function NonReportableError(message: string, cause: any) {
    var error = new Error(message, cause);
    error.name = NON_REPORTABLE_ERROR_TYPE;
    return error;
}
NonReportableError.prototype = Object.create(Error.prototype);

export default NonReportableError;
