enum CartStep {
    PROFILE,
    ACTIVITIES,
    WHOS_COVERED,
    PAYMENT,
}

export const CartStepsWithoutKids = [CartStep.PROFILE, CartStep.ACTIVITIES, CartStep.PAYMENT];

export const CartStepsWithKids = [CartStep.PROFILE, CartStep.ACTIVITIES, CartStep.WHOS_COVERED, CartStep.PAYMENT];

export default CartStep;
