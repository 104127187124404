import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import './IconButton.scss';

export interface IconButtonProps {
    onClick: () => void;
    size?: 'small' | 'large';
    icon: string;
    hoverIcon: string;
    actionLabel?: string;
}

// Button component consists of single icon that triggers action not hyperlink
const IconButton: React.FC<IconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
    onClick,
    size = 'large',
    icon,
    hoverIcon,
    actionLabel = '',
}) => {
    return (
        <button
            type="button"
            className={classNames('icon-button', `icon-button--${size}`, 'button')}
            onClick={onClick}
            title={actionLabel}
        >
            <img
                src={icon}
                alt={actionLabel}
                className={classNames('icon-button__icon--base', `icon-button__icon--${size}`)}
            />
            <img
                src={hoverIcon}
                alt={actionLabel}
                className={classNames('icon-button__icon--hover', `icon-button__icon--${size}`)}
            />
        </button>
    );
};

export default IconButton;
