import classNames from 'classnames';
import { FC } from 'react';
import chevron from '../../../../assets/images/chevron-right-harbour.svg';
import cartContent from '../../../../content/ui/screens/cart/cart';
import withContent from '../../../../hoc/with-content/withContent';
import logo from '../../../../logo.svg';
import CartStep from '../../constants/CartStep';

import './StepProgressBar.scss';

const contentMap = {
    [`${[CartStep.PROFILE]}`]: `ui.steps.${[CartStep.PROFILE]}`,
    [`${[CartStep.ACTIVITIES]}`]: `ui.steps.${[CartStep.ACTIVITIES]}`,
    [`${[CartStep.WHOS_COVERED]}`]: `ui.steps.${[CartStep.WHOS_COVERED]}`,
    [`${[CartStep.PAYMENT]}`]: `ui.steps.${[CartStep.PAYMENT]}`,
};

interface StepProgressBarProps {
    content: Record<keyof typeof contentMap, string>;
    steps: CartStep[];
    currentStep: number;
    layoutHasBanner?: boolean;
}

const StepProgressBar: FC<StepProgressBarProps> = ({ content, steps, currentStep, layoutHasBanner }) => (
    <>
        <div className="step-progress-bar__logo-wrapper">
            <img src={logo} alt="" className="step-progress-bar__logo" />
        </div>
        <div
            className={classNames('step-progress-bar', layoutHasBanner && 'step-progress-bar--with-layout-banner')}
            id="step-progress-bar"
        >
            {Object.values(steps).map((step, index) => (
                <div className="step-progress-bar__wrapper" key={step}>
                    <span
                        className={classNames(
                            'step-progress-bar__step',
                            index <= currentStep && 'step-progress-bar__step--completed',
                        )}
                    >
                        {content[step]}
                    </span>
                    {index < Object.values(steps).length - 1 && (
                        <img
                            src={chevron}
                            alt=""
                            className={classNames(
                                'step-progress-bar__chevron',
                                index <= currentStep && 'step-progress-bar__chevron--completed',
                            )}
                        />
                    )}
                </div>
            ))}
        </div>
    </>
);

export default withContent(StepProgressBar, contentMap, cartContent);
