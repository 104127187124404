import { assign } from 'xstate';
import { Typegen0 } from '../kidDetailsFormMachine.typegen';
import { KidDetailsFormMachineContext } from '../context/kidDetailsFormMachineContext';
import Gender from '../../../../utils/constants/Gender';
import kidDetailsFormContent from '../../../../content/ui/components/kid-details-form/kidDetailsForm';
import AgeRangeEligibility from '../../../../utils/constants/AgeEligibilityRange';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setFirstName = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setFirstName']; data: string }
>({
    firstName: (_, event) => event.data,
}) as any;

export const setLastName = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setLastName']; data: string }
>({
    lastName: (_, event) => event.data,
}) as any;

export const setDate = assign<
    KidDetailsFormMachineContext,
    {
        type: EventsCausingActions['setDate'];
        data: { value: string; coverStartDates: string[]; minAge?: number; maxAge?: number };
    }
>({
    coverStartDates: (_, event) => event.data.coverStartDates,
    config: (ctx, event) => ({
        minAge: event.data.minAge ?? 5,
        maxAge: event.data.maxAge ?? 17,
        nameMinLength: ctx.config.nameMinLength,
    }),
    dob: (ctx, event) => ({ ...ctx.dob, day: event.data.value }),
}) as any;

export const setMonth = assign<
    KidDetailsFormMachineContext,
    {
        type: EventsCausingActions['setMonth'];
        data: { value: string; coverStartDates: string[]; minAge?: number; maxAge?: number };
    }
>({
    coverStartDates: (_, event) => event.data.coverStartDates,
    config: (ctx, event) => ({
        minAge: event.data.minAge ?? AgeRangeEligibility.DEPENDANT_MIN_AGE,
        maxAge: event.data.maxAge ?? AgeRangeEligibility.DEPENDANT_MAX_AGE,
        nameMinLength: ctx.config.nameMinLength,
    }),
    dob: (ctx, event) => ({ ...ctx.dob, month: event.data.value }),
}) as any;

export const setYear = assign<
    KidDetailsFormMachineContext,
    {
        type: EventsCausingActions['setYear'];
        data: { value: string; coverStartDates: string[]; minAge?: number; maxAge?: number };
    }
>({
    coverStartDates: (_, event) => event.data.coverStartDates,
    config: (ctx, event) => ({
        minAge: event.data.minAge ?? AgeRangeEligibility.DEPENDANT_MIN_AGE,
        maxAge: event.data.maxAge ?? AgeRangeEligibility.DEPENDANT_MAX_AGE,
        nameMinLength: ctx.config.nameMinLength,
    }),
    dob: (ctx, event) => ({ ...ctx.dob, year: event.data.value }),
}) as any;

export const setInvalidDateFormatError = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setInvalidDateFormatError'] }
>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: kidDetailsFormContent.dobError.errorInvalidDate,
    }),
}) as any;

export const setIneligibleAgeError = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setIneligibleAgeError'] }
>({
    dob: (ctx) => {
        return {
            ...ctx.dob,
            error: kidDetailsFormContent
                .formatString(kidDetailsFormContent.dobError.errorAgeIneligible, {
                    minAge: ctx.config.minAge ?? 0,
                    maxAge: ctx.config.maxAge ?? 0,
                })
                .toString(),
        };
    },
}) as any;

export const clearDobError = assign<
    KidDetailsFormMachineContext,
    | {
          type: Extract<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
          data: string;
      }
    | {
          type: Exclude<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
      }
>({
    dob: (ctx) => ({ ...ctx.dob, error: '' }),
}) as any;

export const setGender = assign<
    KidDetailsFormMachineContext,
    { type: EventsCausingActions['setGender']; data: Gender }
>({
    gender: (_, event) => event.data,
}) as any;
