import differenceInYears from 'date-fns/differenceInYears';
import { KidDetailsFormMachineContext } from '../context/kidDetailsFormMachineContext';
import isValidDate from '../../../../validation/isValidDate';
import DateFormat from '../../../../utils/constants/DateFormat';
import parse from 'date-fns/parse';

export const isFirstNameValid = (ctx: KidDetailsFormMachineContext): boolean =>
    ctx.firstName.length >= ctx.config.nameMinLength;

export const isLastNameValid = (ctx: KidDetailsFormMachineContext): boolean =>
    ctx.lastName.length >= ctx.config.nameMinLength;

export const isDateFilledIn = (ctx: KidDetailsFormMachineContext): boolean =>
    ctx.dob.day.length > 0 && ctx.dob.month.length > 0 && ctx.dob.year.length === 4;

export const isValidDateFormat = (ctx: KidDetailsFormMachineContext): boolean =>
    ctx.dob.year.length === 4 && isValidDate(ctx.dob.day, ctx.dob.month, ctx.dob.year);

export const isGenderSelected = (ctx: KidDetailsFormMachineContext): boolean => {
    return ctx.gender !== null;
};

export const isAgeIneligible = (ctx: KidDetailsFormMachineContext): boolean => {
    return !ctx.coverStartDates.every((startDate) => {
        const dob = parse(`${ctx.dob.day}/${ctx.dob.month}/${ctx.dob.year}`, DateFormat.DEFAULT, new Date());
        const date = parse(startDate, DateFormat.DEFAULT, new Date());
        // Calculate the age at that particular date
        const age = differenceInYears(date, dob);
        return !!ctx.config?.minAge && ctx.config.maxAge && age >= ctx.config?.minAge && age <= ctx.config.maxAge;
    });
};
