import LocalizedStrings from 'react-localization';

const mobileAppDownloadModalContent = new LocalizedStrings({
    en: {
        appStoreActionText: 'Download on the',
        appStoreStoreName: 'App Store',
        playStoreActionText: 'Get it on',
        playStoreStoreName: 'Google Play',
        cta: 'Back to dashboard',
    },
});

export default mobileAppDownloadModalContent;
