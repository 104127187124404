import UserDetails from '../../../../../business-logic/models/UserDetails';
import { RadioCheckboxProps } from '../../../../../components/form/RadioCheckboxProps';

const toDependantCheckboxProps = (dependant: UserDetails): Omit<RadioCheckboxProps, 'checked' | 'onChange'> => {
    return {
        id: dependant.personId!,
        name: `${dependant.firstName} ${dependant.lastName}}`,
        label: `${dependant.firstName} ${dependant.lastName}`,
    };
};

export default toDependantCheckboxProps;
