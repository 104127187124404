import { Alias } from '../../../business-logic/models/Alias';
import UserDetails from '../../../business-logic/models/UserDetails';

export function getInsuredPersonName(
    insurablePersons: (UserDetails | Alias)[],
    insuredPersonId: string | null,
): string | null {
    return (
        insurablePersons.find((x) => x.personId?.toLowerCase() === insuredPersonId?.toLowerCase())?.firstName || null
    );
}

export default getInsuredPersonName;
