import createAlias from './createAlias';
import createDependants from './createDependants';
import getAdults from './getAliases';
import getDependants from './getDependants';

export default {
    createDependants,
    getDependants,
    getAdults,
    createAlias,
};
