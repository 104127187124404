import classNames from 'classnames';
import { ListItemProps } from '../../../components/list-item/ListItem';
import Button from '../../../components/button/Button';

import './AccountButtonsList.scss';

export interface AccountButtonsListProps {
    buttonItems: ListItemProps[];
    header?: string;
    innerHeader?: string;
    className?: string;
}

const AccountButtonsList = ({ header, innerHeader, buttonItems, className }: AccountButtonsListProps) => {
    return (
        <div className={classNames('account-btn-group', 'card', className)}>
            {header && <h2 className="account-btn-group__header">{header}</h2>}
            <div className="account-btn-group__wrapper">
                {innerHeader && <p className="account-btn-group__inner-header">{innerHeader}</p>}
                {buttonItems.map((item: ListItemProps) => (
                    <Button
                        variant="drawer-link"
                        key={item.id}
                        label={item.data}
                        onClick={item.onClick}
                        className="account-btn-group__button"
                        suffixIcon={item.rightIcon}
                    />
                ))}
            </div>
        </div>
    );
};

export default AccountButtonsList;
