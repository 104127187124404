import React from 'react';
import appStoreButton from '../../assets/images/app-store-button--filled.svg';
import info from '../../assets/images/icon-info-blue-40x40.svg';
import playStoreButton from '../../assets/images/play-store-button--filled.svg';
import mobileAppDownloadModalContent from '../../content/ui/components/mobile-app-download-modal/mobileAppDownloadModal';
import withContent from '../../hoc/with-content/withContent';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import LinkButton from '../link-button/LinkButton';
import Modal from '../modal/Modal';

import './ModalAppDownloadModal.scss';

interface MobileDownloadModalProps {
    isOpen: boolean;
    header: string;
    description: string;
    content: Record<keyof typeof contentMap, string | string[]>;
    onClose: () => void;
}

const renderDownloadAppButton = (imageSrc: string, accessibleText: string) => {
    return (
        <>
            <img src={imageSrc} alt="" />
            <span className="mobile-app-download-modal__download-button-hidden-text">{accessibleText}</span>
        </>
    );
};

const contentMap = {
    header: 'ui.header',
    description: 'ui.description',
    appStoreActionText: 'ui.appStoreActionText',
    appStoreStoreName: 'ui.appStoreStoreName',
    playStoreActionText: 'ui.playStoreActionText',
    playStoreStoreName: 'ui.playStoreStoreName',
    cat: 'ui.cta',
};

const MobileAppDownloadModal: React.FC<MobileDownloadModalProps> = ({
    isOpen,
    content,
    header,
    description,
    onClose,
}) => {
    return (
        <Modal
            className="mobile-app-download-modal"
            isOpen={isOpen}
            parentClass="#root"
            onClose={onClose}
            variant="slide-to-center"
            universalClose
        >
            <div className="mobile-app-download-modal__content">
                <img src={info} alt="" />
                <h3 className="mobile-app-download-modal__content__heading">{header}</h3>
                <p className="mobile-app-download-modal__content__text">{description}</p>
                <div className="mobile-app-download-modal__buttons">
                    <LinkButton
                        label={renderDownloadAppButton(
                            appStoreButton,
                            `${content.appStoreActionText} ${content.appStoreStoreName}`,
                        )}
                        href={ExternalLinks.mobileAppAppleAppStore}
                        variant="secondary"
                        target="_blank"
                        onClick={() => {
                            window.location.href = ExternalLinks.mobileAppAppleAppStore;
                        }}
                    />
                    <LinkButton
                        label={renderDownloadAppButton(
                            playStoreButton,
                            `${content.playStoreActionText} ${content.playStoreStoreName}`,
                        )}
                        href={ExternalLinks.mobileAppGooglePlayStore}
                        variant="secondary"
                        target="_blank"
                        onClick={() => {
                            window.location.href = ExternalLinks.mobileAppGooglePlayStore;
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default withContent(MobileAppDownloadModal, contentMap, mobileAppDownloadModalContent);
