import LocalizedStrings from 'react-localization';

const personsInsuredContent = new LocalizedStrings({
    en: {
        heading: 'Who do you want cover for?',
        myselfLabel: 'Myself',
        kidsLabel: 'For kids',
        kidsInfo:
            'You will be the account holder on behalf of the Kids’ policies. You must be over 18 and a parent or legal guardian of the children you’re buying cover for.',
        addAKidCta: 'Add a kid',
        addAnAdultCta: 'Add an adult',
        kidDetailsFormHeading: "Kid's detail",
        kidDetailsFormCta: 'Save',
        kidDetailsFormCancel: 'Close',
        adultDetailsFormHeading: "Adult's detail",
        adultDetailsFormCta: 'Save',
        adultDetailsFormCancel: 'Close',
        cta: 'Continue to payment',
        kidOver18: 'This dependant is over 18 and need to purchase its own cover.',
        ageEligibilityTitle:
            'The cover selected is only eligible for customers between {minAge} and {maxAge} years of age.',
    },
});

export default personsInsuredContent;
