import { FormEvent, ReactNode } from 'react';
import alert from '../../assets/images/alert-alert-large.svg';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { PolicyItem } from '../../business-logic/models/PoliciesResponse';
import cancellationCoverDrawerContent from '../../content/ui/components/cancel-cover-drawer/cancelCoverDrawer';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import DateFormat from '../../utils/constants/DateFormat';
import formatISO8601DateTime from '../../utils/formatISO8601DateTime';
import getTimezone from '../../utils/getTimezone';
import Button from '../button/Button';
import Fieldset from '../form/fieldset/Fieldset';

interface CancelCoverListProps {
    onSelectCoverToCancel: (e: FormEvent<HTMLFormElement>) => void;
    policy: PolicyItem | null;
    handleCloseModal: () => void;
}

const CancelCoverList = ({ onSelectCoverToCancel, policy, handleCloseModal }: CancelCoverListProps) => {
    const { userTimeZone } = useUser();
    const { coverInformation } = useProduct();

    const renderCoverToCancel = (coverName: ReactNode, subText: ReactNode, destination?: ReactNode) => {
        return (
            <div className="cancel-cover-drawer__checkbox-label">
                <span className="cancel-cover-drawer__checkbox-label-cover-name">{coverName}</span>
                <span className="cancel-cover-drawer__checkbox-label-cover-text">{subText}</span>
                <div className="cancel-cover-drawer__checkbox-label-cover-text">{destination}</div>
            </div>
        );
    };
    const renderSubscriptionLabel = (subscription: PolicyItem) => {
        const coverName = coverInformation[subscription.mainCover.coverCode][subscription.PDSVersion].name;
        const defaultStartDate = formatISO8601DateTime(
            subscription.mainCover.startTime,
            subscription.policyTimezone ?? userTimeZone,
            DateFormat.DEFAULT,
        );
        const subText =
            subscription.mainCover.status === 'Scheduled'
                ? cancellationCoverDrawerContent.formatString(cancellationCoverDrawerContent.starting, {
                      startDate: formatISO8601DateTime(
                          subscription.mainCover.startTime,
                          userTimeZone,
                          DateFormat.VERBOSE,
                      ),
                      timezone: getTimezone(subscription.policyTimezone ?? userTimeZone, defaultStartDate),
                  })
                : cancellationCoverDrawerContent.formatString(cancellationCoverDrawerContent.renews, {
                      renewDate: formatISO8601DateTime(
                          subscription.mainCover.nextCycleAt!,
                          userTimeZone,
                          DateFormat.VERBOSE,
                      ),
                      timezone: getTimezone(subscription.policyTimezone ?? userTimeZone, defaultStartDate),
                  });

        return renderCoverToCancel(coverName, subText);
    };

    const renderSingleCoverLabel = (cover: PolicyItem) => {
        const coverName = coverInformation[cover.mainCover.coverCode][cover.PDSVersion].name;
        const defaultStartDate = formatISO8601DateTime(
            cover.mainCover.startTime,
            cover.policyTimezone ?? userTimeZone,
            DateFormat.DEFAULT,
        );
        const subText = cancellationCoverDrawerContent.formatString(cancellationCoverDrawerContent.starting, {
            startDate: formatISO8601DateTime(
                cover.mainCover.startTime,
                cover.policyTimezone ?? userTimeZone,
                DateFormat.VERBOSE,
            ),
            timezone: getTimezone(cover.policyTimezone ?? userTimeZone, defaultStartDate),
        });

        const destination = cover.mainCover.destination ? (
            <>{cover.mainCover.destination?.destinations.sort((a, b) => a.localeCompare(b)).join(', ')}</>
        ) : (
            ''
        );
        return renderCoverToCancel(coverName, subText, destination);
    };

    return (
        <form onSubmit={onSelectCoverToCancel} className="cancel-cover-drawer__form">
            <img src={alert} alt="" />
            <h2 className="cancel-cover-drawer__heading">{cancellationCoverDrawerContent.cancelYourCover}</h2>
            <Fieldset
                legend={cancellationCoverDrawerContent.cancelYourCover}
                visuallyHideLegend
                className="cancel-cover-drawer__fieldset"
            >
                {policy && policy?.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1 && (
                    <div
                        key={`${policy?.mainCover.insuranceCoverId}-${policy?.policyNumber}`}
                        className="cancel-cover-drawer__checkbox--subscription"
                    >
                        {renderSubscriptionLabel(policy)}
                        {policy.mainCover.status !== 'Scheduled' &&
                            cancellationCoverDrawerContent.formatString(
                                cancellationCoverDrawerContent.cancelSubscriptionInfo,
                                {
                                    endDateTime: formatISO8601DateTime(
                                        policy.mainCover.currentCycleEndTime!,
                                        policy.policyTimezone ?? userTimeZone,
                                        DateFormat.TIME_AND_DATE,
                                    ),
                                },
                            )}
                    </div>
                )}
                {policy && policy?.mainCover.coverTypeId === CoverTypeId.SINGLE_V1 && renderSingleCoverLabel(policy)}
            </Fieldset>

            <Button
                type="submit"
                variant="primary"
                label={cancellationCoverDrawerContent.cancelCoverCTA}
                width="full"
                className="cancel-cover-drawer__cta"
            />
            <Button
                type="button"
                width="full"
                variant="secondary"
                className="cancel-cover-drawer__cta--secondary"
                label="Oops, go back"
                onClick={handleCloseModal}
            />
            <p className="cancel-cover-drawer__cancel-info">{cancellationCoverDrawerContent.cancelInfo}</p>
        </form>
    );
};

export default CancelCoverList;
