import { assign } from 'xstate';
import { Typegen0 } from '../adultDetailsFormMachine.typegen';
import { AdultDetailsFormMachineContext } from '../context/adultDetailsFormMachineContext';
import adultDetailsFormContent from '../../../../content/ui/components/adult-details-form/adultDetailsForm';
import AgeRangeEligibility from '../../../../utils/constants/AgeEligibilityRange';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setFirstName = assign<
    AdultDetailsFormMachineContext,
    { type: EventsCausingActions['setFirstName']; data: string }
>({
    firstName: (ctx, event) => event.data,
}) as any;

export const setLastName = assign<
    AdultDetailsFormMachineContext,
    { type: EventsCausingActions['setLastName']; data: string }
>({
    lastName: (ctx, event) => event.data,
}) as any;

export const setDate = assign<
    AdultDetailsFormMachineContext,
    {
        type: EventsCausingActions['setDate'];
        data: { value: string; coverStartDates: string[]; minAge?: number; maxAge?: number };
    }
>({
    coverStartDates: (_, event) => event.data.coverStartDates,
    config: (ctx, event) => ({
        minAge: event.data.minAge ?? AgeRangeEligibility.ADULT_MIN_AGE,
        maxAge: event.data.maxAge ?? AgeRangeEligibility.ADULT_MAX_AGE,
        nameMinLength: ctx.config.nameMinLength,
    }),
    dob: (ctx, event) => ({ ...ctx.dob, day: event.data.value }),
}) as any;

export const setMonth = assign<
    AdultDetailsFormMachineContext,
    {
        type: EventsCausingActions['setMonth'];
        data: { value: string; coverStartDates: string[]; minAge?: number; maxAge?: number };
    }
>({
    coverStartDates: (_, event) => event.data.coverStartDates,
    config: (ctx, event) => ({
        minAge: event.data.minAge ?? AgeRangeEligibility.ADULT_MIN_AGE,
        maxAge: event.data.maxAge ?? AgeRangeEligibility.ADULT_MAX_AGE,
        nameMinLength: ctx.config.nameMinLength,
    }),
    dob: (ctx, event) => ({ ...ctx.dob, month: event.data.value }),
}) as any;

export const setYear = assign<
    AdultDetailsFormMachineContext,
    {
        type: EventsCausingActions['setYear'];
        data: { value: string; coverStartDates: string[]; minAge?: number; maxAge?: number };
    }
>({
    coverStartDates: (_, event) => event.data.coverStartDates,
    config: (ctx, event) => ({
        minAge: event.data.minAge ?? AgeRangeEligibility.ADULT_MIN_AGE,
        maxAge: event.data.maxAge ?? AgeRangeEligibility.ADULT_MAX_AGE,
        nameMinLength: ctx.config.nameMinLength,
    }),
    dob: (ctx, event) => ({ ...ctx.dob, year: event.data.value }),
}) as any;

export const setInvalidDateFormatError = assign<
    AdultDetailsFormMachineContext,
    { type: EventsCausingActions['setInvalidDateFormatError'] }
>({
    dob: (ctx) => ({
        ...ctx.dob,
        error: adultDetailsFormContent.dobError.errorInvalidDate,
    }),
}) as any;

export const clearDobError = assign<
    AdultDetailsFormMachineContext,
    | {
          type: Extract<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
          data: string;
      }
    | {
          type: Exclude<EventsCausingActions['clearDobError'], 'UPDATE_DATE' | 'UPDATE_MONTH' | 'UPDATE_YEAR'>;
      }
>({
    dob: (ctx) => ({ ...ctx.dob, error: '' }),
}) as any;

export const setEmail = assign<
    AdultDetailsFormMachineContext,
    { type: EventsCausingActions['setEmail']; data: string }
>({
    email: (ctx, event) => event.data,
}) as any;

export const setEmailError = assign<AdultDetailsFormMachineContext, { type: EventsCausingActions['setEmailError'] }>({
    emailError: () => adultDetailsFormContent.emailError,
}) as any;

export const clearEmailError = assign<
    AdultDetailsFormMachineContext,
    | {
          type: Extract<EventsCausingActions['clearEmailError'], 'UPDATE_EMAIL'>;
          data: string;
      }
    | {
          type: Exclude<EventsCausingActions['clearEmailError'], 'UPDATE_EMAIL'>;
      }
>({
    emailError: () => '',
}) as any;

export const setPurchaserPrivacyCheck = assign<
    AdultDetailsFormMachineContext,
    { type: EventsCausingActions['setPurchaserPrivacyCheck']; data: boolean }
>({
    purchaserPrivacyCheck: (_, event) => event.data,
}) as any;

export const setInsuredPersonPrivacyCheck = assign<
    AdultDetailsFormMachineContext,
    { type: EventsCausingActions['setInsuredPersonPrivacyCheck']; data: boolean }
>({
    insuredPersonPrivacyCheck: (_, event) => event.data,
}) as any;

export const setResidencyCheck = assign<
    AdultDetailsFormMachineContext,
    { type: EventsCausingActions['setResidencyCheck']; data: boolean }
>({
    residencyCheck: (_, event) => event.data,
}) as any;

export const setIneligibleAgeError = assign<
    AdultDetailsFormMachineContext,
    { type: EventsCausingActions['setIneligibleAgeError'] }
>({
    dob: (ctx) => {
        return {
            ...ctx.dob,
            error: adultDetailsFormContent
                .formatString(adultDetailsFormContent.dobError.errorAgeIneligible, {
                    minAge: ctx.config.minAge ?? AgeRangeEligibility.ADULT_MIN_AGE,
                    maxAge: ctx.config.maxAge ?? AgeRangeEligibility.ADULT_MAX_AGE,
                })
                .toString(),
        };
    },
}) as any;
