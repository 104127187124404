import classNames from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';
import blueArrow from '../../assets/images/arrow-left-blue.svg';

import './DashboardButton.scss';

export enum DashboardButtonStyle {
    REGULAR = 'REGULAR',
    LITE = 'LITE',
}

export type DashboardButtonProps = {
    label: string;
    icon?: string;
    description?: string;
    onClick: () => void;
    className?: string;
    style?: DashboardButtonStyle;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const DashboardButton: FC<DashboardButtonProps> = ({
    label,
    icon,
    description,
    className,
    style = DashboardButtonStyle.REGULAR,
    onClick,
    ...props
}) => {
    return style === DashboardButtonStyle.LITE ? (
        <button
            type="button"
            onClick={onClick}
            className={classNames('dashboard-button', 'dashboard-button--lite', className)}
            {...props}
        >
            <div className="dashboard-button__title">
                <img src={icon} alt="" className="dashboard-button__title__icon" />
                <span>{label}</span>
            </div>
            <div className="button--drawer-link__icon">
                <img src={blueArrow} alt="" className="button--drawer-link__icon__arrow" />
            </div>
        </button>
    ) : (
        <button type="button" onClick={onClick} className={classNames('dashboard-button', className)} {...props}>
            <div className="dashboard-button__title--regular">
                <span>{label}</span>
                <div className="button--drawer-link__icon">
                    <img src={blueArrow} alt="" className="button--drawer-link__icon__arrow" />
                </div>
            </div>
            <div className="dashboard-button__content">
                <img src={icon} alt="" className="dashboard-button__content__icon" />
                <span>{description}</span>
            </div>
        </button>
    );
};

export default DashboardButton;
