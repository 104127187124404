import { CustomerResponseProfile } from '../../models/CustomerResponse';
import LazyDependency from '../../models/LazyDependency';
import UserDetails from '../../models/UserDetails';
import UserPreferences from '../../models/UserPreferences';

// Interfaces
export interface UserState {
    loading: boolean;
    initialised: boolean;
    accessToken?: string;
    userDetails: UserDetails;
    userPreferences: UserPreferences;
    variables: Record<string, unknown>;
    onboardingQuizAnswers: {
        existingInjury?: boolean;
        workAccidents?: boolean;
        motorcycling?: boolean;
    };
}

export interface UserActions {
    setAccessToken: (accessToken: string) => void;
    setUserDetailsByAttr: (attr: keyof UserDetails, value?: string | undefined | string[]) => void;
    setUserBankDetails: (accountName: string, accountNumber: string, bsb: string) => void;
    setUserVariables?: (variables: Record<string, unknown>) => void;
    updateUserPreference: (id: string, checked: boolean) => void;
    refreshUserPreferences: () => Promise<void>;
    refreshUserDetails: () => Promise<void>;
    initialiseNewUser: (profile: Partial<CustomerResponseProfile>) => Promise<void>;
    logout: () => Promise<void>;
    setQuizAnswer: (data: Record<string, boolean>) => void;
}

export interface UserProperties {
    userTimeZone: string;
    profileCompleted: boolean;
    residencyCompleted: boolean;
}

export interface UserLazyDependencies {
    creditBalance: LazyDependency<number>;
}

export const initialState: UserState = {
    loading: true,
    initialised: false,
    accessToken: undefined,
    userDetails: {
        personId: null,
    },
    userPreferences: {
        reminders: true,
        productSurveys: true,
        guidesAndPromotions: true,
        policyAndClaimUpdates: true,
        accountUpdates: true,
    },
    variables: {},
    onboardingQuizAnswers: {},
};

export type IUserContext = UserState & UserActions & UserProperties & UserLazyDependencies;

export type UserDispatchAction =
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_INITIALISED'; payload: boolean }
    | { type: 'SET_ACCESS_TOKEN'; payload: string }
    | { type: 'SET_USER_DETAILS'; payload: UserDetails }
    | { type: 'SET_USER_ATTRIBUTE'; payload: Record<string, any> }
    | { type: 'SET_USER_PREFERENCES'; payload: UserPreferences }
    | { type: 'UPDATE_USER_PREFERENCE'; payload: Record<string, any> }
    | { type: 'SET_USER_VARIABLES'; payload: Record<string, unknown> }
    | { type: 'SET_QUIZ_ANSWER'; payload: Record<string, boolean> };
