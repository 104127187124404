import LocalizedStrings from 'react-localization';

const maintenance = new LocalizedStrings({
    en: {
        heading: 'Flippin on a few things...',
        description:
            "We're currently working on a couple of scheduled maintenance tasks. We will be back up to full speed soon. ",
        contactUs: 'If you have any questions please reach out to us at {flipSupportEmail}.',
    },
});

export default maintenance;
