import { page, track, identify, reset, trackV2 } from './segmentAnalytics';
import { PageEvent, AnalyticsUserTraits, AuthenticationMethod } from './Analytics.d';
import { getAnalyticsPageName } from './utils';
import { CHECKOUT_EVENT, PRODUCT_SELECTION_EVENT } from './AnalyticsConstants';

const Analytics = {
    // Screens tracking
    trackScreen: ([category, name]: PageEvent): void => {
        page(category, name);
    },

    // Identify the user
    identify: (traits?: Partial<AnalyticsUserTraits>, user_id?: string): void => {
        identify(traits, user_id);
    },

    // Auth events
    // Registration
    trackSignedUp: (traits?: Partial<AnalyticsUserTraits>): void => {
        setTimeout(() => {
            trackV2('Signed Up', traits);
        }, 200);
    },

    trackAppstoreRedirect: (platform: string) => {
        trackV2('Redirect to Mobile App Download', { platform });
    },

    trackSignUpStepViewed: (step: number, step_name: string): void => {
        trackV2('Sign Up Step Viewed', {
            shipping_method: 'digital',
            step_name,
            step,
        });
    },

    trackSignUpStepCompleted: (step: number, step_name: string): void => {
        trackV2('Sign Up Step Completed', {
            shipping_method: 'digital',
            step_name,
            step,
        });
    },

    // Login
    trackSignedIn: (traits?: Partial<AnalyticsUserTraits>): void => {
        trackV2('Signed In', traits);
    },

    // Logout
    trackSignedOut: (traits?: Partial<AnalyticsUserTraits>): void => {
        trackV2('Signed Out', traits);
        reset();
    },

    // // For reaching Sign In page
    trackSignedInStarted: (): void => {
        trackV2('Signed In Started');
    },

    reset: (): void => {
        reset();
    },

    // Purchase Flow Product Selection
    trackProductListViewed: (): void => {
        trackV2('Product List Viewed');
    },

    trackProductViewed: (): void => {
        trackV2('Product Viewed');
    },

    trackProductInterestConfirmed: (): void => {
        trackV2('Product Interest Expressed');
    },

    trackProductSelectionStepViewed: (step_name: PRODUCT_SELECTION_EVENT): void => {
        trackV2('Product Selection Step Viewed', {
            step_name,
        });
    },

    trackProductSelectionStepCompleted: (step_name: PRODUCT_SELECTION_EVENT): void => {
        trackV2('Product Selection Step Completed', {
            step_name,
        });
    },

    trackProductSelectionExited: (step_name: PRODUCT_SELECTION_EVENT): void => {
        trackV2('Product Selection Exited', {
            step_name,
        });
    },

    // Check out
    trackPaymentInfoEntered: (checkout_id: string, step: number, payment_method: string): void => {
        trackV2('Payment Info Entered', {
            checkout_id,
            shipping_method: 'digital',
            payment_method,
            step,
        });
    },

    trackWalletCheckoutStarted: (checkout_id: string, step: number, payment_method: string): void => {
        trackV2('Wallet Checkout Started', {
            checkout_id,
            shipping_method: 'digital',
            payment_method,
            step,
        });
    },

    trackCouponEntered: (checkout_id: string, coupon_id: string): void => {
        trackV2('Coupon Entered', { checkout_id, coupon_id });
    },

    trackCouponApplied: (checkout_id: string, coupon_id: string, discount: number): void => {
        trackV2('Coupon Applied', { checkout_id, coupon_id, discount: discount / 100 });
    },

    trackCouponDenied: (checkout_id: string, coupon_id: string, reason: string): void => {
        trackV2('Coupon Denied', { checkout_id, coupon_id, reason });
    },

    trackCheckoutStarted: (): void => {
        trackV2('Checkout Started');
    },

    trackCheckoutStepViewed: (step_name: CHECKOUT_EVENT): void => {
        trackV2('Checkout Step Viewed', {
            step_name,
        });
    },

    trackCheckoutStepCompleted: (step_name: CHECKOUT_EVENT, properties?: Record<string, unknown>): void => {
        trackV2('Checkout Step Completed', {
            step_name,
            properties,
        });
    },

    trackCheckoutCompleted: (): void => {
        trackV2('Checkout Completed');
    },

    trackOrderCompleted: ({
        order_id,
        total,
        discount,
        coupon,
        products,
    }: {
        order_id: string;
        discount?: number;
        coupon?: string;
        total: number;
        products: Record<string, unknown>[];
    }): void =>
        trackV2('Order Completed', {
            order_id,
            total: total / 100,
            revenue: total / 100,
            discount: discount ? discount / 100 : undefined,
            coupon,
            currency: 'AUD',
            products,
        }),
    // Profile events
    trackAccountClickViewPrivacyPolicy: (): void => {
        track('Account_Click=ViewPrivacyPolicy');
    },

    trackSignUpMethodClicked: (authenticationMethod: AuthenticationMethod): void => {
        track('Sign Up Method Clicked', { authenticationMethod });
    },

    trackSignInMethodClicked: (authenticationMethod: AuthenticationMethod): void => {
        track('Sign In Method Clicked', { authenticationMethod });
    },

    trackPDSLinkClicked: (): void => {
        track('PDS Link Clicked', { page: getAnalyticsPageName() });
    },

    trackTMDLinkClicked: (): void => {
        track('TMD Link Clicked', { page: getAnalyticsPageName() });
    },

    // Miscel
    trackTabChange: (screenName: string, product: Record<string, unknown>): void => {
        trackV2(`${screenName} Tab Clicked`, { ...product });
    },

    trackBenefitsDrawerViewed: (screen_name: string, coverCode: string): void => {
        trackV2('Benefits Drawer triggered', { screen_name, coverCode });
    },

    trackAddedAdult: (): void => {
        trackV2('Added an Adult');
    },

    trackAddedDependant: (): void => {
        trackV2('Added a Dependant');
    },
};

export default Analytics;
