/* eslint-disable no-underscore-dangle */
import { datadogRum } from '@datadog/browser-rum';
import axios, { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { NON_REPORTABLE_ERROR_TYPE } from './nonReportableError';

class SessionIdService {
    private _sessionId: string;

    constructor() {
        this._sessionId = uuidv4();
    }

    get sessionId(): string {
        return this._sessionId;
    }

    resetSessionId() {
        this._sessionId = uuidv4();
    }
}

const sessionIdService = new SessionIdService();

export interface ServiceArgs {
    axios: AxiosInstance;
}

/**
 * Wrapper for a "service" implementation. Handles try catch, error logging through datadog,
 * as well as providing an axios instance that will send the same X-Session-Id for a batch of calls
 *
 * @param serviceImplementation the service implementation
 * @returns whatever the service should return
 */
function asService<T, U>(serviceImplementation: (args: ServiceArgs & T) => Promise<U>, withSessionId = true) {
    return async function serviceInvocation(args: T): Promise<U> {
        try {
            const axiosInstance = axios.create(
                withSessionId ? { headers: { 'X-Session-Id': sessionIdService.sessionId } } : undefined,
            );

            return await serviceImplementation({ axios: axiosInstance, ...args });
        } catch (error: any) {
            const errorObject = typeof error === 'object' ? error : new Error(error);

            if (error.name !== NON_REPORTABLE_ERROR_TYPE) {
                datadogRum.addError(errorObject, {
                    serviceName: serviceImplementation.name,
                    data: args,
                    ...(axios.isAxiosError(error)
                        ? {
                              response: {
                                  config: error.response?.config,
                                  data: error.response?.data,
                                  headers: error.response?.headers,
                                  status: error.response?.status,
                                  statusText: error.response?.statusText,
                              },
                          }
                        : {}),
                });
            }
            throw errorObject;
        } finally {
            sessionIdService.resetSessionId();
        }
    };
}

export default asService;
