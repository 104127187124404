import axios from 'axios';
import PoliciesResponse, { PolicyItem } from '../../business-logic/models/PoliciesResponse';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';
import toHeaders from '../utils/toHeaders';
import assertAccessTokenValidity from './utils/assertTokenValidity';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getScheduledCovers = async ({
    accessToken,
    queryParams,
}: {
    accessToken: string | undefined;
    queryParams: { personId: string; page: number };
} & ServiceArgs): Promise<PolicyItem[] | null> => {
    try {
        assertAccessTokenValidity(accessToken);
        const { data } = await axios.get<PoliciesResponse>(`${baseApiPath}/api/v1/bff-policies/policies/scheduled`, {
            params: {
                person: queryParams.personId ?? '', // Empty string denotes accountholder
                page: queryParams.page ?? 1,
            },
            ...toHeaders(accessToken, { 'X-API-Version': 3 }),
        });
        return data.policies;
    } catch (error) {
        if (isAxios404Error(error)) {
            return null; // handle error silently
        }
        throw new Error(
            `Error with calling GET scheduled cover service: ${error}, for person id: ${queryParams.personId} on page: ${queryParams.page}`,
        );
    }
};

export default asService(getScheduledCovers);
