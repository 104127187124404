import { Alias, AliasResponse } from '../../business-logic/models/Alias';

const transformAliasResponse = (createAliasResponse: AliasResponse): Alias => {
    return {
        personId: createAliasResponse.aliasedPersonId,
        firstName: createAliasResponse.alias.firstName,
        lastName: createAliasResponse.alias.lastName,
        email: createAliasResponse.email,
    };
};

export default transformAliasResponse;
