import { FC } from 'react';
import Layout from '../../components/layout/Layout';
import maintenance from '../../content/ui/screens/maintenance/maintenance';
import common from '../../strings/common';
import ExternalLink from '../../components/external-link/ExternalLink';
import logo from '../../logo.svg';

import './Maintenance.scss';

const Maintenance: FC = () => {
    return (
        <Layout>
            <h1 className="maintenance__heading">{maintenance.heading}</h1>
            <p>{maintenance.description}</p>
            <p>
                {maintenance.formatString(maintenance.contactUs, {
                    flipSupportEmail: (
                        <ExternalLink
                            label={common.flipSupportEmail}
                            to={`mailto:${common.flipSupportEmail}`}
                            className="maintenance__link"
                        />
                    ),
                })}
            </p>
            <div className="maintenance__logo">
                <img src={logo} alt="" />
            </div>
        </Layout>
    );
};

export default Maintenance;
