import { CoverInfoV3 } from '../../content/coverTermsTypes';
import useCoverTerms from '../../hooks/useCoverTerms';
import CoverCode from '../../utils/constants/CoverCode';
import parseString from '../../utils/parseString';
import stringToReactKey from '../../utils/stringToReactKey';
import Alert, { AlertTypes } from '../alert/Alert';
import Button from '../button/Button';
import { ListItemProps } from '../list-item/ListItem';
import List from '../list/List';
import check from '../../assets/images/deep-green-check.svg';

import './CoverTerms.scss';

interface CoverTermsProps {
    coverCode: CoverCode;
    pdsVersion: string;
    currentMenuId: keyof CoverInfoV3;
    switchSubMenu: (id: string) => void;
}

const CoverTerms = ({ coverCode, pdsVersion, currentMenuId, switchSubMenu }: CoverTermsProps) => {
    const newCoverTerms = useCoverTerms(coverCode, pdsVersion)?.coverTermsSections;
    const newSelectedContent = newCoverTerms?.find((x) => x.id === currentMenuId)?.content;
    return (
        <>
            {newSelectedContent?.map(({ contentBlockType, content }) => {
                switch (contentBlockType) {
                    case 'title':
                        return (
                            <h1 className="cover-terms__title" key={content}>
                                {content}
                            </h1>
                        );
                    case 'text':
                        return (
                            <div className="cover-terms__text" key={content}>
                                {parseString(content)}
                            </div>
                        );
                    case 'steps':
                        return (
                            <div key={JSON.stringify(content)}>
                                <div className="cover-terms__howitworks">
                                    {content.map((step: string, stepIndex: number) => (
                                        <div key={step + stepIndex} className="cover-terms__howitworks__step">
                                            <div className="cover-terms__howitworks__step__number">{stepIndex + 1}</div>
                                            <span className="cover-terms__howitworks__step__content">
                                                {parseString(step || '')}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    case 'payoutCategories':
                        return (
                            <div key={JSON.stringify(content)} className="cover-terms__payout-categories">
                                {content.map(({ categoryName, payouts }) => (
                                    <div
                                        className="cover-terms__payout-category"
                                        key={JSON.stringify(categoryName) + JSON.stringify(payouts)}
                                    >
                                        {categoryName ? (
                                            <p className="cover-terms__payout-category-name">{categoryName}</p>
                                        ) : null}
                                        {payouts.map((payout: { injurySeverity: string; payoutAmount: string }) => (
                                            <div className="cover-terms__payout" key={payout.injurySeverity}>
                                                <div className="cover-terms__payout-severity-wrapper">
                                                    <img
                                                        src={check}
                                                        alt=""
                                                        className="cover-terms__payout-severity-icon"
                                                    />
                                                    <span>{payout.injurySeverity}</span>
                                                </div>
                                                <span>{payout.payoutAmount}</span>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        );
                    case 'iconList':
                        return (
                            <div key={content.toString()}>
                                <div className="cover-terms__additional-notes">
                                    {content.map((iconItem) => (
                                        <div key={iconItem.content} className="cover-terms__additional-note__item">
                                            <img
                                                src={iconItem.icon}
                                                alt=""
                                                className="cover-terms__additional-note__icon"
                                            />
                                            <span>{parseString(iconItem.content)}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    case 'disclosureList': {
                        const exclusionsList: ListItemProps[] = content.map((exclusion) => ({
                            id: stringToReactKey(exclusion.summary),
                            icon: exclusion.icon,
                            data: exclusion.summary,
                            dataExpanded: (
                                <span className="cover-terms__exclusion--data-expanded">{exclusion.content}</span>
                            ),
                        }));
                        return (
                            <List
                                items={exclusionsList}
                                className="cover-terms__exclusions"
                                key={content.map((x) => stringToReactKey(x.summary)).join('')}
                            />
                        );
                    }
                    case 'navigateToCoverTermsSection':
                        return (
                            <div key={content.label}>
                                <Button
                                    variant="drawer-link"
                                    className="cover-terms__trigger-button"
                                    label={content.label}
                                    onClick={() => switchSubMenu(content.toCoverTermsSectionId)}
                                    width="full"
                                />
                            </div>
                        );
                    case 'info':
                        return (
                            <Alert
                                key={content.toString()}
                                className="cover-terms__alert"
                                message={<span>{parseString(content)}</span>}
                                type={AlertTypes.INFO}
                            />
                        );
                    default:
                        return '';
                }
            })}
        </>
    );
};

export default CoverTerms;
