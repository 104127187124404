import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BandaidIcon from '../../assets/images/icon-bandaid.svg';
import CalendarIcon from '../../assets/images/icon-calendar.svg';
import ticketIcon from '../../assets/images/icon-ticket.svg';
import { useUser } from '../../business-logic/context-provider/user-context';
import BrandPurchaseButton from '../../components/brand-purchase-button/BrandPurchaseButton';
import DashboardButton from '../../components/dashboard-button/DashboardButton';
import DashboardV2 from '../../components/dashboard/DashboardV2';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import HomeContent from '../../content/ui/screens/home/home';
import requireFlags from '../../hoc/require-flags/requireFlags';
import withContent from '../../hoc/with-content/withContent';
import Routes from '../../utils/Routes';
import SideNavigation from './components/SideNavigation';
import MobileAppDownloadModal from '../../components/mobile-download-modal/MobileAppDownloadModal';
import claimsDeprecationContent from '../../content/ui/components/claims-deprecation-modal/claimsDeprecationModal';
import groupActiveRegisterModal from '../../content/ui/components/group-active-register-modal/groupActiveRegisterModal';

import './Home.scss';

const contentMap = {
    greeting: 'ui.greeting',
    purchaseHeading: 'ui.purchaseHeading',
    linksHeading: 'ui.linksHeading',
    manageCoverLinkTitle: 'ui.manageCoverLinkTitle',
    manageCoverLinkDescription: 'ui.manageCoverLinkDescription',
    groupActiveRegisterLinkTitle: 'ui.groupActiveRegisterLinkTitle',
    groupActiveRegisterLinkDescription: 'ui.groupActiveRegisterLinkDescription',
    claimsLinkTitle: 'ui.claimsLinkTitle',
    claimsLinkDescription: 'ui.claimsLinkDescription',
    coverSubLinkTitle: 'ui.coverSubLinkTitle',
    claimsSubLinkTitle: 'ui.claimsSubLinkTitle',
    accountSubLinkTitle: 'ui.accountSubLinkTitle',
};

interface HomeProps {
    content: Record<keyof typeof contentMap, string>;
}

const Home: FC<HomeProps> = ({ content }) => {
    const history = useHistory();
    const { loading: userLoading, userDetails } = useUser();
    const [openClaimsDeprecation, setOpenClaimsDeprecation] = useState(false);
    const [openGroupActiveRegister, setOpenGroupActiveRegister] = useState(false);
    const isAppLoading = userLoading;

    if (isAppLoading) {
        return <LoadingSpinnerOverlay />;
    }

    return (
        <DashboardV2
            title={HomeContent.formatString(content.greeting, { name: userDetails.firstName || '!' }).toString()}
        >
            <div className="home-v3 dashboardV2__main__content">
                <div className="home-v3__column home-v3__column--main">
                    <DashboardButton
                        label={content.manageCoverLinkTitle}
                        icon={CalendarIcon}
                        description={content.manageCoverLinkDescription}
                        onClick={() => {
                            // TODO: revisit route url
                            history.push(Routes.MANAGE_COVER);
                        }}
                    />
                    <div className="home-v3__purchase--mobile">
                        <h4 className="home-v3__purchase__heading">{HomeContent.purchaseHeading}</h4>
                        <BrandPurchaseButton />
                    </div>
                    <DashboardButton
                        label={content.groupActiveRegisterLinkTitle}
                        icon={ticketIcon}
                        description={content.groupActiveRegisterLinkDescription}
                        onClick={() => {
                            setOpenGroupActiveRegister(true);
                        }}
                    />
                    <DashboardButton
                        label={content.claimsLinkTitle}
                        icon={BandaidIcon}
                        description={content.claimsLinkDescription}
                        onClick={() => {
                            setOpenClaimsDeprecation(true);
                        }}
                    />
                </div>
            </div>
            <MobileAppDownloadModal
                header={claimsDeprecationContent.header}
                description={claimsDeprecationContent.description}
                isOpen={openClaimsDeprecation}
                onClose={() => setOpenClaimsDeprecation(false)}
            />
            <MobileAppDownloadModal
                header={groupActiveRegisterModal.header}
                description={groupActiveRegisterModal.description}
                isOpen={openGroupActiveRegister}
                onClose={() => setOpenGroupActiveRegister(false)}
            />
            <SideNavigation className="dashboardV2__main__side" />
        </DashboardV2>
    );
};

export default requireFlags(withContent(Home, contentMap, HomeContent));
