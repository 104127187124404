import Routes, { Route } from '../Routes';

const PageTitles: Record<Route, string> = {
    [Routes.HOME]: 'Dashboard',
    [Routes.LOGIN]: 'Login',
    [Routes.FORGOT_PASSWORD]: 'Forgot password',

    // Onboarding
    [Routes.LANDING]: 'Welcome',
    [Routes.REGISTER]: 'Create account',

    // Sign up flow
    [Routes.YOUR_DETAILS]: 'Your details',
    [Routes.ACTIVITIES]: 'Activities',

    // Checkout
    [Routes.SELECT_PRODUCT_OPTIONS]: 'Purchase options',
    [Routes.SELECT_COVER]: 'Select cover',
    [Routes.COVER_INFORMATION]: 'Cover Information',
    [Routes.SELECT_DATES]: 'Select dates',
    [Routes.COVER_DETAILS]: 'Your selected cover',

    [Routes.SELECT_DESTINATIONS]: 'Select Destinations',
    [Routes.SELECT_STARTING_DESTINATION]: 'Select starting destination',
    [Routes.SELECT_STARTING_REGION]: 'Select starting region',

    // Profile
    [Routes.ACCOUNT]: 'Account',
    [Routes.ACCOUNT_DETAILS]: 'Account details',
    [Routes.ACCOUNT_PREFERENCES]: 'Preferences',
    [Routes.ACCOUNT_CERTIFICATE_OF_COVER]: 'Certificate of cover',
    [Routes.MANAGE_COVER]: 'Manage Cover',

    [Routes.CART]: 'Cart',
    [Routes.CART_SUCCESS]: 'Checkout Success',

    // Intermediate states, no need to title
    [Routes.LOGIN_CALLBACK]: '',
    [Routes.PURCHASE_ENTRY]: '',

    [Routes.NOT_FOUND]: 'Page not found',
};

export default PageTitles;
