import { useFlags, useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useState } from 'react';
import { OpenCoversProvider } from '../../business-logic/context-provider/OpenCoversContext';
import { PersonsProvider } from '../../business-logic/context-provider/persons-provider/PersonsContext';
import { ProductProvider } from '../../business-logic/context-provider/ProductContext';
import { ReferralProvider } from '../../business-logic/context-provider/ReferralContext';
import { UserProvider } from '../../business-logic/context-provider/user-context';
import ToastProvider from '../../components/toast/ToastProvider';
import ZendeskProvider from '../../components/zendesk/Zendesk';
import Maintenance from '../maintenance/Maintenance';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';

const LAUNCH_DARKLY_CLIENT_SIDE_ID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID || '';

/**
 * Adds LaunchDarkly provider and Cover Provider
 *
 * @param param0
 * @returns
 */
const Providers: FC<{}> = (props) => {
    const { maintenance } = useFlags();
    const [initialised, setInitialised] = useState<boolean>(false);
    const ldClient = useLDClient();

    // This check guarantees the maintenance feature flag value is always
    // available (not undefined) from launchdarkly when the app needs to
    // decide whether to render Maintenance page or to initialise and
    // run the rest of the app
    useEffect(() => {
        if (ldClient && !initialised) {
            ldClient.waitForInitialization().then(() => {
                setInitialised(true);
            });
        }
    }, [setInitialised, initialised, ldClient]);

    if (!initialised) {
        return <LoadingSpinnerOverlay />;
    }

    return maintenance ? (
        <Maintenance />
    ) : (
        <UserProvider>
            <ZendeskProvider>
                <ToastProvider>
                    <ReferralProvider>
                        <OpenCoversProvider>
                            <PersonsProvider>
                                <ProductProvider {...props} />
                            </PersonsProvider>
                        </OpenCoversProvider>
                    </ReferralProvider>
                </ToastProvider>
            </ZendeskProvider>
        </UserProvider>
    );
};

export default withLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
})(Providers);
