import LocalizedStrings from 'react-localization';

const kidDetailsFormContent = new LocalizedStrings({
    en: {
        firstName: {
            label: 'First name',
            placeholder: 'John',
        },
        lastName: {
            label: 'Last name',
            placeholder: 'Citizen',
        },
        gender: {
            label: 'Select gender',
        },
        dropdownPlaceholder: 'Please select',
        dobError: {
            errorInvalidDate: 'Sorry, the date doesn’t look right',
            errorAgeIneligible:
                'The cover selected is only eligible for customers between {minAge} and {maxAge} years of age.',
        },
    },
});

export default kidDetailsFormContent;
