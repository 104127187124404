import { FC } from 'react';
import { ProductOption } from '../../../../business-logic/models/ProductGroupsResponse';
import Fieldset from '../../../../components/form/fieldset/Fieldset';
import PurchaseOptionsRadioButton from '../purchase-options-radio-button/PurchaseOptionsRadioButton';

import './PurchaseOptionsRadioGroup.scss';

interface PurchaseOptionsRadioGroup {
    productOptions: ProductOption[];
    legend: string;
    selectedProductOptionId: string | null;
    onChange: (productOptionId: string) => void;
}

const PurchaseOptionsRadioGroup: FC<PurchaseOptionsRadioGroup> = ({
    productOptions,
    legend,
    selectedProductOptionId,
    onChange,
}) => {
    return (
        <Fieldset legend={legend} visuallyHideLegend className="purchase-options-radio-group">
            {productOptions.map((productOption, index) => (
                <div key={productOption.id}>
                    {index !== 0 && <hr className="purchase-options-radio-button__divider" />}
                    <PurchaseOptionsRadioButton
                        productOption={productOption}
                        checked={selectedProductOptionId === productOption.id}
                        onChange={onChange}
                    />
                </div>
            ))}
        </Fieldset>
    );
};

export default PurchaseOptionsRadioGroup;
