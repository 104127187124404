import { ChangeEvent, Ref } from 'react';
import commonStrings from '../../strings/common';
import Button from '../button/Button';
import Sticky from '../sticky/Sticky';
import ActivityTile, { Activity } from './ActivityTile';

interface ActivitiesListProps {
    displayActivities: Activity[];
    onValueChange: (activityId: string, e: ChangeEvent<HTMLInputElement>) => void;
    onFreeTextChange: (activityId: string, e: ChangeEvent<HTMLInputElement>) => void;
    handleContinueClick: () => void;
    error: string;
    disableContinue: boolean;
    freeTextRef: Ref<HTMLInputElement>;
}

const ActivitiesList = ({
    displayActivities,
    onValueChange,
    onFreeTextChange,
    handleContinueClick,
    error,
    disableContinue,
    freeTextRef,
}: ActivitiesListProps) => {
    return (
        <div>
            {displayActivities.map((activityItem) => {
                return (
                    <ActivityTile
                        key={activityItem.activityConfig.id}
                        activityConfig={activityItem.activityConfig}
                        activityState={activityItem.activityState}
                        onChange={(e) => onValueChange(activityItem.activityConfig.id, e)}
                        onFreeTextChange={onFreeTextChange}
                        ref={freeTextRef}
                    />
                );
            })}
            <Sticky>
                <Button
                    label={commonStrings.continue}
                    disabled={disableContinue}
                    width="full"
                    className="select-activities__btn-continue"
                    onClick={handleContinueClick}
                />
                {!!error && <p className="error">{error}</p>}
            </Sticky>
        </div>
    );
};

export default ActivitiesList;
