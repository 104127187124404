import classNames from 'classnames';
import React, { useState } from 'react';
import commonStrings from '../../strings/common';
import Alert, { AlertSizes, AlertTypes } from '../alert/Alert';
import Checkbox from '../form/checkbox/Checkbox';
import { RadioCheckboxProps } from '../form/RadioCheckboxProps';
import RADIO_CHECKBOX_SIZE from '../form/RadioCheckboxSize';
import { TextField } from '../text-field/TextField';
import Tooltip from '../tooltip/Tooltip';

import './CheckboxList.scss';

export interface CheckboxListProps {
    list: RadioCheckboxProps[];
    onOtherOptionChange?: (value: string) => void;
    shouldPersistOtherField?: boolean;
    className?: string;
    checkboxSize?: typeof RADIO_CHECKBOX_SIZE;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
    list,
    onOtherOptionChange,
    shouldPersistOtherField = false,
    className,
    checkboxSize = 'small',
}) => {
    const [otherOption, setOtherOption] = useState('');

    const onCheckboxChange = (id: string, checked = false) => {
        if (!shouldPersistOtherField) return;
        // remove other textfield value if unchecked
        if (id === 'other' && !checked && !!otherOption.length) {
            setOtherOption('');
            if (onOtherOptionChange) onOtherOptionChange('');
        }
    };

    return (
        <ul className={classNames('checkbox-list', className)}>
            {list.map((item) => (
                <li key={item.id} className="checkbox-list__item">
                    <div className="checkbox-list__item__row">
                        <Checkbox
                            id={item.id}
                            name={item.name}
                            label={item.label}
                            checked={item.checked}
                            onChange={(e) => {
                                onCheckboxChange(item.id, item.checked);
                                item.onChange(e);
                            }}
                            onFocus={item.onFocus}
                            onBlur={item.onBlur}
                            className={item.className}
                            disabled={item.disabled}
                            size={checkboxSize}
                        />
                        {item.id === 'other' && item.checked && (
                            <TextField
                                name={commonStrings.other}
                                value={otherOption}
                                className="checkbox-list__other-textfield"
                                onChange={(e) => {
                                    setOtherOption(e.target.value);
                                    if (onOtherOptionChange) onOtherOptionChange(e.target.value);
                                }}
                            />
                        )}
                        {item.showTooltip && (
                            <div className="checkbox-list__item__tooltip">
                                <Tooltip
                                    modalProps={{
                                        title: item.modalContent,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="checkbox-list__item__row">
                        {item.showAlert && (
                            <Alert
                                message={item.modalContent}
                                type={AlertTypes.INFO}
                                size={AlertSizes.SMALL}
                                className="checkbox-list-item__alert"
                            />
                        )}
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default CheckboxList;
