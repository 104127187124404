import LocalizedStrings from 'react-localization';

const HomeContent = new LocalizedStrings({
    en: {
        greeting: 'Hi {name}',
        purchaseHeading: 'Get more from Flip',
        linksHeading: 'Quick links',
        manageCoverLinkTitle: 'Manage Cover',
        manageCoverLinkDescription: 'View purchased Flip cover that is active or scheduled, and your cover history.',
        groupActiveRegisterLinkTitle: 'Register with your Event Code',
        groupActiveRegisterLinkDescription:
            'Have you received a registration Event Code from your event, team or venue?',
        claimsLinkTitle: 'Make a claim',
        claimsLinkDescription: 'Super quick claims, get paid fast.',
        coverSubLinkTitle: 'Cover',
        claimsSubLinkTitle: 'Claims',
        accountSubLinkTitle: 'Account',
        dashboardSubLinkTitle: 'Dashboard',
    },
});

export default HomeContent;
