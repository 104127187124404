import { useOktaAuth } from '@okta/okta-react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import ModalWithCTA from '../../components/modal-with-cta/ModalWithCTA';
import checkAgeEligibilityContent from '../../content/ui/components/check-age-eligibility/checkAgeEligibility';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import toDate from '../../utils/toDate';
import getAge from '../../validation/getAge';
import withContent from '../with-content/withContent';
import Alert, { AlertTypes } from '../../components/alert/Alert';

const ACTIVE_MIN_AGE = 18;

const contentMap = {
    title: 'ui.title',
    description: 'ui.description',
    cta: 'ui.cta',
};

interface WrappedComponentWithAgeCheckProps extends JSX.IntrinsicAttributes {
    content: Record<keyof typeof contentMap, string>;
}

function checkAgeEligibility<T extends JSX.IntrinsicAttributes>(WrappedComponent: FC<T>) {
    const WrappedComponentWithAgeCheck = (props: T & WrappedComponentWithAgeCheckProps): ReactElement => {
        const { authState } = useOktaAuth();
        const { content, ...passThroughProps } = props;
        const { userDetails } = useUser();
        const [showModal, setShowModal] = useState(false);
        const history = useHistory();

        const backToDashboard = () => {
            history.push(Routes.HOME);
        };

        useEffect(() => {
            // If guest, do not check for age eligibility
            if (!authState?.isAuthenticated) {
                return;
            }

            if (typeof userDetails.dob === 'undefined') {
                history.push(Routes.YOUR_DETAILS);
                return;
            }

            const { day, month, year } = toDate(userDetails.dob);

            const isMinAge = getAge(day, month, year) >= ACTIVE_MIN_AGE;

            if (!isMinAge) {
                setShowModal(true);
            }
        }, [authState?.isAuthenticated, history, userDetails.dob]);
        return (
            <>
                <ModalWithCTA
                    isOpen={showModal}
                    title={
                        checkAgeEligibilityContent.formatString(content.title, {
                            minAge: ACTIVE_MIN_AGE.toString(),
                        }) as string
                    }
                    textContent={
                        <Alert
                            type={AlertTypes.ALERT}
                            message={checkAgeEligibilityContent.formatString(content.description, {
                                customerSupport: (
                                    <a href={`mailto:${common.flipSupportEmail}`}>{common.customerSupport}</a>
                                ),
                            })}
                        />
                    }
                    primaryCTA={{ label: content.cta, onClick: backToDashboard }}
                />
                <WrappedComponent {...(passThroughProps as T)} />
            </>
        );
    };

    return withContent(WrappedComponentWithAgeCheck, contentMap, checkAgeEligibilityContent);
}

export default checkAgeEligibility;
