import PriceLineItem from '../price-line-item/PriceLineItem';
import paymentsStrings from '../../../../strings/payments';
import formatPrice from '../../../../utils/formatPrice';
import { CheckoutDetailsResponse } from '../../../../business-logic/models/CheckoutDetails';

export default (checkoutDetails: CheckoutDetailsResponse) => {
    return (
        <div className="checkout-summary__account-credit">
            <PriceLineItem
                description={paymentsStrings.creditApplied}
                amount={`-${formatPrice(checkoutDetails.invoice.balanceApplied)}`}
                withTopSpacing
            />
        </div>
    );
};
