import { datadogRum } from '@datadog/browser-rum';
import { Alias, AliasResponse } from '../../business-logic/models/Alias';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios409Error from '../utils/isAxios409Error';
import toHeaders from '../utils/toHeaders';
import withRetriesAsync from '../utils/withRetriesAsync';
import transformAliasResponse from './transformAliasResponse';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getAliases = async ({
    accessToken,
    axios,
}: {
    accessToken: string;
} & ServiceArgs): Promise<Alias[]> => {
    const { data } = await withRetriesAsync(
        () => axios.get<AliasResponse[]>(`${baseApiPath}/api/v1/person/alias`, toHeaders(accessToken)),
        undefined,
        (error) => {
            // If 409, log error and retry fetch
            if (isAxios409Error(error)) {
                datadogRum.addError(error, {
                    serviceName: 'getAliases',
                    description: 'conflict in get aliases call',
                });
                return true;
            }
            return false;
        },
    );

    return data.map((x) => transformAliasResponse(x));
};

export default asService(getAliases);
