import classNames from 'classnames';
import { RadioCheckboxProps } from '../RadioCheckboxProps';
import RADIO_CHECKBOX_SIZE from '../RadioCheckboxSize';
import tick from '../../../assets/images/icon-check-white.svg';

import './Checkbox.scss';

const Checkbox: React.FC<RadioCheckboxProps> = ({
    id,
    name,
    label,
    className,
    checked,
    isError,
    variant = 'standard',
    form,
    onChange,
    onBlur,
    onFocus,
    disabled,
    size = RADIO_CHECKBOX_SIZE.LARGE,
}) => (
    <label
        htmlFor={id}
        className={classNames(
            'checkbox',
            disabled && 'checkbox--disabled',
            isError && 'checkbox--error',
            className,
            size === RADIO_CHECKBOX_SIZE.SMALL && 'small',
            variant === 'contained' && 'checkbox--contained',
        )}
    >
        <input
            type="checkbox"
            id={id}
            name={name}
            checked={!!checked}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            className="checkbox__input"
            disabled={disabled}
            form={form}
        />
        <div
            className={classNames(
                'checkbox__decorated-checkbox',
                { checked },
                size === RADIO_CHECKBOX_SIZE.SMALL && 'small',
            )}
        >
            <img src={tick} className="checkbox__decorated-checkbox__tick" alt="" />
        </div>
        {label}
    </label>
);

export default Checkbox;
