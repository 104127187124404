export enum PRODUCT_SELECTION_EVENT {
    SELECT_DESTINATION = 'select_destination',
    SELECT_COVER = 'select_cover',
    SELECT_DATES = 'select_dates',
    REVIEW_PRODUCT = 'review_product',
    WHAT_IS_NOT_COVERED = 'what_is_not_covered',
    WHAT_IS_COVERED = 'what_is_covered',
}

export enum CHECKOUT_EVENT {
    YOUR_DETAILS = 'customer_profile',
    ACTIVITY = 'activities',
    PERSONS_COVERED = 'select_persons_covered',
    REVIEW_PURCHASE = 'review_purchase',
    PAYMENT = 'payment_details',
}

export default {
    PRODUCT_SELECTION_EVENT,
    CHECKOUT_EVENT,
};
