import { useOktaAuth } from '@okta/okta-react';
import { useMachine } from '@xstate/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import Layout from '../../components/layout/Layout';
import CreditBanner from '../../components/layout/banners/credit-banner/CreditBanner';
import cartContent from '../../content/ui/screens/cart/cart';
import requireFlags from '../../hoc/require-flags/requireFlags';
import withContent from '../../hoc/with-content/withContent';
import useLazyDependency from '../../hooks/lazy-dependency/useLazyDependency';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import StepItem from '../guest-cart/components/step-item/StepItem';
import ProfileStep from '../guest-cart/steps/profile/ProfileStep';
import cartMachine from './cart-machine/cartMachine';
import StepProgressBar from './components/step-progress-bar/StepProgressBar';
import CartStep from './constants/CartStep';
import ActivitiesStep from './steps/activities/ActivitiesStep';
import PaymentStep from './steps/payment-step/PaymentStep';
import PersonsInsured from './steps/persons-insured/PersonsInsured';

import './Cart.scss';

const contentMap = {
    header: 'ui.header',
    selectProductStepTitle: 'ui.selectProductStep.title',
    selectProductStepDescription: 'ui.selectProductStep.description',
    selectProductStepFlipActiveDescription: 'ui.selectProductStep.descriptionFlipActive',
    registerStepTitle: 'ui.registerStep.title',
    registerStepDescription: 'ui.registerStep.description',
    profileStepTitle: 'ui.profileStep.title',
    profileStepDescription: 'ui.profileStep.description',
    activitiesStepTitle: 'ui.activitiesStep.title',
    activitiesStepDescription: 'ui.activitiesStep.description',
    whosCoveredStepTitle: 'ui.whosCoveredStep.title',
    whosCoveredStepDescription: 'ui.whosCoveredStep.description',
    paymentStepTitle: 'ui.paymentStep.title',
    referralVerificationSuccess: 'ui.referral.referralVerificationSuccess',
    referralVerificationError: 'ui.referral.referralVerificationError',
};

interface CartProps {
    content: Record<keyof typeof contentMap, string>;
}

const Cart: FC<CartProps> = ({ content }) => {
    const {
        creditBalance: creditBalanceLazyDependency,
        userDetails,
        loading: userLoading,
        initialised: userInitialised,
        userTimeZone,
        profileCompleted,
        residencyCompleted,
    } = useUser();
    const location = useLocation<PurchaseState>();
    const {
        products,
        productGroups,
        coverInformation: allCoverInformation,
        loading: productsLoading,
        initialised: productsInitialised,
    } = useProduct();
    const { multiAdultPurchase } = useFlags();
    const creditBalance = useLazyDependency(creditBalanceLazyDependency);
    const { authState } = useOktaAuth();
    const history = useHistory();
    const { selectedProductOption } = location.state;

    // if selectedProductOption is null, redirect to /purchase, redirect using useEffect
    const [state, send] = useMachine(cartMachine, {
        context: {
            isExistingUser: profileCompleted && residencyCompleted,
            purchaseState: location.state,
        },
        actions: {
            redirectToPurchase: () => {
                history.push({
                    pathname: Routes.SELECT_COVER,
                    state: {
                        selectedProductGrouping: null,
                        selectedProductOption: null,
                        destinations: null,
                    },
                });
            },
        },
    });

    const { currentStep, showStep, steps } = state.context;

    const layoutHasBanner = !!creditBalance.value;

    const profileDescription =
        userLoading || !userInitialised || !state.context.isExistingUser
            ? content.profileStepDescription
            : userDetails.email;

    useEffect(() => {
        if (!productsLoading && productsInitialised && products.length) {
            send({
                type: 'CONTEXT_INITIALISED',
                data: {
                    products,
                    productGroups,
                    coverInformation: allCoverInformation,
                    withMultiAdultPurchase: multiAdultPurchase,
                },
            });
        }
    }, [
        products,
        productsInitialised,
        productsLoading,
        send,
        userDetails,
        productGroups,
        authState?.isAuthenticated,
        state.context,
        allCoverInformation,
        multiAdultPurchase,
    ]);

    const renderStepItem = (step: CartStep, index: number) => {
        switch (step) {
            case CartStep.PROFILE:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.profileStepTitle}
                        description={profileDescription}
                        open={showStep === CartStep.PROFILE}
                        onToggle={() => send('TOGGLE_PROFILE')}
                        completed={currentStep > CartStep.PROFILE}
                        disabled={!state.can('TOGGLE_PROFILE') || state.context.isExistingUser}
                        scrollToTopOnOpen
                    >
                        <ProfileStep onProfileUpdateComplete={() => send('PROFILE_UPDATE_COMPLETE')} />
                    </StepItem>
                );
            case CartStep.ACTIVITIES:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.activitiesStepTitle}
                        description={content.activitiesStepDescription}
                        open={showStep === CartStep.ACTIVITIES}
                        onToggle={() => send('TOGGLE_ACTIVITIES')}
                        completed={currentStep > CartStep.ACTIVITIES}
                        disabled={!state.can('TOGGLE_ACTIVITIES')}
                        scrollToTopOnOpen
                    >
                        <ActivitiesStep
                            onActivitiesComplete={(activities: string[]) =>
                                send({ type: 'ACTIVITIES_UPDATE_COMPLETE', data: activities })
                            }
                        />
                    </StepItem>
                );
            case CartStep.WHOS_COVERED:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.whosCoveredStepTitle}
                        description={content.whosCoveredStepDescription}
                        open={showStep === CartStep.WHOS_COVERED}
                        onToggle={() => send('TOGGLE_PERSONS_INSURED')}
                        completed={currentStep > CartStep.WHOS_COVERED}
                        disabled={!state.can('TOGGLE_PERSONS_INSURED')}
                        scrollToTopOnOpen
                    >
                        <PersonsInsured
                            selectedPersons={state.context.insuredPersons}
                            coverStartDates={state.context.purchaseState?.coverStartDates ?? []}
                            availableInsuredPersonsTypes={state.context.availableInsuredPersonsTypes}
                            products={products}
                            selectedProductOption={selectedProductOption}
                            onPersonsUpdateComplete={(insuredPersons) =>
                                send({
                                    type: 'PERSONS_INSURED_COMPLETE',
                                    data: insuredPersons,
                                })
                            }
                        />
                    </StepItem>
                );

            case CartStep.PAYMENT:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.paymentStepTitle}
                        open={showStep === CartStep.PAYMENT}
                        onToggle={() => send('TOGGLE_PAYMENT')}
                        completed={currentStep > CartStep.PAYMENT}
                        disabled={!state.can('TOGGLE_PAYMENT')}
                        scrollToTopOnOpen
                    >
                        <PaymentStep
                            purchaseState={state.context.purchaseState!}
                            products={state.context.products}
                            productGroups={state.context.productGroups!}
                            coverInformation={allCoverInformation}
                            userTimeZone={userTimeZone}
                            insuredPersons={state.context.insuredPersons}
                            onInvalidCoverSelection={() => send('TOGGLE_PROFILE')}
                            onPaymentComplete={() => send('PAYMENT_COMPLETE')}
                            onGoToPrevStep={() => send('TOGGLE_PAYMENT')}
                            activities={state.context.activities}
                        />
                    </StepItem>
                );

            default:
                return null;
        }
    };

    return (
        <Layout banner={<CreditBanner />}>
            <StepProgressBar steps={steps} currentStep={currentStep} layoutHasBanner={layoutHasBanner} />
            <h1 className="guest-cart__header">{content.header}</h1>
            {steps.map(renderStepItem)}
        </Layout>
    );
};

export default requireFlags(withContent(Cart, contentMap, cartContent));
