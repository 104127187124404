import { assign } from 'xstate';
import { ProductOption } from '../../../../business-logic/models/ProductGroupsResponse';
import { Destination } from '../../../../business-logic/models/CoverConfig';
import { SelectDestinationsMachineContextTypes } from '../context/selectDestinationsMachineContext';
import { Typegen0 } from '../selectDestinationsMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setChosenDestinations = assign<
    SelectDestinationsMachineContextTypes,
    { type: EventsCausingActions['setChosenDestinations']; data: string[] }
>({
    chosenDestinations: (_, event) => event.data,
}) as any;

export const updateMachineContextFromGlobalContext = assign<
    SelectDestinationsMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            selectedProductOption: ProductOption;
            destinations: Destination[];
        };
    }
>({
    availableDestinations: (ctx, event) => event.data.destinations,
    selectedProductOption: (ctx, event) => event.data.selectedProductOption,
}) as any;
