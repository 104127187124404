import classNames from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Analytics from '../../../../analytics/Analytics';
import blueArrow from '../../../../assets/images/arrow-left-blue.svg';
import dayPassInverse from '../../../../assets/images/cover/day-pass-inverse.svg';
import dayPass from '../../../../assets/images/cover/day-pass.svg';
import SlideInCoverTerms from '../../../../components/slide-in-cover-terms/SlideInCoverTerms';
import useCoverInformation from '../../../../hooks/useCoverInformation';
import CoverCode from '../../../../utils/constants/CoverCode';
import CoverPaymentStatus from '../../../../utils/constants/CoverPaymentStatus';
import CoverStatus from '../../../../utils/constants/CoverStatus';
import DateFormat from '../../../../utils/constants/DateFormat';
import PageTitles from '../../../../utils/constants/PageTitles';
import formatISO8601DateTime from '../../../../utils/formatISO8601DateTime';
import getTimezone from '../../../../utils/getTimezone';
import { Route } from '../../../../utils/Routes';
import CancelCoverDrawer from '../../../../components/cancel-cover-drawer/CancelCoverDrawer';
import { PolicyItem } from '../../../../business-logic/models/PoliciesResponse';

import './ManageCoverCard.scss';

export interface ManageCoverCardProp {
    status: CoverStatus;
    paymentStatus?: CoverPaymentStatus;
    policy: PolicyItem;
    coverCode: string;
    title: string;
    insuredPersonName: string | null;
    startTime: string;
    endTime: string;
    acceptPaymentUntil?: string | null;
    renewalTime: string | null;
    destinations?: string[];
    pdsVersion: string;
    timeZone: string;
    goToBillingCenter: () => void;
    canCancel: boolean;
    refreshCovers: (personId: string) => void;
    className?: string;
    isPurchasedByAlias?: boolean;
    purchaserName?: string | null;
}

const ManageCoverCard = ({
    status,
    policy,
    paymentStatus,
    coverCode,
    title,
    insuredPersonName,
    startTime,
    endTime,
    acceptPaymentUntil,
    renewalTime,
    destinations,
    pdsVersion,
    timeZone,
    isPurchasedByAlias,
    purchaserName,
    goToBillingCenter,
    canCancel = false,
    refreshCovers,
    className,
}: ManageCoverCardProp) => {
    const history = useHistory();
    const defaultDate = formatISO8601DateTime(startTime, timeZone, DateFormat.DEFAULT);
    const coverInformation = useCoverInformation(coverCode, pdsVersion);

    const pageName = PageTitles[history?.location.pathname as Route];
    const [coverToCancel, setCoverToCancel] = useState<PolicyItem | null>(null);
    const [selectedCoverForBenefits, setSelectedCover] = useState<keyof typeof CoverCode | null>(null);
    const localEndTime =
        paymentStatus === CoverPaymentStatus.FAILED && status !== CoverStatus.INACTIVE && acceptPaymentUntil != null
            ? acceptPaymentUntil
            : endTime;

    return (
        <>
            <button
                type="button"
                className={classNames(
                    'manage-cover-card',
                    'button',
                    status === CoverStatus.INACTIVE && 'manage-cover-card--inactive',
                    className,
                )}
                title={'Click to see cover benefits' + (canCancel ? ' or to cancel cover' : '')}
                onClick={() => {
                    setSelectedCover((coverCode as CoverCode) ?? null);
                    Analytics.trackBenefitsDrawerViewed(pageName, coverCode);
                }}
            >
                <div className="manage-cover-card__header">
                    <div className="manage-cover-card__header__wrapper">
                        <div className="manage-cover-card__header__content">
                            <img
                                src={
                                    status === CoverStatus.INACTIVE
                                        ? coverInformation?.inverseIcon || dayPassInverse
                                        : coverInformation?.icon || dayPass
                                }
                                alt=""
                                className="manage-cover-card__header__content__icon"
                            />
                            <span className="manage-cover-card__header__content__text">
                                {coverInformation?.name || title}
                                {insuredPersonName != null && (
                                    <span className="manage-cover-card__header__content__text__name">
                                        {' '}
                                        - for {insuredPersonName}
                                    </span>
                                )}
                            </span>
                        </div>
                        {(status === CoverStatus.ACTIVE || status === CoverStatus.ENDING) && (
                            <div className="manage-cover-card__header__status">
                                <span className="manage-cover-card__header__status__label">Active</span>
                            </div>
                        )}
                    </div>

                    <div
                        className="manage-cover-card__header__button"
                        title="See insurance benefits and or cancellation options"
                    >
                        <div className="button--drawer-link__icon">
                            <img src={blueArrow} alt="" className="button--drawer-link__icon__arrow" />
                        </div>
                    </div>
                </div>
                {status !== CoverStatus.ENDING && (
                    <div className="manage-cover-card__date">
                        <span
                            className={classNames(
                                'manage-cover-card__date__label',
                                renewalTime && 'manage-cover-card__date__label--wide',
                            )}
                        >
                            {status === CoverStatus.INACTIVE ? 'Started:' : 'Starts:'}
                        </span>
                        <span className="manage-cover-card__date__item">
                            {formatISO8601DateTime(startTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                        </span>
                    </div>
                )}

                {renewalTime && status !== CoverStatus.ENDING && (
                    <div className="manage-cover-card__date">
                        <span className="manage-cover-card__date__label manage-cover-card__date__label--wide">
                            Next payment:
                        </span>
                        <span className="manage-cover-card__date__item">
                            {formatISO8601DateTime(renewalTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                        </span>
                    </div>
                )}
                {!renewalTime && status !== CoverStatus.ENDING && (
                    <div className="manage-cover-card__date">
                        <span className="manage-cover-card__date__label">
                            {status === CoverStatus.INACTIVE ? 'Ended:' : 'Ends:'}
                        </span>
                        <span className="manage-cover-card__date__item">
                            {formatISO8601DateTime(endTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                        </span>
                    </div>
                )}
                {status === CoverStatus.ENDING && (
                    <div className="manage-cover-card__date">
                        <span className="manage-cover-card__date__label">Ending:</span>
                        <span className="manage-cover-card__date__item">
                            {formatISO8601DateTime(localEndTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                        </span>
                    </div>
                )}
                {!!destinations && <span className="manage-cover-card__destinations">{destinations.join(', ')}</span>}
                {status === CoverStatus.ENDING && paymentStatus !== CoverPaymentStatus.FAILED && (
                    <span className="manage-cover-card__terms">
                        You can always start cover again after it ends. Just be sure to check the latest PDS in case
                        things have changed.
                    </span>
                )}
                {status !== CoverStatus.INACTIVE && paymentStatus === CoverPaymentStatus.FAILED && (
                    <span className="manage-cover-card__terms">
                        Sorry, your payment’s failed. You’ll need to update your payment details in{' '}
                        <a
                            role="button"
                            className="manage-cover-card__terms__billing-link"
                            onClick={(e) => {
                                if (e && e.stopPropagation) {
                                    e.stopPropagation();
                                    goToBillingCenter();
                                }
                            }}
                        >
                            <strong>Managing Billing and Payments</strong>
                        </a>{' '}
                        and pay any overdue invoice to avoid your cover ending.
                    </span>
                )}
                <div className="manage-cover-card__footer">
                    {isPurchasedByAlias && purchaserName && (
                        <div className="manage-cover-card__footer__row">
                            <small className="manage-cover-card__footer__purchaser">Purchased by {purchaserName}</small>
                        </div>
                    )}
                    <div className="manage-cover-card__footer__row">
                        <small className="manage-cover-card__footer__timezone">
                            Times displayed in {getTimezone(timeZone, defaultDate)}
                        </small>
                        <small className="manage-cover-card__footer__pds">PDS version {pdsVersion}</small>
                    </div>
                </div>
                <SlideInCoverTerms
                    coverCode={coverCode as CoverCode}
                    pdsVersion={pdsVersion}
                    isOpen={selectedCoverForBenefits === coverCode}
                    onOpen={() => {
                        setSelectedCover((coverCode as CoverCode) ?? null);
                    }}
                    onClose={() => setSelectedCover(null)}
                    canCancel={canCancel}
                    initiateCancellation={() => setCoverToCancel(policy)}
                />
            </button>
            <CancelCoverDrawer
                policy={coverToCancel}
                isOpen={!!coverToCancel}
                onClose={() => {
                    setCoverToCancel(null);
                }}
                refreshCovers={() => refreshCovers(policy.mainCover.insuredPersonId ?? '')}
            />
        </>
    );
};

export default ManageCoverCard;
