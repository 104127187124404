import Routes, { Route } from '../utils/Routes';
import { Category, PageEvent } from './Analytics.d';

const PageEvents: Record<Route, PageEvent | null> = {
    [Routes.HOME]: [Category.DASHBOARD, 'Dashboard'],
    [Routes.LOGIN]: [Category.AUTHENTICATION, 'Login'],
    [Routes.FORGOT_PASSWORD]: [Category.AUTHENTICATION, 'Forgot password'],

    // Onboarding
    [Routes.LANDING]: [Category.ONBOARDING, 'Landing page'],
    [Routes.REGISTER]: [Category.ONBOARDING, 'Create account'],

    // Sign up flow
    [Routes.ACTIVITIES]: [Category.SIGNUP, 'Activities'],
    [Routes.YOUR_DETAILS]: [Category.SIGNUP, 'Your details'],

    // Profile
    [Routes.ACCOUNT]: [Category.ACCOUNT, 'Account'],
    [Routes.ACCOUNT_DETAILS]: [Category.ACCOUNT, 'Account details'],
    [Routes.ACCOUNT_PREFERENCES]: [Category.ACCOUNT, 'Preferences'],
    [Routes.ACCOUNT_CERTIFICATE_OF_COVER]: [Category.ACCOUNT, 'Certificate of cover'],
    [Routes.MANAGE_COVER]: [Category.ACCOUNT, 'Manage Cover'],

    // Intermediate states - do not track, mark with null
    [Routes.LOGIN_CALLBACK]: null,
    [Routes.NOT_FOUND]: null,

    [Routes.SELECT_COVER]: [Category.CHECKOUT, 'Select Cover'],
    [Routes.COVER_INFORMATION]: [Category.CHECKOUT, 'Cover Information'],
    [Routes.COVER_DETAILS]: [Category.CHECKOUT, 'Your selected cover'],
    [Routes.SELECT_DATES]: [Category.CHECKOUT, 'Select your dates'],

    [Routes.SELECT_DESTINATIONS]: [Category.CHECKOUT, 'Select Destinations'],
    [Routes.SELECT_STARTING_DESTINATION]: [Category.CHECKOUT, 'Select Starting Destination'],
    [Routes.SELECT_STARTING_REGION]: [Category.CHECKOUT, 'Select Starting Region'],

    [Routes.CART]: [Category.CHECKOUT, 'Cart'],
    [Routes.CART_SUCCESS]: [Category.CHECKOUT, 'Cart Checkout Success'],
    [Routes.PURCHASE_ENTRY]: null,
    [Routes.SELECT_PRODUCT_OPTIONS]: [Category.CHECKOUT, 'Purchase options'],
};

export default PageEvents;
