import React, { ReactNode } from 'react';
import closeBlue from '../../../../assets/images/close-blue.svg';
import editBlue from '../../../../assets/images/icon-edit-blue.svg';
import paymentStrings from '../../../../strings/payments';
import Button from '../../../button/Button';
import Cards from './Cards';

import './SavedCard.scss';

interface SavedCardProps {
    brand: string;
    last4?: string;
    onChange: () => void;
    onCancel: () => void;
    isChangingCardDetails?: boolean;
    cardDetailsComponent?: ReactNode;
}

const SavedCard: React.FC<SavedCardProps> = ({
    brand,
    last4,
    onChange,
    onCancel,
    isChangingCardDetails = false,
    cardDetailsComponent,
}) => (
    <div className="saved-card">
        <div className="saved-card__row">
            <div className="saved-card__card">
                <img src={Cards[brand]?.icon} alt="card" />
                <p className="saved-card__card-number">•••• {last4}</p>
            </div>
            <Button
                variant="link"
                label={isChangingCardDetails ? paymentStrings.cardChangeCancel : paymentStrings.cardChange}
                type="button"
                className="saved-card__btn"
                onClick={isChangingCardDetails ? onCancel : onChange}
                suffixIcon={isChangingCardDetails ? closeBlue : editBlue}
            />
        </div>
        {isChangingCardDetails && cardDetailsComponent}
    </div>
);

export default SavedCard;
