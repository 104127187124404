import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import common from '../../strings/common';
import parseString from '../../utils/parseString';
import { Button, ButtonProps } from '../button/Button';
import Modal from '../modal/Modal';
import { ModalAlert, ModalWithCTAAlert } from './utils/ModalWithCTAAlertTypes';

import './ModalWithCTA.scss';

interface ModalCTAProps {
    onClick: () => void;
    label?: string;
    variant?: ButtonProps['variant'];
    disabled?: boolean;
}

export interface ModalWithCTAProps {
    isOpen: boolean;
    onClose?: () => void;
    textContent?: string | ReactNode;
    primaryCTA: ModalCTAProps;
    secondaryCTA?: ModalCTAProps;
    tertiaryCTA?: ModalCTAProps;
    icon?: ModalAlert;
    className?: string;
    variant?: 'pop-up' | 'slide-in' | 'slide-up' | 'slide-to-center';
    title?: string;
    arrangeButtons?: 'row' | 'column';
    universalClose?: boolean;
    size?: 'small' | 'large';
}

const ModalWithCTA: FC<ModalWithCTAProps> = ({
    isOpen,
    onClose,
    textContent,
    primaryCTA,
    secondaryCTA,
    tertiaryCTA,
    icon,
    className,
    variant,
    title,
    arrangeButtons,
    universalClose = false,
    size = 'small',
}) => {
    return (
        <Modal
            variant={variant}
            isOpen={isOpen}
            onClose={onClose}
            parentClass="#root"
            className={classNames(className, 'modal-with-cta')}
            size={size}
            universalClose={universalClose}
        >
            <div className="modal-with-cta__icon">
                <img src={ModalWithCTAAlert[icon || 'ALERT']} alt="" />
            </div>
            <h2 className="modal-with-cta__heading">{title}</h2>
            <div className={classNames('modal-with-cta__text')}>{textContent}</div>
            <div className="modal-with-cta__buttons">
                <div
                    className={classNames('modal-with-cta__btn-group', {
                        'modal-with-cta__btn-group--row': !!secondaryCTA && arrangeButtons === 'row',
                        'modal-with-cta__btn-group--col': !!secondaryCTA && arrangeButtons === 'column',
                    })}
                >
                    <Button
                        variant={primaryCTA.variant || 'primary'}
                        label={primaryCTA?.label ?? common.yes}
                        width="full"
                        onClick={primaryCTA.onClick}
                        disabled={primaryCTA.disabled}
                    />
                    {secondaryCTA && (
                        <Button
                            className="modal-with-cta__btn-secondary"
                            variant={secondaryCTA.variant || 'secondary'}
                            label={secondaryCTA?.label ?? common.no}
                            width="full"
                            onClick={secondaryCTA.onClick}
                            disabled={secondaryCTA.disabled}
                        />
                    )}
                </div>
                {tertiaryCTA && (
                    <Button
                        variant={tertiaryCTA.variant || 'link'}
                        label={parseString(tertiaryCTA?.label ?? '')}
                        width="full"
                        onClick={tertiaryCTA.onClick}
                        disabled={tertiaryCTA.disabled}
                        className="modal-with-cta__btn--tertiary"
                    />
                )}
            </div>
        </Modal>
    );
};

export default ModalWithCTA;
