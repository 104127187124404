import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import useScreenSize from './useScreenSize';
import closeIcon from '../../assets/images/close.svg';
import './Modal.scss';

export interface ModalDialogProps {
    isOpen: boolean;
    onClose?: () => void;
    parentClass: string;
    variant?: 'pop-up' | 'slide-in' | 'slide-up' | 'slide-to-center';
    children?: React.ReactNode;
    className?: string;
    size?: 'small' | 'large'; // only applicable to pop-up variant
    universalClose?: boolean;
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
    isOpen = false,
    onClose,
    parentClass,
    variant = 'pop-up',
    children,
    className,
    size = 'small',
    universalClose = false,
}) => {
    // binding modal to appElement (https://reactcommunity.org/react-modal/accessibility/)
    useEffect(() => Modal.setAppElement(parentClass));
    const { width } = useScreenSize();

    const getModalTimeOutMs = useCallback(() => {
        const DEFAULT_TIMEOUT_MS = 500;
        const isSlideIn = variant === 'slide-in';
        const isSlideUp = variant === 'slide-up' && width <= 480;
        return isSlideIn || isSlideUp ? DEFAULT_TIMEOUT_MS : undefined;
    }, [width, variant]);

    return (
        <Modal
            ariaHideApp={false}
            closeTimeoutMS={getModalTimeOutMs()}
            isOpen={isOpen}
            onRequestClose={onClose}
            className={classNames(
                'modal',
                variant === 'slide-in' && 'modal--slide-in',
                variant === 'slide-up' && 'modal--slide-up',
                variant === 'slide-to-center' && 'modal--slide-to-center',
                className,
                {
                    'modal--small': size === 'small',
                    'modal--large': size === 'large',
                },
            )}
            overlayClassName="modal-overlay"
        >
            {universalClose && !!onClose && (
                <button type="button" className="modal__close-button" aria-label="Close Modal" onClick={onClose}>
                    <img src={closeIcon} alt="Close" />
                </button>
            )}
            {children}
        </Modal>
    );
};

export default ModalDialog;
