import classNames from 'classnames';
import { FC, ReactNode, useRef } from 'react';
import useOnScreen from '../../hooks/useOnScreen';

import './Sticky.scss';

interface StickyProps {
    variant?: 'bottom' | 'top';
    background?: 'bondi' | 'harbour';
    children: ReactNode;
    className?: string;
}

const Sticky: FC<StickyProps> = ({ variant = 'bottom', background = 'bondi', children, className }) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const { isOnScreen } = useOnScreen(elementRef);

    return (
        <div
            className={classNames(
                variant === 'bottom' && 'sticky--bottom',
                variant === 'top' && 'sticky--top',
                background === 'harbour' && 'sticky--bg-Harbour',
                isOnScreen && 'sticky--stuck',
                className,
            )}
            ref={elementRef}
        >
            {children}
        </div>
    );
};

export default Sticky;
