import classNames from 'classnames';
import { FC, useRef, useState } from 'react';
import blueChevron from '../../../../assets/images/icon-chevron-down-blue.svg';
import personIcon from '../../../../assets/images/icon-person.svg';
import { usePersons } from '../../../../business-logic/context-provider/persons-provider/PersonsContext';
import { useUser } from '../../../../business-logic/context-provider/user-context/UserContext';
import RadioButton from '../../../../components/form/radio-button/RadioButton';

import './ManageCoverPersonSelector.scss';

interface ManageCoverPersonSelectorProps {
    onSelectPerson: (personId: string) => void;
    selectedPersonId: string;
}

const ManageCoverPersonSelector: FC<ManageCoverPersonSelectorProps> = ({ selectedPersonId, onSelectPerson }) => {
    const { userDetails } = useUser();
    const { insurablePersons, adults, dependants } = usePersons();
    const [isExpanded, setIsExpanded] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const getSelectedLabel = () => {
        if (selectedPersonId.length === 0) {
            return 'Show all';
        } else if (selectedPersonId === userDetails.personId!) {
            return 'Your cover';
        } else {
            return `${insurablePersons.find((x) => x.personId === selectedPersonId)?.firstName}'s cover`;
        }
    };

    return (
        <div className="manage-cover-v3__selector">
            <button
                type="button"
                disabled={insurablePersons.length === 0}
                className="manage-cover-v3__selector__expander"
                onClick={() => {
                    if (insurablePersons.length > 0) {
                        toggleExpand();
                    }
                }}
                onKeyDown={() => {
                    onSelectPerson(userDetails.personId!);
                }}
            >
                <div className="manage-cover-v3__selector__expander__label">
                    <img src={personIcon} alt="" className="manage-cover-v3__selector__expander__icon" />
                    <span>{getSelectedLabel()}</span>
                </div>
                {insurablePersons.length > 0 && (
                    <div className="manage-cover-v3__selector__expander__action">
                        <span className="manage-cover-v3__selector__expander__action__label">Filter</span>
                        <img
                            src={blueChevron}
                            className={classNames(
                                'manage-cover-v3__selector__expander__action__icon',
                                isExpanded && 'manage-cover-v3__selector__expander__action__icon--selected',
                            )}
                            alt=""
                        />
                    </div>
                )}
            </button>
            {!!insurablePersons.length && (
                <div
                    className={classNames('manage-cover-v3__selector__list')}
                    ref={listRef}
                    style={{
                        maxHeight: isExpanded ? `${listRef?.current?.scrollHeight}px` : '0px',
                    }}
                >
                    <hr className="manage-cover-v3__selector__divider" />
                    <div className="manage-cover-v3__selector__list__wrapper">
                        <RadioButton
                            id={'show-all'}
                            name={'show-all'}
                            checked={selectedPersonId === ''}
                            className="manage-cover-v3__selector__option"
                            label={'Show all'}
                            onChange={() => {
                                onSelectPerson('show-all');
                                toggleExpand();
                            }}
                        />
                        <RadioButton
                            id={userDetails.personId ?? ''}
                            name={'account-holder'}
                            checked={selectedPersonId === userDetails.personId}
                            className="manage-cover-v3__selector__option"
                            label={'Your cover'}
                            onChange={() => {
                                onSelectPerson(userDetails.personId!);
                                toggleExpand();
                            }}
                        />
                    </div>

                    {adults.length > 0 && (
                        <>
                            <hr className="manage-cover-v3__selector__divider" />
                            <div className="manage-cover-v3__selector__list__wrapper">
                                <h4 className="manage-cover-v3__selector__subheading">Adults</h4>
                                {adults
                                    .sort((a, b) => a.firstName!.localeCompare(b.firstName!))
                                    .map((person) => (
                                        <RadioButton
                                            key={person.personId}
                                            id={person.personId}
                                            name={person.personId}
                                            checked={selectedPersonId === person.personId}
                                            className="manage-cover-v3__selector__option"
                                            label={`${person.firstName}\'s`}
                                            onChange={() => {
                                                onSelectPerson(person.personId!);
                                                toggleExpand();
                                            }}
                                        />
                                    ))}
                            </div>
                        </>
                    )}
                    {dependants.length > 0 && (
                        <>
                            <hr className="manage-cover-v3__selector__divider" />
                            <div className="manage-cover-v3__selector__list__wrapper">
                                <h4 className="manage-cover-v3__selector__subheading">Kids</h4>
                                {dependants
                                    .sort((a, b) => a.firstName!.localeCompare(b.firstName!))
                                    .map((person) => (
                                        <RadioButton
                                            key={person.personId ?? ''}
                                            id={person.personId ?? ''}
                                            name={person.personId ?? ''}
                                            checked={selectedPersonId === person.personId}
                                            className="manage-cover-v3__selector__option"
                                            label={`${person.firstName}\'s`}
                                            onChange={() => {
                                                onSelectPerson(person.personId!);
                                                toggleExpand();
                                            }}
                                        />
                                    ))}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ManageCoverPersonSelector;
