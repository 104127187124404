import React, { ButtonHTMLAttributes, MouseEventHandler, ReactNode, useState } from 'react';
import classNames from 'classnames';
import blueArrow from '../../assets/images/arrow-left-blue.svg';

import './Button.scss';

export interface ButtonProps {
    variant?: 'primary' | 'secondary' | 'link' | 'primary-invert' | 'secondary-invert' | 'drawer-link';
    width?: 'full' | 'standard';
    size?: 'large' | 'small';
    label: string | ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: 'submit' | 'reset' | 'button';
    prefixIcon?: string;
    suffixIcon?: string;
}

export const Button: React.FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
    variant = 'primary',
    width = 'standard',
    label,
    disabled,
    className,
    type = 'button',
    size = 'large',
    prefixIcon,
    suffixIcon,
    ...props
}) => {
    return (
        <button
            // button types are already restricted by TS
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={classNames(
                'button',
                `button--${variant}`,
                disabled && 'disabled',
                `button--${width}`,
                `button--${size}`,
                className,
            )}
            disabled={disabled}
            aria-disabled={disabled}
            {...props}
        >
            {variant !== 'drawer-link' && prefixIcon && (
                <img src={prefixIcon} className="button__icon--prefix" alt="" />
            )}
            <span>{label}</span>
            {variant !== 'drawer-link' && suffixIcon && (
                <img src={suffixIcon} className="button__icon--suffix" alt="" />
            )}
            {variant === 'drawer-link' && (
                <div className="button--drawer-link__icon">
                    {suffixIcon ? (
                        <img src={suffixIcon} alt="" className="button--drawer-link__icon--standard" />
                    ) : (
                        <img src={blueArrow} alt="" className="button--drawer-link__icon__arrow" />
                    )}
                </div>
            )}
        </button>
    );
};

export default Button;
