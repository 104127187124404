import RadioButton from '../radio-button/RadioButton';
import { RadioCheckboxProps } from '../RadioCheckboxProps';
import Checkbox from '../checkbox/Checkbox';
import classNames from 'classnames';

import './OptionSelector.scss';

interface OptionSelectorConfig {
    icon?: string;
    multiSelect?: boolean; // true = multi select (checkbox), false = single select (radio)
}

export type OptionSelectorProp = RadioCheckboxProps & OptionSelectorConfig;

const OptionSelector = ({
    id,
    name,
    label,
    checked,
    className,
    onChange,
    onFocus,
    disabled,
    isError,
    icon,
    multiSelect = false, // single select by default
}: OptionSelectorProp) => {
    if (multiSelect) {
        return (
            <Checkbox
                id={id}
                name={name}
                className={classNames(
                    'option-selector',
                    'option-selector--checkbox',
                    {
                        'option-selector--icon': !!icon,
                        disabled,
                        isError,
                        checked,
                    },
                    className,
                )}
                label={
                    !!icon ? (
                        <>
                            <img src={icon} alt={label} className="option-selector__checkbox__icon" />
                            <span className="option-selector__checkbox__icon__label">{label}</span>
                        </>
                    ) : (
                        label
                    )
                }
                onFocus={onFocus}
                checked={checked}
                disabled={disabled}
                isError={isError}
                onChange={onChange}
            />
        );
    }
    return (
        <RadioButton
            id={id}
            name={name}
            className={classNames('option-selector', 'option-selector--radio', className)}
            label={label}
            onFocus={onFocus}
            checked={checked}
            disabled={disabled}
            icon={icon}
            isError={isError}
            onChange={onChange}
        />
    );
};

export default OptionSelector;
