import { CartRequestItem } from '../business-logic/models/Cart';
import ProductGroupsResponse from '../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../business-logic/models/ProductResponse';
import { InsuredPerson, InsuredPersonType } from '../screens/cart/cart-machine/context/cartMachineContext';
import { PurchaseState } from '../types/PurchaseState';
import getScheduledStartTime from './getScheduledStartTime';

const getCartRequestItems = (
    purchaseState: PurchaseState,
    products: ProductResponse[],
    productGroups: ProductGroupsResponse,
    userTimeZone: string,
    insuredPersons: InsuredPerson[],
): CartRequestItem[] => {
    // Get cover codes in selected product option
    const selectedProductGroup = productGroups.find(
        (group) => group.id.toLowerCase() === purchaseState?.selectedProductGrouping?.toLowerCase(),
    );
    const coverCodes = selectedProductGroup?.options.find((x) =>
        x.coverCodes.includes(purchaseState.selectedProductOption?.representedByCoverCode ?? ''),
    )?.coverCodes;

    const dependantsToBeInsured = insuredPersons.filter((p) => p.type === InsuredPersonType.DEPENDANT);
    const nonDependantsToBeInsured = insuredPersons.filter((p) => p.type !== InsuredPersonType.DEPENDANT);

    // For each cover code to add to cart
    return coverCodes!.reduce<CartRequestItem[]>((acc, cur) => {
        const coverCodeProduct = products.find((product) => product.productSpec.mainCoverType.coverCode === cur);

        // Check if requiredInsuredPersonRelationship is 'Dependant'
        const isDependantCover =
            coverCodeProduct?.productSpec.mainCoverType.requiredInsuredPersonRelationship?.toLowerCase() ===
            InsuredPersonType.DEPENDANT;

        // If dependant cover
        if (isDependantCover) {
            // For each dependant
            dependantsToBeInsured.forEach((dependant) => {
                // For each start date
                purchaseState.coverStartDates.forEach((startDate) => {
                    acc.push({
                        coverCode: cur,
                        scheduledStartTime: getScheduledStartTime(
                            startDate,
                            purchaseState.destination?.timezone || userTimeZone.toString(),
                        ),
                        personId: dependant.personId,
                        insurancePolicyId: null, // TODO: check for extras cover to return policyID, otherwise return null
                        insuranceProductId: coverCodeProduct.insuranceProductId || '',
                        coverTypeId: coverCodeProduct.productSpec.mainCoverTypeId, // TODO: Assuming no extra covers for now
                        timeZone: purchaseState.destination?.timezone || undefined,
                    });
                });
            });
        }

        // If not dependant cover
        if (!isDependantCover) {
            // For each non dependant
            nonDependantsToBeInsured.forEach((person) => {
                // For each start date
                purchaseState.coverStartDates.forEach((startDate) => {
                    acc.push({
                        coverCode: cur,
                        scheduledStartTime: getScheduledStartTime(
                            startDate,
                            purchaseState.destination?.timezone || userTimeZone.toString(),
                        ),
                        personId: person.personId,
                        insurancePolicyId: null, // TODO: check for extras cover to return policyID, otherwise return null
                        insuranceProductId: coverCodeProduct!.insuranceProductId || '',
                        coverTypeId: coverCodeProduct!.productSpec.mainCoverTypeId, // TODO: Assuming no extra covers for now
                        timeZone: purchaseState.destination?.timezone || undefined,
                    });
                });
            });
        }

        return acc;
    }, []);
};

export default getCartRequestItems;
