import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import Analytics from '../../analytics/Analytics';
import { usePersons } from '../../business-logic/context-provider/persons-provider/PersonsContext';
import { PolicyItem } from '../../business-logic/models/PoliciesResponse';
import DashboardV2 from '../../components/dashboard/DashboardV2';
import { Tabs } from '../../components/tabs/Tabs';
import manageCoverContent from '../../content/ui/screens/manage-cover/manageCoverContent';
import withContent from '../../hoc/with-content/withContent';
import CoverService from '../../services/cover-service/CoverService';
import SideNavigation from '../home/components/SideNavigation';
import ManageCoverPersonSelector from './components/manage-cover-person-selector/ManageCoverPersonSelector';
import ManageCoverTabList from './components/ManageCoverTabList';

import './ManageCoverV3.scss';

const contentMap = {
    heading: 'ui.heading',
    scheduledCoverLabel: 'ui.scheduledCoverLabel',
    coverHistoryLabel: 'ui.coverHistoryLabel',
};

interface ManageCoverV3Props {
    content: Record<keyof typeof contentMap, string>;
}

const ManageCoverV3: React.FC<ManageCoverV3Props> = ({ content }) => {
    const { insurablePersons } = usePersons();
    const { authState } = useOktaAuth();
    const [selectedPersonId, setSelectedPersonId] = useState<string>('');
    const [scheduledPolicies, setScheduledPolicies] = useState<PolicyItem[]>([]);
    const [historicalPolicies, setHistoricalPolicies] = useState<PolicyItem[]>([]);
    const [canLoadMoreHistorical, setCanLoadMoreHistorical] = useState<boolean>(true);
    const [canLoadMoreScheduled, setCanLoadMoreScheduled] = useState<boolean>(true);
    const [scheduledPage, setScheduledPage] = useState<number>(1);
    const [historicalPage, setHistoricalPage] = useState<number>(1);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // Do not show selectors in either tab when
    // no adults and no dependants registered
    const hidePersonSelector = insurablePersons.length === 0;

    // Get all historical/scheduled covers for selected person
    // for the first page of results (max 10 covers)
    const getAllCovers = async (personId: string) => {
        try {
            setLoading(true);
            // RESET to account for transition
            setScheduledPolicies([]);
            setHistoricalPolicies([]);
            const response = await Promise.all([
                CoverService.getScheduledCovers({
                    accessToken: authState?.accessToken?.accessToken,
                    queryParams: {
                        personId,
                        page: 1,
                    },
                }),
                CoverService.getHistoricalCovers({
                    accessToken: authState?.accessToken?.accessToken,
                    queryParams: {
                        personId,
                        page: 1,
                    },
                }),
            ]);
            if (response[0] && response[1]) {
                setScheduledPolicies(response[0]);
                setHistoricalPolicies(response[1]);

                setScheduledPage(1);
                setHistoricalPage(1);
                setCanLoadMoreScheduled(!!response[0].length);
                setCanLoadMoreHistorical(!!response[1].length);
                setError(false);
            }
        } catch (err) {
            setError(true);
            console.error(`Error getting all covers for person id ${selectedPersonId} error: ${err}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        // on first page load, gets accountholder's covers
        getAllCovers(selectedPersonId);
    }, []);

    const toggleSelectedPerson = (personId: string) => {
        setSelectedPersonId(personId == 'show-all' ? '' : personId);
        // Reset
        setScheduledPage(1);
        setHistoricalPage(1);
        setCanLoadMoreHistorical(true);
        setCanLoadMoreScheduled(true);
        getAllCovers(personId == 'show-all' ? '' : personId);
    };

    const refreshCovers = async () => {
        getAllCovers(selectedPersonId);
    };

    const loadMoreScheduledPolicies = async () => {
        try {
            const response = await CoverService.getScheduledCovers({
                accessToken: authState?.accessToken?.accessToken,
                queryParams: {
                    personId: selectedPersonId,
                    page: scheduledPage + 1,
                },
            });
            if (response && response.length > 0) {
                setScheduledPolicies((prev) => [...prev, ...response]);

                setScheduledPage((prev) => prev + 1);
                setCanLoadMoreScheduled(true);
            }
            if (response && response.length === 0) {
                setCanLoadMoreScheduled(false);
            }
            setError(false);
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const loadMoreHistoricalPolicies = async () => {
        try {
            const response = await CoverService.getHistoricalCovers({
                accessToken: authState?.accessToken?.accessToken,
                queryParams: {
                    personId: selectedPersonId,
                    page: historicalPage + 1,
                },
            });
            if (response && response.length > 0) {
                setHistoricalPolicies((prev) => [...prev, ...response]);

                setHistoricalPage((prev) => prev + 1);
                setCanLoadMoreHistorical(true);
            }
            if (response && response.length === 0) {
                setCanLoadMoreHistorical(false);
            }
            setError(false);
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardV2 title={content.heading}>
            <div className="manage-cover-v3 dashboardV2__main__content">
                <div className="manage-cover-v3__content">
                    <Tabs
                        className="manage-cover-v3__tabs"
                        onTabChange={(selectedTabIndex) => {
                            Analytics.trackTabChange('Manage Cover', {
                                tab: selectedTabIndex === 0 ? 'Scheduled Cover' : 'Cover History',
                            });
                        }}
                        initialTab={null}
                        tabs={[
                            {
                                id: 'scheduled-cover',
                                label: content.scheduledCoverLabel,
                                content: (
                                    <div>
                                        {!hidePersonSelector && (
                                            <ManageCoverPersonSelector
                                                selectedPersonId={selectedPersonId}
                                                onSelectPerson={(personId) => {
                                                    toggleSelectedPerson(personId);
                                                }}
                                            />
                                        )}
                                        <ManageCoverTabList
                                            policies={scheduledPolicies}
                                            canLoadMore={canLoadMoreScheduled}
                                            loadMore={loadMoreScheduledPolicies}
                                            loading={loading}
                                            error={error}
                                            isActive
                                            selectedPersonId={selectedPersonId}
                                            refreshCovers={() => {
                                                getAllCovers(selectedPersonId);
                                            }}
                                        />
                                    </div>
                                ),
                            },
                            {
                                id: 'cover-history',
                                label: content.coverHistoryLabel,
                                content: (
                                    <div>
                                        {!hidePersonSelector && (
                                            <ManageCoverPersonSelector
                                                selectedPersonId={selectedPersonId}
                                                onSelectPerson={(personId) => toggleSelectedPerson(personId)}
                                            />
                                        )}
                                        <ManageCoverTabList
                                            policies={historicalPolicies}
                                            canLoadMore={canLoadMoreHistorical}
                                            loadMore={loadMoreHistoricalPolicies}
                                            loading={loading}
                                            error={error}
                                            selectedPersonId={selectedPersonId}
                                            refreshCovers={refreshCovers}
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <SideNavigation className="dashboardV2__main__side" />
        </DashboardV2>
    );
};

export default withContent(ManageCoverV3, contentMap, manageCoverContent);
