import LocalizedStrings from 'react-localization';

const groupActiveRegisterModal = new LocalizedStrings({
    en: {
        header: 'Register your Event Code',
        description:
            'Download our mobile app to register your Event Code, manage your claims, purchase more cover and manage your account.',
        appStoreActionText: 'Download on the',
        appStoreStoreName: 'App Store',
        playStoreActionText: 'Get it on',
        playStoreStoreName: 'Google Play',
        cta: 'Back to dashboard',
    },
});

export default groupActiveRegisterModal;
