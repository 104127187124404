import { datadogRum } from '@datadog/browser-rum';
import { isAxiosError } from 'axios';
import { Alias, AliasResponse } from '../../business-logic/models/Alias';
import { CreateAliasRequest } from '../../business-logic/models/CreateAlias';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios409Error from '../utils/isAxios409Error';
import toHeaders from '../utils/toHeaders';
import withRetriesAsync from '../utils/withRetriesAsync';
import transformAliasResponse from './transformAliasResponse';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const createAlias = async ({
    accessToken,
    alias,
    axios,
}: {
    accessToken: string;
    alias: CreateAliasRequest;
} & ServiceArgs): Promise<Alias> => {
    try {
        const { data } = await withRetriesAsync(
            () => axios.post<AliasResponse>(`${baseApiPath}/api/v1/person/alias`, alias, toHeaders(accessToken)),
            undefined,
            (error) => {
                if ((isAxiosError(error) && error.status === 422) || isAxios409Error(error)) {
                    // Do not retry 422 or 409 errors
                    return false;
                }
                return true;
            },
        );
        return transformAliasResponse(data);
    } catch (err) {
        if (isAxiosError(err) && err.status === 422) {
            datadogRum.addError(err, {
                serviceName: 'createAlias',
                description: 'createAlias conflict error: cannot create an alias using account holder email',
            });
        }
        // If 409, log error and retry fetch
        if (isAxios409Error(err)) {
            datadogRum.addError(err, {
                serviceName: 'createAlias',
                description: 'createAlias conflict error: cannot create an alias using non-unique email',
            });
        }
        throw err;
    }
};

export default asService(createAlias);
