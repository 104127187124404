import { useOktaAuth } from '@okta/okta-react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { LoadingSpinnerOverlay } from '../../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import PersonService from '../../../services/person-service/PersonService';
import ldWaitForInitialization from '../../../utils/third-party-dependencies/ldWaitForInitialization';
import { Alias } from '../../models/Alias';
import UserDetails from '../../models/UserDetails';
import { useUser } from '../user-context';
import { initialState, IPersonsContext, PersonsDispatchAction, PersonsState } from './PersonsContextTypes';

const env = import.meta.env.VITE_NETLIFY_ENV;

export const PersonsContext = createContext<any>(initialState);

export const usePersons = (): IPersonsContext => {
    const context: IPersonsContext = useContext(PersonsContext);
    if (typeof context === 'undefined') {
        throw new Error('Persons Context must be used within the PersonsProvider');
    }
    return context;
};

const reducer = (state: PersonsState, action: PersonsDispatchAction): PersonsState => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_INITIALISED':
            return { ...state, initialised: action.payload };
        case 'SET_DEPENDANTS':
            return { ...state, dependants: action.payload };
        case 'SET_ADULTS':
            return { ...state, adults: action.payload };
        case 'ADD_DEPENDANT':
            return {
                ...state,
                dependants: [...state.dependants.filter((d) => d.personId !== action.payload.personId), action.payload],
            };
        default:
            throw new Error();
    }
};

export const PersonsProvider: React.FC = (props) => {
    const { authState } = useOktaAuth();
    const ldClient = useLDClient();
    const { userDetails } = useUser();
    const { multiAdultPurchase } = useFlags();

    const [state, dispatch] = useReducer(reducer, initialState);

    // ***************** Setters *****************
    const setLoading = (loading: boolean) => {
        dispatch({ type: 'SET_LOADING', payload: loading });
    };

    const setInitialised = (initialised: boolean) => {
        dispatch({ type: 'SET_INITIALISED', payload: initialised });
    };

    const setDependants = (dependants: UserDetails[]) => {
        dispatch({ type: 'SET_DEPENDANTS', payload: dependants });
    };

    const setAdults = (adults: Alias[]) => {
        dispatch({ type: 'SET_ADULTS', payload: adults });
    };

    const addDependants = (dependants: UserDetails) => {
        dispatch({ type: 'ADD_DEPENDANT', payload: dependants });
    };

    // ***************** Fetch and set *****************

    const fetchAndSetDependants = useCallback(async (accessToken: string): Promise<void> => {
        const { dependants } = await PersonService.getDependants({ accessToken });

        setDependants(dependants);
    }, []);

    const fetchAndSetAdults = useCallback(async (accessToken: string): Promise<void> => {
        const response = await PersonService.getAdults({ accessToken });

        setAdults(response);
    }, []);

    // Refresh adults in background - no loader
    const refreshAdultsInBackground = async () => {
        try {
            const accessToken = authState?.accessToken?.accessToken;
            if (typeof accessToken === 'undefined') throw new Error('refreshAdults: Access token required');

            await fetchAndSetAdults(accessToken);
        } catch (e) {
            // TODO - handle error
            console.error(e);
        }
    };

    // ***************** Initial fetch for user data *****************

    const initialisePersonsContext = useCallback(async () => {
        try {
            const accessToken = authState?.accessToken?.accessToken;
            if (typeof accessToken === 'undefined') throw new Error('initialisePersonsContext: Access token required');
            // Display loader
            setInitialised(false);
            setLoading(true);
            if (multiAdultPurchase) {
                await fetchAndSetAdults(accessToken);
            }
            await fetchAndSetDependants(accessToken);
        } catch (e) {
            // TODO - handle error
            console.error(e);
        } finally {
            setLoading(false);
            setInitialised(true);
        }
    }, [authState?.accessToken?.accessToken, fetchAndSetAdults, fetchAndSetDependants, multiAdultPurchase]);

    // ***************** Side effects *****************

    // Purely for debugging
    useEffect(() => {
        if (env === 'dev' || env === 'test') {
            console.log('STATE', state);
        }
    }, [state]);

    // When flags are ready go and fetch dependants and adults
    useEffect(() => {
        if (ldClient && !state.initialised) {
            ldWaitForInitialization(ldClient).then(() => {
                // only get adults and dependants if customer is registered in database
                if (authState?.isAuthenticated && userDetails.customerNumber) {
                    initialisePersonsContext();
                }
            });
        }
    }, [
        initialisePersonsContext,
        userDetails.customerNumber,
        authState?.isAuthenticated,
        ldClient,
        state.initialised,
        userDetails,
        multiAdultPurchase,
    ]);

    const insurablePersons = useMemo(() => [...state.dependants, ...state.adults], [state.adults, state.dependants]);

    // ***************** Render *****************

    const value: IPersonsContext = {
        ...state,
        setDependants,
        setAdults,
        addDependants,
        insurablePersons,
        refreshAdultsInBackground,
    };

    const { children, ...passThroughProps } = props;

    return (
        <PersonsContext.Provider value={value} {...passThroughProps}>
            {state.loading && <LoadingSpinnerOverlay />}
            {children}
        </PersonsContext.Provider>
    );
};
