import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ActorRefFrom } from 'xstate';
import adultDetailsFormContent from '../../content/ui/components/adult-details-form/adultDetailsForm';
import withContent from '../../hoc/with-content/withContent';
import Limits from '../../utils/constants/Limits';
import DateOfBirthInput from '../date-of-birth-input/DateOfBirthInput';
import TextField from '../text-field/TextField';
import AdultDetailsFormMachine from './adult-details-form-machine/adultDetailsFormMachine';
import Checkbox from '../form/checkbox/Checkbox';
import RADIO_CHECKBOX_SIZE from '../form/RadioCheckboxSize';

const contentMap = {
    firstNameLabel: 'ui.firstName.label',
    firstNamePlaceholder: 'ui.firstName.placeholder',
    lastNameLabel: 'ui.lastName.label',
    lastNamePlaceholder: 'ui.lastName.placeholder',
    genderLabel: 'ui.gender.label',
    emailLabel: 'ui.email.label',
    dobLabel: 'ui.dob.label',
    purchaserPrivacyCheckLabel: 'ui.purchaserPrivacyCheck.label',
    insuredPersonPrivacyCheckLabel: 'ui.insuredPersonPrivacyCheck.label',
    emailPlaceholder: 'ui.email.placeholder',
    residencyCheckLabel: 'ui.residencyCheck.label',
    dropdownPlaceholder: 'ui.dropdownPlaceholder',
};

interface AdultDetailsFormProps {
    content: Record<keyof typeof contentMap, string>;
    adultDetailsFormMachineRef: ActorRefFrom<typeof AdultDetailsFormMachine>;
    form?: string;
    createAdultError: string | null;
    resetCreateAdultError: () => void;
    coverStartDates: string[];
    minAge?: number;
    maxAge?: number;
}

const AdultDetailsForm: FC<AdultDetailsFormProps> = ({
    content,
    adultDetailsFormMachineRef,
    form,
    createAdultError,
    resetCreateAdultError,
    coverStartDates,
    minAge,
    maxAge,
}) => {
    const [state, send] = useActor(adultDetailsFormMachineRef);

    const hasDobError = state.matches('dob.displayDobError');
    const hasEmailError = state.matches('email.displayEmailError');

    return (
        <>
            <TextField
                id="firstName"
                name="firstName"
                className="profile-step-guest__input-row"
                label={content.firstNameLabel}
                value={state.context.firstName}
                placeholder={content.firstNamePlaceholder}
                minLength={Limits.NAME_MIN_LENGTH}
                maxLength={Limits.NAME_MAX_LENGTH}
                onChange={(e) => send({ type: 'UPDATE_FIRST_NAME', data: e.target.value })}
                form={form}
            />
            <TextField
                id="lastName"
                name="lastName"
                label={content.lastNameLabel}
                className="profile-step-guest__input-row"
                value={state.context.lastName}
                placeholder={content.lastNamePlaceholder}
                minLength={Limits.NAME_MIN_LENGTH}
                maxLength={Limits.NAME_MAX_LENGTH}
                onChange={(e) => send({ type: 'UPDATE_LAST_NAME', data: e.target.value })}
                form={form}
            />
            <TextField
                id="email"
                name="email"
                className="profile-step-guest__input-row"
                label={content.emailLabel}
                placeholder={content.emailPlaceholder}
                value={state.context.email}
                onChange={(e) => send({ type: 'UPDATE_EMAIL', data: e.target.value })}
                onBlur={() => {
                    send({ type: 'BLUR_EMAIL' });
                }}
                onKeyDown={resetCreateAdultError}
                onFocus={resetCreateAdultError}
                isError={hasEmailError || (createAdultError !== null && createAdultError.length > 0)}
                error={state.context.emailError}
                form={form}
            />
            <div className="profile-step-guest__input-row">
                <DateOfBirthInput
                    label={content.dobLabel}
                    day={state.context.dob.day}
                    month={state.context.dob.month}
                    year={state.context.dob.year}
                    hasError={hasDobError}
                    onDayChange={(e) => {
                        send({ type: 'UPDATE_DATE', data: { value: e.target.value, coverStartDates, minAge, maxAge } });
                    }}
                    onMonthChange={(e) =>
                        send({ type: 'UPDATE_MONTH', data: { value: e.target.value, coverStartDates, minAge, maxAge } })
                    }
                    onYearChange={(e) =>
                        send({ type: 'UPDATE_YEAR', data: { value: e.target.value, coverStartDates, minAge, maxAge } })
                    }
                    onBlur={() => send({ type: 'BLUR_YEAR', data: coverStartDates })}
                    form={form}
                />
                {hasDobError && <span className="profile-step-guest__dob-error">{state.context.dob.error}</span>}
            </div>
            <div className="profile-step-guest__checkbox-list">
                <Checkbox
                    id="insuredPersonPrivacyCheck"
                    name="insuredPersonPrivacyCheck"
                    label={adultDetailsFormContent.formatString(content.insuredPersonPrivacyCheckLabel, {
                        firstName: state.context.firstName ? state.context.firstName : '[First name]',
                    })}
                    checked={state.context.insuredPersonPrivacyCheck}
                    onChange={(e) => send({ type: 'UPDATE_INSURED_PERSON_PRIVACY_CHECK', data: e.target.checked })}
                    form={form}
                    size={RADIO_CHECKBOX_SIZE.SMALL}
                />
                <Checkbox
                    id="residencyCheck"
                    name="residencyCheck"
                    label={adultDetailsFormContent.formatString(content.residencyCheckLabel, {
                        firstName: state.context.firstName ? `${state.context.firstName}` : '[First name]',
                    })}
                    checked={state.context.residencyCheck}
                    onChange={(e) => send({ type: 'UPDATE_RESIDENCY_CHECK', data: e.target.checked })}
                    form={form}
                    size={RADIO_CHECKBOX_SIZE.SMALL}
                />
                <Checkbox
                    id="purchaserPrivacyCheck"
                    name="purchaserPrivacyCheck"
                    label={adultDetailsFormContent.formatString(content.purchaserPrivacyCheckLabel, {
                        firstName: state.context.firstName ? `${state.context.firstName}` : '[First name]',
                    })}
                    checked={state.context.purchaserPrivacyCheck}
                    onChange={(e) => send({ type: 'UPDATE_PURCHASER_PRIVACY_CHECK', data: e.target.checked })}
                    form={form}
                    size={RADIO_CHECKBOX_SIZE.SMALL}
                />
            </div>
        </>
    );
};

export default withContent(AdultDetailsForm, contentMap, adultDetailsFormContent);
